<template>
  <!--------------------------- Choix edition / upload  ------------------------------->
  <div class="composant-edition-facture" v-if="etapeSaisie == CHOIX_EDITION_UPLOAD">
    <p class="titre">Type de demande</p>
    <div>
      ⚠️ L'edition automatique est uniquement
      <br />disponible pour les factures sans précompte
    </div>
    <br />
    <div>
      <ComposantRadio
        class="radioEditionAuto"
        label="Edition automatique de facture <br>"
        group="typeFacture"
        :value="EDITION_AUTO_FACTURE"
        v-model="typeFacture"
        :disabled="disableEdition"
        :disabledInfo="messageDisableEdition"
      />
      <ComposantRadio
        label="Uploader ma propre facture"
        group="typeFacture"
        :value="UPLOAD_FACTURE"
        v-model="typeFacture"
      />
    </div>
    <br />
    <div class="frame-boutons">
      <div class="frame-ajouter btn btn-primary" @click="validationEditionUpload">Continuer</div>
      <div class="frame-annuler btn btn-danger" @click="masquerModale">Annuler</div>
    </div>
  </div>

  <!--------------------------- Choix avec / sans Siret  ------------------------------->

  <div class="composant-edition-facture" v-else-if="etapeSaisie == CHOIX_AVEC_SANS_SIRET">
    <p class="titre">Type de demande</p>
    <div>
      <ComposantRadio
        label="Sans précompte (nécessite un SIRET)"
        group="choixPrecompte"
        :disabled="!optionPrecompteDisponible"
        disabledInfo="Option indisponible - un siret doit être renseigné dans la fiche auteur"
        :value="false"
        v-model="inclurePrecompte"
      />

      <ComposantRadio
        label="Avec précompte (ne nécessité pas de SIRET)"
        group="choixPrecompte"
        v-model="inclurePrecompte"
        :value="true"
      />
      <!-- :disabled="!optionSansPrecompteDisponible"
          disabledInfo="Option indisponible - un numéro de sécurité social doit être renseigné dans la fiche auteur" -->
    </div>
    <br />
    <div class="frame-boutons">
      <div class="frame-annuler btn btn-primary" @click="validationChoixPrecompte">Continuer</div>
    </div>
  </div>

  <!--------------------------- Choix options à inclure  ------------------------------->

  <div class="composant-edition-facture" v-else-if="etapeSaisie == CHOIX_CONTENU_FACTURE">
    <p class="titre">Editer facture</p>
    <div class="separateur1"></div>

    <!------------------ Options obligatoires ---------------------->
    <div class="section-options">
      <div class="label-liste-options">Inclus dans la facture :</div>
      <ComposantCheckbox
        class="composant-chekbox"
        label="Adresse"
        :disabled="true"
        :checked="true"
        disabledInfo="Champ obligatoire"
      />
      <ComposantCheckbox
        class="composant-chekbox"
        label="Siret"
        v-model="optionsFacture.inclureSiret"
        :disabled="true"
        disabledInfo="Champ obligatoire"
        v-if="!this.inclurePrecompte"
      />

      <ComposantCheckbox
        class="composant-chekbox"
        label="Numéro de facture"
        v-model="optionsFacture.inclureNumeroFacture"
        :disabled="true"
        disabledInfo="Champ obligatoire"
      />
    </div>
    <div class="frame-numero-facture-two">
      <div class="disclaimer-upload">
        <div class="warn-sign">⚠️</div>
        <p class="warn-line">
          Attention ! Il s'agit du numéro qui sera présent sur la facture, il doit donc correspondre
          à VOTRE système de numérotation
        </p>
      </div>
    </div>
    <div v-if="optionsFacture.inclureNumeroFacture" class="frame-numero-facture-two">
      <p class="label-numero-facture">Numéro facture* :</p>
      <input class="frame-numero-facture" placeholder="000000" v-model="numeroFacture" />
    </div>

    <!------------------ Options facultatives ----------------->
    <div class="section-options">
      <div class="label-liste-options">Options supplémentaires :</div>
      <!-- <ComposantCheckbox 
        class="composant-chekbox" 
        label='Inclure la mention "TVA non applicable"'
        v-model="optionsFacture.inclureMentionTVANonApplicable" 
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
      /> -->
      <ComposantCheckbox
        class="composant-chekbox"
        label="Nom d'artiste"
        v-model="optionsFacture.inclureNomArtiste"
        :disabled="contributeur.nom_artiste_contributeur == ''"
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
      />

      <ComposantCheckbox
        :disabled="contributeur.numero_secu_contributeur == ''"
        class="composant-chekbox"
        label="Numéro de sécurité sociale"
        v-model="optionsFacture.inclureNumeroSecu"
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
      />

      <ComposantCheckbox
        :disabled="contributeur.email_contributeur == ''"
        class="composant-chekbox"
        label="Mail"
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
        v-model="optionsFacture.inclureMail"
      />

      <ComposantCheckbox
        :disabled="contributeur.tel_contributeur == ''"
        class="composant-chekbox"
        label="Téléphone"
        v-model="optionsFacture.inclureTel"
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
      />

      <ComposantCheckbox
        :disabled="contributeur.site_web_contributeur == ''"
        class="composant-chekbox"
        label="Site web"
        v-model="optionsFacture.inclureSiteWeb"
        disabledInfo="Ce champ est vide dans la fiche auteur - à compléter avant de pouvoir l'inclure"
      />
    </div>

    <!-- <div class="mentions-champs-obligatoires">Les champs marqués d'une * sont obligatoires</div> -->
    <div class="separateur1"></div>
    <div class="frame-boutons">
      <div class="frame-ajouter btn btn-primary" @click.stop="genererFacture">
        <p class="bouton-ajouter-button">Editer</p>
      </div>
      <div class="frame-annuler btn btn-danger" @click.stop="definirVisibiliteModale(false)">
        <p class="bouton-ajouter-button">Annuler</p>
      </div>
    </div>
  </div>

  <!---------------------------- Upload facture ---------------------------->
  <div class="composant-edition-facture" v-else-if="etapeSaisie == CHOIX_UPLOAD_FACTURE">
    <p class="titre">Chargement d'une facture</p>
    <div class="separateur1"></div>
    <!------------------ Options obligatoires ---------------------->
    <div class="label-liste-options">Choisissez un fichier :</div>
    <input class="input-file" ref="inputFile" type="file" @change="uploadCheck" />
    <div class="label-liste-options">Informations à reporter :</div>
    <div class="disclaimer-upload">
      <div class="warn-sign">⚠️</div>
      <div class="warn-line">
        Pour éviter tout problème, vérifiez bien les montants avant de valider
      </div>
    </div>
    <div class="upload-infos-facture">
      <p class="label-numero-facture">Montant facture* :</p>
      <input class="frame-numero-facture" placeholder="000000" v-model="montantFacture" />
    </div>

    <div class="upload-infos-facture">
      <p class="label-numero-facture">Montant precompte :</p>
      <input class="frame-numero-facture" placeholder="000000" v-model="precompteFacture" />
    </div>

    <div class="upload-infos-facture">
      <p class="label-numero-facture">
        (Lien vers un outil de calcul de précompte :
        <a :href="association.lienSiteWebOutilMagique" target="_blank">
          Outil magique
        </a>
        )
      </p>
    </div>

    <div class="mentions-champs-obligatoires">Les champs marqués d'une * sont obligatoires</div>
    <div class="separateur1"></div>

    <div class="frame-boutons">
      <div class="frame-ajouter btn btn-primary" @click.stop="chargerFacture">
        <p class="bouton-ajouter-button">Envoyer</p>
      </div>
      <div class="frame-annuler btn btn-danger" @click.stop="definirVisibiliteModale(false)">
        <p class="bouton-ajouter-button">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

const CHOIX_EDITION_UPLOAD = "choix edition / upload";
const CHOIX_AVEC_SANS_SIRET = "choix avec / sans SIRET";
const CHOIX_CONTENU_FACTURE = "choix contenu facture";
const EDITION_AUTO_FACTURE = "editionAuto";
const UPLOAD_FACTURE = "uploadFacture";
const CHOIX_UPLOAD_FACTURE = "CHOIX_UPLOAD_FACTURE";

export default {
  name: "ComposantEditionFacture",
  props: {
    contributeur: { default: undefined },
  },
  data: () => {
    return {
      EDITION_AUTO_FACTURE: EDITION_AUTO_FACTURE,
      CHOIX_UPLOAD_FACTURE: CHOIX_UPLOAD_FACTURE,
      UPLOAD_FACTURE: UPLOAD_FACTURE,
      CHOIX_EDITION_UPLOAD: CHOIX_EDITION_UPLOAD,
      CHOIX_AVEC_SANS_SIRET: CHOIX_AVEC_SANS_SIRET,
      CHOIX_CONTENU_FACTURE: CHOIX_CONTENU_FACTURE,
      etapeSaisie: CHOIX_EDITION_UPLOAD,
      // etapeSaisie: CHOIX_UPLOAD_FACTURE,
      optionsFacture: {
        inclureMentionTVANonApplicable: true,
      },
      // Objet vide - les clés seront créées à la volée si l'utilisateur modifie les options.
      // Le fichier php gère automatiquement l'absence d'une clé en la créant à false
      numeroFacture: undefined,
      montantFacture: undefined,
      precompteFacture: undefined,
      inclurePrecompte: undefined,
      typeFacture: undefined,
      association: {
        lienSiteWebOutilMagique: "",
      },
    };
  },
  methods: {
    ...mapMutations(["definirVisibiliteModale", "masquerModale"]),
    ...mapActions(["phpPost", "modaleComposant", "modaleAlert"]),
    validationEditionUpload() {
      if (this.typeFacture != undefined) {
        if (this.typeFacture == UPLOAD_FACTURE) this.etapeSaisie = CHOIX_UPLOAD_FACTURE;
        else {
          this.optionsFacture.inclureSiret = true;
          this.optionsFacture.inclureNumeroFacture = true;
          this.inclurePrecompte = false;
          this.etapeSaisie = CHOIX_CONTENU_FACTURE;
        }
      }
    },
    validationChoixPrecompte() {
      if (this.inclurePrecompte != undefined) {
        if (this.inclurePrecompte) this.optionsFacture.inclureNumeroSecu = true;
        else {
          this.optionsFacture.inclureSiret = true;
          this.optionsFacture.inclureNumeroFacture = true;
        }
        this.etapeSaisie = CHOIX_EDITION_UPLOAD;
      }
    },
    genererFacture() {
      if (
        this.optionsFacture.inclureNumeroFacture &&
        (this.numeroFacture == undefined || this.numeroFacture == "")
      )
        return this.modaleAlert("Le numéro de facture doit être rempli");
      if (
        this.contributeur.adresse_contributeur.libelle_adresse == "" ||
        this.contributeur.adresse_contributeur.code_postal_adresse == "" ||
        this.contributeur.adresse_contributeur.ville_adresse == ""
      )
        return this.modaleAlert("L'adresse doit être renseignée dans la fiche auteur");

      // console.log('this.contributeur', this.contributeur)
      if (this.contributeur != undefined)
        this.phpPost({
          phpFile: "./assets/php/editionFacture.php",
          sendObject: {
            ...this.contributeur,
            optionsFacture: this.optionsFacture,
            numeroFacture: this.numeroFacture,
            inclurePrecompte: this.inclurePrecompte,
            montantFacture:
              Math.round(
                (parseFloat(this.contributeur["total_compte_droits"]) -
                  parseFloat(this.contributeur["deja_verse_compte_droits"])) *
                  100
              ) / 100,
          },
          callBack: ((response) => {
            this.definirVisibiliteModale(false);
            this.modaleComposant({
              componentName: "composantVisualisationFacture",
              props: {
                fichierPdf: response.reqDoc,
                numeroFacture: this.numeroFacture,
                contributeur: this.contributeur,
                montantFacture:
                  Math.round(
                    (parseFloat(this.contributeur["total_compte_droits"]) -
                      parseFloat(this.contributeur["deja_verse_compte_droits"])) *
                      100
                  ) / 100,
              },
            });
          }).bind(this),
        });
      else alert("Aucun contributeur n'a été sélectionné");
    },
    uploadCheck() {
      var facture = this.$refs.inputFile.files[0];
      if (facture == undefined) return;
      var typesFichierUploadFacture = ["PDF"];
      if (facture.size > 6000000) {
        this.modaleAlert("Fichier trop volumineux, maximum : 5Mo");
        this.$refs.inputFile.type = "text";
        this.$refs.inputFile.type = "file";
      } else if (
        facture.type == "" ||
        typesFichierUploadFacture.indexOf(facture.type.split("/")[1].toUpperCase()) == -1
      ) {
        this.modaleAlert("Format de fichier non pris en charge - fichier accepté : pdf");
        this.$refs.inputFile.type = "text";
        this.$refs.inputFile.type = "file";
      }
    },
    chargerFacture() {
      var facture = this.$refs.inputFile.files[0];
      if (facture == undefined) return this.modaleAlert("Aucun fichier choisi");
      // if (this.numeroFacture == undefined
      //     || this.numeroFacture == ''
      //     || this.montantFacture == undefined
      //     || this.montantFacture == ''
      //   ) return this.modaleAlert("Les champs marqués d'une * sont obligatoires")
      if (this.montantFacture == undefined || this.montantFacture == "")
        return this.modaleAlert("Les champs marqués d'une * sont obligatoires");
      this.numeroFacture = "XXXX";

      if (this.contributeur != undefined) {
        var formData = new FormData();
        formData.append("file", facture);

        this.phpPost({
          phpFile: "./assets/php/uploadFactureTmp.php",
          sendObject: formData,
          postType: "ajax",
          callBack: ((response) => {
            this.definirVisibiliteModale(false);
            this.modaleComposant({
              componentName: "composantVisualisationFacture",
              props: {
                fichierPdf: response.uploadedFile,
                numeroFacture: this.numeroFacture,
                contributeur: this.contributeur,
                montantFacture: this.montantFacture,
                precompteFacture: this.precompteFacture,
              },
            });
          }).bind(this),
        });
      } else alert("Aucun contributeur n'a été sélectionné");
    },
  },
  computed: {
    disableEdition() {
      return this.adresseManquante || this.siretManquant;
    },
    messageDisableEdition() {
      return this.disableEdition
        ? "Option indisponible : adresse et/ou Siret manquants. A noter : pour une demande avec précompte, vous devez choisir l'option 'Uploader ma propre facture'"
        : "";
    },
    adresseManquante() {
      return (
        this.contributeur.adresse_contributeur.libelle_adresse == "" ||
        this.contributeur.adresse_contributeur.code_postal_adresse == "" ||
        this.contributeur.adresse_contributeur.ville_adresse == ""
      );
    },
    siretManquant() {
      return (
        this.contributeur.siret_contributeur == "" ||
        this.contributeur.siret_contributeur == undefined
      );
    },
    optionPrecompteDisponible() {
      return (
        this.contributeur.siret_contributeur != "" &&
        this.contributeur.siret_contributeur != undefined
      );
    },
    optionSansPrecompteDisponible() {
      return (
        this.contributeur.numero_secu_contributeur != "" &&
        this.contributeur.numero_secu_contributeur != undefined
      );
    },
  },
  mounted: function() {
    this.phpPost({
      phpFile: "assets/php/coordonneesAssociation.php",
      disableThumbnailForAll: true,
      callBack: (response) => {
        if (response.reqSuccess) this.association = response.infosAssociation;
      },
    });
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";
.composant-edition-facture {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 24px 23px 23px 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
}
.titre {
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.label-liste-options {
  @include typo-large;
  color: $couleur-texte-fonce;
  margin-bottom: 15px;
}

.choix-fichier-upload {
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-bottom: 15px;
}

.composant-chekbox {
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-left: 30px;
}

.frame-numero-facture-two {
  background-color: $fond-page;
  margin-left: 40px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  // align-self: stretch;
}
.label-numero-facture {
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-right: 27px;
}
.frame-numero-facture {
  width: 150px;
  padding: 2px 0;
  @include typo-medium;
  color: $couleur-texte-fonce;
}
.montant-facture {
  width: 150px;
  @include typo-medium;
  color: $couleur-texte-fonce;
}

.frame-boutons {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 37px;
}
.frame-ajouter {
  // background-color: $neutral-btn;
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  align-self: stretch;
}
.bouton-ajouter-button {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.frame-annuler {
  // background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  align-self: stretch;
}

.input-file {
  margin-top: 10px;
  margin-bottom: 30px;
}

.upload-infos-facture {
  display: flex;
  align-content: center;
  margin-bottom: 30px;
}

.disclaimer-upload {
  color: $couleur-texte-fonce;
  @include typo-medium;
  margin-bottom: 20px;
  border: 1px solid $couleur-texte-fonce;
  border-radius: 15px;
  padding: 10px;
  width: 400px;
}

.warn-sign {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.warn-line {
  text-align: center;
}

.mentions-champs-obligatoires {
  margin-top: 10px;
  margin-bottom: 3px;
  text-align: center;
  @include typo-small;
  color: $couleur-texte-fonce;
}
.section-options {
  margin-bottom: 20px;
}

.composant-edition-facture .infobulle:hover::after{
  left: 320px!important;
  top: -120px!important;
}
</style>
