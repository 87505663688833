
import $ from 'jquery'
import Vue from 'vue'
import Vuex from 'vuex'

import App from './components/pagePrincipale'
// import * as modale from './js/modale'
import VueRouter from 'vue-router'
import JsonViewer from 'vue-json-viewer'


/*********** Page de l'application *****************/
import pageVisualisation from './components/pageVisualisation'
import pageFicheNumero from './components/pageFicheNumero'
import pageFicheAuteur from './components/pageFicheAuteur'
import pageAjoutComptage from './components/pageAjoutComptage'
import pageModifComptage from './components/pageModifComptage'
import pageSessionComptage from './components/pageSessionComptage'
import pageFicheLieu from './components/pageFicheLieu'
import pageGestionLieux from "./components/pageGestionLieux";
import pageAdministration from "./components/pageAdministration";
import pageEspacePersonnel from './components/pageEspacePersonnel'
import pageFestival from './components/pageFestival'
import pageGestionFestivals from './components/pageGestionFestivals'
import pageStatistiques from './components/pageStatistiques'
import pageCreationCompteFuturMembre from './components/pageCreationCompteFuturMembre'
Vue.component('pageCreationCompteFuturMembre', pageCreationCompteFuturMembre)
import Multiselect from "vue-multiselect";
Vue.component('Multiselect', Multiselect)

import __composantTest from './components/__composantTest3'


/*********** Micro composant global *****************/
import LabelEditable from './components/composantLabelEditable'
import ComposantCheckbox from './components/composantCheckbox'
import ComposantRadio from './components/ComposantRadio'
import Loader from './components/loader'

/*********** Composants dynamiques *****************/



/*********** Composant à injecter dans la modale *****************/
import composantListeVersement from './components/composantListeVersement'
import composantAjoutVersement from './components/composantAjoutVersement'
import composantAjoutMission from './components/composantAjoutMission'
import composantAjoutTransfert from './components/composantAjoutTransfert'
import composantModaleAlert from './components/ComposantModaleAlert'
import composantModaleOuiNon from './components/ComposantModaleOuiNon'
import composantEditionFacture from './components/composantEditionFacture'
import composantVisualisationFacture from './components/composantVisualisationFacture'
import composantConnexion from './components/composantConnexion'
import composantDetailsNumero from './components/composantDetailsNumero'
import ComposantListeDemandesVersement from './components/composantListeDemandesVersement'
import ComposantDemandeVersement from './components/composantDemandeVersement'
import ComposantReinitPass from './components/composantReinitPass'
import ComposantInfosDeclaContributeur from './components/composantInfosDeclaContributeur'
import ComposantAjoutImpression from './components/ComposantAjoutImpression'
import ComposantListeImpressions from './components/ComposantListeImpressions'
import ComposantNouveauPass from './components/ComposantNouveauPass'
import ComposantListeContenuPack from './components/ComposantListeContenuPack'
import ComposantListeTransfert from './components/composantListeTransfert'
import ComposantAjoutFestival from './components/composantAjoutFestival'
import ComposantDefinirNumeroFestival from './components/composantDefinirNumeroFestival'
import ComposantSelectionNumerosFestival from './components/composantSelectionNumerosFestival'
import ComposantInvitationNouveauMembre from './components/ComposantInvitationNouveauMembre'

/******** Composant page admin  *********/
import composantAdminBDD from './components/composantAdminBDD'
import composantSupprimerContributeur from './components/composantSupprimerContributeur'
import composantAjoutContributeur from './components/composantAjoutContributeur'
import composantModifContributeur from './components/composantModifContributeur'
import composantAdminAsso from './components/composantAdminAsso'
import composantAdminDemandesVersement from './components/composantAdminDemandesVersement'

Vue.component('LabelEditable', LabelEditable)
Vue.component('ComposantCheckbox', ComposantCheckbox)
Vue.component('ComposantRadio', ComposantRadio)
Vue.component('Loader', Loader)


Vue.component('composantListeVersement', composantListeVersement)
Vue.component('ComposantAjoutVersement', composantAjoutVersement)
Vue.component('ComposantAjoutMission', composantAjoutMission)
Vue.component('ComposantAjoutTransfert', composantAjoutTransfert)
Vue.component('ComposantModaleAlert', composantModaleAlert)
Vue.component('ComposantModaleOuiNon', composantModaleOuiNon)
Vue.component('ComposantEditionFacture', composantEditionFacture)
Vue.component('composantVisualisationFacture', composantVisualisationFacture)
Vue.component('ComposantConnexion', composantConnexion)
Vue.component('ComposantDetailsNumero', composantDetailsNumero)

Vue.component('composantAdminBDD', composantAdminBDD)
Vue.component('composantAdminAsso', composantAdminAsso)
Vue.component('composantAdminDemandesVersement', composantAdminDemandesVersement)

Vue.component('ComposantSupprimerContributeur', composantSupprimerContributeur)
Vue.component('ComposantAjoutContributeur', composantAjoutContributeur)
Vue.component('composantModifContributeur', composantModifContributeur)
Vue.component('ComposantListeDemandesVersement', ComposantListeDemandesVersement)
Vue.component('ComposantReinitPass', ComposantReinitPass)
Vue.component('ComposantDemandeVersement', ComposantDemandeVersement)
Vue.component('ComposantInfosDeclaContributeur', ComposantInfosDeclaContributeur)
Vue.component('ComposantAjoutImpression', ComposantAjoutImpression)
Vue.component('ComposantListeImpressions', ComposantListeImpressions)
Vue.component('ComposantNouveauPass', ComposantNouveauPass)
Vue.component('ComposantListeContenuPack', ComposantListeContenuPack)
Vue.component('ComposantListeTransfert', ComposantListeTransfert)
Vue.component('ComposantAjoutFestival', ComposantAjoutFestival)
Vue.component('ComposantDefinirNumeroFestival', ComposantDefinirNumeroFestival)
Vue.component('ComposantSelectionNumerosFestival', ComposantSelectionNumerosFestival)
Vue.component('ComposantInvitationNouveauMembre', ComposantInvitationNouveauMembre)



// require ('./js/loader/loading-bar.js');

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)
Vue.use(VueRouter)
Vue.use(Vuex)

Vue.config.productionTip = false

import * as storeConst from './js/storeConst'
import { storeConfig } from './js/store'
// import $ from './__mocks__/jquery'

export const store = new Vuex.Store(storeConfig)

$.post("assets/php/context.php", (serverName) => {
    if (serverName == 'localhost') {
        store.commit("definirAffichageLogsConsole", true)
        store.commit("definirAffichageLogsRetour", false)
    } else {
        store.commit("definirAffichageLogsConsole", false)
        store.commit("definirAffichageLogsRetour", false)
    }
})


// console.log('{ ...storeConfig }', { ...storeConfig })
// console.log('...storeConfig', ...storeConfig)
// console.log('storeConfig', storeConfig)

// let store
// console.log('store', store)


const router = new VueRouter({
    routes: [
        { path: '/ajoutcomptage-:id', component: pageAjoutComptage },
        { path: '/modifcomptage-:id/:cpt', component: pageModifComptage },
        { path: '/sessioncomptage', component: pageSessionComptage },
        { path: '/edition/:type_edition', component: pageFicheNumero },
        { path: '/listenumeros', component: pageVisualisation },
        { path: '/gestionLieux', component: pageGestionLieux },
        { name: 'ficheLieu', path: '/ficheLieu/:refLieu', component: pageFicheLieu, props: true },
        { path: '/ficheauteur-:id', component: pageFicheAuteur },
        { path: '/espacepersonnel', component: pageEspacePersonnel },
        { path: '/admin', component: pageAdministration },
        { path: '/festival', component: pageGestionFestivals },
        { path: '/festival/festival-:id', component: pageFestival },
        { path: '/futur-membre-:token', component: pageCreationCompteFuturMembre, name: "inscription" },
        { path: '/festival/festival-', redirect: '/festival' },

        { path: '/testVisualisations', component: __composantTest },
        { path: '/statistiques', component: pageStatistiques },
        // {path: '/ajoutcontributeur', component: pageAjoutContributeur}, 
        { path: '*', redirect: '/listenumeros' },
    ]
})

router.beforeEach(function (to, from, next) {
    if (store.state.modificationPresente && from.path != "/visualisation") {
        store.dispatch('modaleOuiNon', {
            message: store.state.messageAlerteModification,
            callBackYes: () => {
                store.commit('resetModificationPresente');
                next();
            }
        })
    }
    else next();
})


new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')

store.dispatch(storeConst.INITIALISATION_DATA);

String.prototype.noAccent = function () {
    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ];
    var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

    var str = this;
    for (var i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
    }

    return str;
}

Date.prototype.addMonths = function (n) {
    return new Date(this.setMonth(this.getMonth() + n));
};

Date.prototype.addHours = function (n) {
    let hours = this.getHours() + n
    return new Date(this.setHours(hours));
};
Date.prototype.addDays = function (n) {
    let hours = this.getDate() + n
    return new Date(this.setDate(hours));
};