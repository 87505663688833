<template>
  <div class="composant-modale-alert">
    <p class="message" v-html="params.message"></p>
    <div class="separateur1"></div>
    <div v-if="params.displayLoader" class="loader">
      <div class="lds-dual-ring"></div>
    </div>
    <div v-else-if="params.displayProgressBar" class="progress-bar-container">
      <div class="progress-bar">
        <div class="inner-bar" :style="`width:${barProgression}%`"></div>
      </div>
    </div>
    <button
      ref="OKButton"
      v-else
      @click="quitterFenetreModale"
      @keypress.enter="quitterFenetreModale"
      class="frame-ok"
    >
      <p ref="boutonOK" class="bouton-ok">Ok</p>
    </button>
    <Keypress
      key-event="keyup"
      :key-code="13"
      @success="quitterFenetreModale"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "ComposantModaleAlert",
  components: {
    Keypress: () => import("vue-keypress"),
  },
  props: {
    params: {
      message: {
        default: "Informations",
      },
      displayLoader: {
        default: false,
      },
      displayProgressBar: {
        default: false,
      },
    },
  },
  computed: {
    ...mapState(["barProgression"]),
  },
  methods: {
    ...mapMutations(["masquerModale"]),
    quitterFenetreModale() {
      // if (this.params.waitingContent != undefined) this.definirContenuModale(this.params.waitingContent)
      this.masquerModale(false);
      if (this.params.callBack != undefined) this.params.callBack();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-modale-alert {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  @media (max-width: $mobile-break-point) {
    width: calc(100% - 30px);
    width: 400px;
  }
}
.message {
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 300px;
  height: 1px;
  background-color: $silver;
  // margin-bottom: 20px;
}
.frame-ok {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  // margin-top: 20px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  cursor: pointer;
  border: none;
}
.bouton-ok {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.loader {
  height: 13px;
  border-radius: 9px;
  // padding: 12px 16px;
  align-self: stretch;
  text-align: center;
  margin-bottom: 20px;
}

.lds-dual-ring {
  display: inline-block;
  width: 34px;
  height: 34px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progress-bar {
  height: 30px;
  width: 300px;
  padding: 2px;
  background-color: rgb(112, 158, 255);
  border-radius: 5px;
}
.inner-bar {
  height: 100%;
  // width: 150px;
  transition: width 0.5s;
  // width: 75%;
  background-color: rgb(47, 112, 253);
}
</style>
