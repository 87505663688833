<template>
  <div class="composant-parametresbdd">
    <div class="frame-titre">
      <p class="label-titre">Accès base de données</p>
    </div>
    <div class="frame-denomination">
      <p class="label">Administrateur base de données</p>
      <div class="frame-ligne-admin">
        <p class="label-two">Identifiant</p>
        <LabelEditable 
          class="label-editable" 
          v-model="infosBddAdmin.idBddAdmin" 
          @change="modifIdBddAdmin"
        />
      </div>
      <div class="frame-ligne-admin">
        <p class="label-two">Mail (réinit. mot de passe)</p>
        <LabelEditable 
          class="label-editable" 
          v-model="infosBddAdmin.mailBddAdmin"
          @change="modifMailBddAdmin"
        />
      </div>
      <div class="frame-ligne-admin">
        <p class="label-two">Nouveau mot de passe</p>
        <div class="btn btn-primary bdd-param-link" @click="reinitPassword">Réinitialiser</div>
      </div>
      <div class="frame-ligne-admin">
        <p class="label-two">Paramètres de connexion BDD</p>
        <div class="btn btn-primary bdd-param-link" @click="goToBddAdmin" >Accéder aux paramètres</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: "ComposantAdminBDD",
  data: () => {
    return { 
      infosBddAdmin: {
        mailBddAdmin: '',
        idBddAdmin: ''
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapActions(['phpPost', 'modaleAlert', 'modaleOuiNon']),
    ...mapMutations(['masquerModale']),
    goToBddAdmin() {
      if (this.user.superAdminAccessGranted) window.location="assets/php/bddadmin"
      else alert('Droits insuffisants pour accéder à la page')
    },
    modifMailBddAdmin() {
      this.phpPost({
        phpFile: 'assets/php/adminBddModifMail.php',
        sendObject: { mailBddAdmin: this.infosBddAdmin.mailBddAdmin }
      })
    },
    reinitPassword() {
      this.modaleOuiNon({
        message: "Un nouveau mot de passe aléatoire va être créé, puis il sera ensuite envoyé à l'adresse mail spécifiée.<br>Êtes-vous sûr de vouloir continuer ?",
        callBackYes: ()=>{
          this.modaleAlert({
            message: 'Patientez...',
            displayLoader: true
          })
          this.phpPost({
            phpFile: 'assets/php/adminBddReinitPass.php',
            callBack: ()=> {
              this.masquerModale()
            }
          })
        }
      })
     
    },
    modifIdBddAdmin() {
      this.phpPost({
        phpFile: 'assets/php/adminBddModifId.php',
        sendObject: { newBddId: this.infosBddAdmin.idBddAdmin }
      })
    }
  },
  mounted: function () {
    this.phpPost({
      phpFile: 'assets/php/adminBddGetInfos.php',
      disableThumbnail: true,
      callBack: (response) => {
        if (response.reqSuccess) this.infosBddAdmin = response.infosBddAdmin
      }
    })
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-parametresbdd {
  border: $contour-page;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.frame-titre {
  margin-bottom: 58.5px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-titre {
  color: $couleur-texte-fonce;
  @include typo-titre;
}
.frame-denomination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.label {
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
  @include typo-large;
}
.frame-ligne-admin {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 9px 27px;
  width: 600px;
  height: 50px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.label-two {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.label-editable {
  // align-self: stretch;
  width: 300px;
  justify-content: space-between;
}

.btn {
  width: 200px;
}
</style>