<template>
  <div id="visualisationCorpsDePage">
    <h1 id="titreListeNumeros">Liste des numéros</h1>
    <div
      id="informationNumeroStockFaible"
      v-if="numerosAvecUnStockFaible == true"
      class="alert alert-danger"
    >
      <!-- <span class="alerte-stock-faible">⚠️</span> -->
      Attention - certains numéros sont presque épuisés. Utiliser l'option de
      tri 'Quantité en stock' pour trier les numéros par quantité
    </div>
    <div
      id="informationNumeroStockFaible"
      v-if="numerosAvecProblemeImpression == true"
      class="alert alert-danger"
    >
      <span class="alerte-inventaire">⚠️</span>
      . Attention ! Certains numéros présentent des erreurs d'inventaire (Ventes
      négatives ou droits d'auteur négatifs)<br />
      Cause probable : impression non déclarée - Vérifiez que les impressions
      sont à jour. <br />
      Utiliser l'option de tri 'Erreur inventaire' pour les afficher en
      priorité.
    </div>
    <div id="zoneRecherche" class="dflex">
      <div>Recherche :</div>
      <input type="text" id="rechercheInput" v-model="cleRecherche" />
      <div>Trier par :</div>
      <select name="optionDeTri" id="optionDeTri" v-model="optionDeTri">
        <option value="numero">Numéro d'exemplaire</option>
        <option value="titre">Titre</option>
        <option value="dateComptage">Date de dernier comptage</option>
        <option value="nombreImpressions">Nombre d'impressions</option>
        <option value="ventes">Nombre de ventes</option>
        <option value="quantite">Quantité en stock</option>
        <option value="erreurComptage">Erreur d'inventaire</option>
      </select>
    </div>
    <div id="cards">
      <div
        class="card"
        v-for="numero of listeNumerosFiltreeTriee(cleRecherche, optionDeTri)"
        v-bind:key="numero.id_numero"
      >
        <div class="imgVisualisationContainer">
          <img
            class="card-img-top imgVisualisationCouverture"
            :id="'imgVisualisationNumero-' + numero.id_numero"
            :src="numero.couverture_numero"
            alt="Couverture"
            v-on:click="afficherDetailsNumero(numero.id_numero)"
          />
          <div
            v-if="erreurComptage(numero)"
            class="alerte-inventaire infobulle"
            :bulle-content="rapportErreurComptage(numero)"
          >
            ⚠️
          </div>
        </div>
        <div class="card-body">
          <div class="card-body-partie1">
            <div class="card-title-custom">
              N°{{ numero.numero_numero }} {{ numero.titre_numero }}
            </div>
            <div class="details-numeros-container">
              <div
                class="details-numero-card"
                :class="alerteStockFaible(numero)"
              >
                <p class="card-text">Stock actuel :</p>
                <p>
                  {{
                    numero.liste_comptages[numero.liste_comptages.length - 1]
                      .quantite_comptage
                  }}
                </p>
              </div>
              <!-- <p class="card-text">Dernier comptage : {{ dateDernierComptage(numero.id_numero) }}</p> -->
              <div class="details-numero-card">
                <p class="card-text">Ventes :</p>
                <p>{{ numero.nombre_ventes_numero }}</p>
              </div>
            </div>
          </div>

          <div class="bouton-container">
            <div class="divTextCenter" v-if="!screenIsPhone">
              <a
                class="btn btn-light btn-sm boutonCard"
                :id="'boutonDetailsNumero-' + numero.id_numero"
                v-on:click="afficherDetailsNumero(numero.id_numero)"
                >Details</a
              >
            </div>
            <router-link
              :to="{ path: '/edition/consulter-' + numero.id_numero }"
            >
              <div class="divTextCenter">
                <a
                  :id="'afficherFicherNumero-' + numero.id_numero"
                  class="btn btn-light btn-sm boutonCard"
                  >Fiche</a
                >
              </div>
            </router-link>
            <router-link
              :to="{ path: '/modifcomptage-' + numero.id_numero + '/-' }"
            >
              <div class="divTextCenter">
                <a
                  v-if="user.adminAccessGranted"
                  class="btn btn-light btn-sm boutonCard"
                  >Comptage</a
                >
              </div>
            </router-link>
            <div class="divTextCenter">
              <a
                class="btn btn-danger btn-sm boutonCard"
                :id="'boutonSupprimerNumero-' + numero.id_numero"
                @click="supprimerNumero(numero.id_numero)"
                v-if="user.adminAccessGranted"
                >Supprimer</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const GETTERS_TO_MAP = [
  "listeNumerosFiltreeTriee",
  "trouveNumeroParId",
  "formatDateNomDuMois",
  "dateFrancaise",
];

const ACTIONS_TO_MAP = ["phpPost", "modaleOuiNon"];

const MUTATIONS_TO_MAP = ["definirContenuModale", "definirVisibiliteModale"];

const STATES_TO_MAP = ["user", "modale", "screenIsPhone", "listeNumeros"];

export default {
  name: "pageVisualisation",
  props: ["testing"],
  data: () => {
    return {
      cleRecherche: "",
      optionDeTri: "numero",
      numerosAvecUnStockFaible: false,
      numerosAvecProblemeImpression: false,
    };
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    erreurComptage(numero) {
      this.numerosAvecProblemeImpression =
        this.numerosAvecProblemeImpression || numero.erreur_comptage;
      return numero.erreur_comptage;
      // let erreur = false
      // for (let cont of numero.liste_contributions) erreur = erreur || parseFloat(cont.montant_contributions) < 0
      // return erreur
    },
    rapportErreurComptage(numero) {
      return (
        "Ce numero possède des anomalies d'inventaire" +
        "\n(Ventes négatives ou droits d'auteur négatifs)" +
        "\nCause probable : impression non déclarée.\nVérifiez que les impressions sont à jour"
      );
      // let comptage1 = numero.liste_comptages[numero.liste_comptages.length - 1]
      // let comptage2 = numero.liste_comptages[numero.liste_comptages.length - 2]
      // return  "Augmentation du stock :"
      //         + "\nLe " + this.dateFrancaise(comptage2.date_comptage) + " : " + comptage2.quantite_comptage
      //          + "\nLe " + this.dateFrancaise(comptage1.date_comptage) + " : " + comptage1.quantite_comptage
    },
    alerteVentesNegatives(numero) {
      let ventes = numero.nombre_ventes_numero;
      if (ventes < 0) {
        this.numerosAvecProblemeImpression = true;
        return "alert-danger";
      } else {
        return "";
      }
    },
    alerteStockFaible(numero) {
      let stock =
        numero.liste_comptages[numero.liste_comptages.length - 1]
          .quantite_comptage;
      if (stock < 500) {
        this.numerosAvecUnStockFaible = true;
        return "alert-danger";
      } else {
        return "";
      }
    },
    dateDernierComptage(id_numero) {
      var numero = this.trouveNumeroParId(id_numero);
      if (numero == undefined || numero.liste_comptages.length == 0) return "?";
      return numero.liste_comptages[
        numero.liste_comptages.length - 1
      ].date_comptage
        .split("-")
        .reverse()
        .join("/");
    },
    modifierNumero(id_numero) {
      document.location.href = "#/modificationnumero/modifier-" + id_numero;
    },
    afficherDetailsNumero(id_numero) {
      if (this.screenIsPhone) return;
      var numero = this.trouveNumeroParId(id_numero);
      var quantiteDernierComptage = "Aucun comptage trouvé";

      if (numero.liste_comptages.length != 0)
        quantiteDernierComptage =
          numero.liste_comptages[numero.liste_comptages.length - 1]
            .quantite_comptage +
          " (au " +
          numero.liste_comptages[
            numero.liste_comptages.length - 1
          ].date_comptage
            .split("-")
            .reverse()
            .join("/") +
          ")";

      var contributionsStr = numero.liste_contributions.reduce(function(
        listeStr,
        contribution
      ) {
        var symboleContribution =
          contribution.mode_contribution == "fixe" ? " € / Numéro" : " %";
        return (
          listeStr +
          "  - " +
          contribution.prenom_contributeur +
          " " +
          contribution.nom_contributeur +
          " (" +
          contribution.pourcentage_contribution +
          symboleContribution +
          " / " +
          contribution.type_contribution +
          ")<br>"
        );
      },
      "");
      this.definirContenuModale({
        componentName: "ComposantDetailsNumero",
        props: {
          titre: "N°" + numero.numero_numero + " - " + numero.titre_numero,
          dateParution: this.formatDateNomDuMois(numero.date_parution_numero),
          nombreImpressions: numero.nombre_impressions_numero,
          ventesNumero: numero.nombre_ventes_numero,
          stockActuel: quantiteDernierComptage,
          detailsContribution: contributionsStr,
          couvertureNumero: numero.couverture_numero,
        },
        allowQuitOnClick: true,
      });
      this.definirVisibiliteModale(true);
    },
    supprimerNumero(id_numero) {
      let numeroASupprimer = this.trouveNumeroParId(id_numero);
      this.modaleOuiNon({
        message:
          "<h2>ATTENTION !</h2><br> Vous êtes sur le point de supprimer le N°" +
          numeroASupprimer.numero_numero +
          " - " +
          numeroASupprimer.titre_numero +
          "<br><br>Tous les comptages de stocks de ce numéro seront supprimés" +
          "<br><br>Toutes les contributions à ce numéro seront supprimées mais les contributeurs resteront dans la base de données." +
          "<br><br>Tous les droits d'auteur relatifs à ce numéro seront supprimés." +
          "<br><br><strong>Cette opération est irréversible. Êtes-vous sûr de vouloir continuer ?</strong>",
        callBackYes: () => {
          // Si oui
          this.phpPost({
            phpFile: "./assets/php/supprimerNumero.php",
            sendObject: { id: id_numero },
            dispatchAtTheEnd: [
              "miseAJourNumeros",
              "miseAJourContributeurs",
              "miseAJourLieux",
            ],
          });
        },
      });
    },
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
    // ...mapState({
    //     listeNumeros: 'listeNumeros',
    // })
  },
  // mounted() {
  //   this.phpPost({
  //     phpFile: "./assets/api/select/contributeur/nom_contributeur/PESNOT-PIN",
  //     callBack: (response) => {
  //       console.log("response", response.reqList);
  //     },
  //   });
  // },
};
</script>

<style lang="scss">
@import "../assets/css/components.scss";

#visualisationCorpsDePage {
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: $mobile-break-point) {
    padding: 0 5px;
    width: 100vw;
  }
}

#titreListeNumeros {
  text-align: left;
  font-size: 2.5em;
}

#zoneRecherche {
  margin-bottom: 50px;
  margin-top: 50px;
  justify-content: space-between;
  width: 600px;
  @media (max-width: $mobile-break-point) {
    flex-direction: column;
    width: 100%;
  }
}

#cards {
  display: flex;
  flex-wrap: wrap;
}

.boutonCard {
  width: 100% !important;
}
.card {
  margin: 20px auto;
  width: 200px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
  // @media (max-width: $mobile-break-point) {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  // }
}

.detailsNumero {
  display: flex;
  justify-content: space-between;
  /* padding-right: 50px; */
}

/* .imgCouvertureModif {
  width: 170px;
  margin-left: 60PX;
} */

.imgVisualisationContainer {
  height: 280px;
  // @media (max-width: $mobile-break-point) {
  //   width: 30%;
  // }
}

.alerte-stock-faible {
  position: absolute;
  display: flex;
  @include typo-medium;
  justify-content: center;
  width: 35px;
  height: 35px;
  left: -10px;
  top: -10px;
  border-radius: 50%;
}

.alerte-inventaire {
  position: absolute;
  display: flex;
  @include typo-medium;
  justify-content: center;
  width: 35px;
  height: 35px;
  left: -10px;
  top: -10px;
  background-color: lighten($danger-btn, 10);
  border-radius: 50%;
}

.card-body-partie1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  @media (max-width: $mobile-break-point) {
    height: auto;
  }
}

.card-title-custom {
  @include typo-medium;
  font-weight: bold;
  margin-bottom: 10px;
  //   font-size: 1em;
}

.details-numeros-container {
  margin-bottom: 10px;
}

.details-numero-card {
  // height: 50px;

  display: flex;
  justify-content: space-between;
}

.card-body {
  background-color: #e7e7e7;
  padding: 13px;
}

.bouton-container {
  // @media (max-width: $mobile-break-point) {
  //   border: 1px solid #000;
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  // }
}

.boutonCard {
  width: 160px;
  margin-bottom: 10px;
  // @media (max-width: $mobile-break-point) {
  //   width: 80px !important;
  //   // flex-direction: row !important;
  // }
}

.imgVisualisationCouverture {
  cursor: pointer;
}
</style>
