import Vue from 'vue'
import Vuex from 'vuex'
import $ from 'jquery'

import * as storeConst from './storeConst'

const RUNTIME_MODE_DEBUG = "RUNTIME DEBUG";
const RUNTIME_MODE_DEBUG_DETAILS = "RUNTIME DEBUG DETAIL";
const RUNTIME_MODE_PRODUCTION = "RUNTIME PRODUCTION";
const DONT_LOG = "DON'T LOG THIS"

const RUNTIME_MODE = RUNTIME_MODE_PRODUCTION


Vue.use(Vuex)

export var state = {
    barProgression: 60,
    afficherLogsRetour: true,
    afficherLogsConsole: true,
    screenIsPhone: false,
    requetesEnCours: 0,
    imgCadenas: storeConst.IMG_CADENAS,
    imgCadenasOuvert: storeConst.IMG_CADENAS_OUVERT,
    imgHorloge: storeConst.IMG_HORLOGE,
    imgValide: storeConst.IMG_VALIDE,
    imgErreur: storeConst.IMG_ERREUR,
    imgChecked: storeConst.IMG_CHECKED,
    imgUnchecked: storeConst.IMG_UNCHECKED,

    UNITE_FESTIVAL_DEPART: storeConst.UNITE_FESTIVAL_DEPART,
    UNITE_FESTIVAL_RETOUR: storeConst.UNITE_FESTIVAL_RETOUR,

    imgLivre: storeConst.IMG_LIVRE,
    imgLivreOuvert: storeConst.IMG_LIVRE_OUVERT,

    couvManquante: storeConst.COUV_MANQUANTE,
    imgSelectionnerNumero: storeConst.IMG_SELECTIONNER_NUMERO,
    messageAlerteModification: storeConst.MESSAGE_ALERTE_MODIFICATION,

    authorized: false,
    userConnected: false,
    user: {
        adminAccessGranted: false
    },

    listeNumeros: [],
    listeNumerosPrete: false,
    listeLieux: [],
    listeLieuxPrete: false,
    listeContributeurs: [],
    listeContributeursPrete: false,

    imgVerrouillageConst: storeConst.IMG_VERROUILLAGE,
    imgSuccessConst: storeConst.IMG_SUCCESS,

    modificationPresente: false,
    lieuVide: {
        nom_lieu: 'chargement...',
        adresse_lieu: 'chargement...',
        capacite_lieu: -1,
        quantite_lieu: 0
    },
    numeroVide: {
        id_numero: '',
        titre_numero: '',
        numero_numero: '',
        date_parution_numero: '',
        liste_contributions: [],
        liste_comptages: [],
        couverture_numero: storeConst.COUV_MANQUANTE,
        nombre_ventes_numero: 0,
        nombre_impressions_numero: 1,
        declarerPremiereImpression: false
    },
    contributeurVide: {
        identifiant: '',
        nom_contributeur: '-',
        prenom_contributeur: '-',
        date_naissance_contributeur: '-',
        email_contributeur: '-',
        tel_contributeur: '-',
        adresse_contributeur: {
            ville_adresse: 'Inconnue',
            code_postal_adresse: 'Inconnu',
            libelle_adresse: 'Inconnu',
            infos_complementaires_adresse: '',
        }
    },
    adresseInconnue: {
        ville_adresse: '',
        code_postal_adresse: '',
        libelle_adresse: '',
        infos_complementaires_adresse: '',
    },
    vignette: {
        visible: false,
        message: 'message',
        type: 'neutral',
        autoHide: true,
        detailsButton: false,
        detailsContent: ''
    },
    nomLieuDisabled: undefined,
    adresseLieuDisabled: undefined,
    modale: {
        allowQuitOnClick: false,
        visible: false,
        // content: {
        //     componentName: '',
        //     props: {}
        // },
        content: undefined,
        fetchedData: undefined,
    },
}

export var getters = {
    afficherLogsConsole(state) {
        return state.afficherLogsConsole
    },
    getToday() {
        var todayD = new Date();

        var month = (todayD.getMonth() + 1).toString();
        var day = (todayD.getDate()).toString();

        return todayD.getFullYear().toString() + '-'
            + (month.length < 2 ? '0' + month : month) + '-'
            + (day.length < 2 ? '0' + day : day);
    },
    modaleData(state) {
        return state.modale.fetchedData
    },
    listeNumerosFiltreeTriee: (state, getters) => (cleRecherche, optionDeTri, indexLieu) => {
        if (getters.listeNumerosTriee(optionDeTri, indexLieu) != undefined)
            return getters.listeNumerosTriee(optionDeTri, indexLieu).filter(
                (numero) => {
                    var numeroCorrespondant = true;
                    for (var recherche of cleRecherche.split(' ')) {
                        recherche = recherche.toLowerCase();
                        var contributions = numero.liste_contributions.filter(
                            (contribution) => {
                                return contribution.nom_contributeur.toLowerCase().includes(recherche) || contribution.prenom_contributeur.toLowerCase().includes(recherche)
                            });

                        numeroCorrespondant = numeroCorrespondant &&
                            (numero.titre_numero.toLowerCase().includes(recherche)
                                || numero.numero_numero.toLowerCase().includes(recherche)
                                || contributions.length > 0);
                    }
                    return numeroCorrespondant;
                }
            );
        else {
            return []
        }
    },
    listeNumerosTriee: (state, getters) => (optionDeTri, indexLieu) => {
        if (state.listeNumeros == undefined) return []
        var cmpFunc = undefined;
        if (optionDeTri == undefined) optionDeTri = 'numero'
        if (optionDeTri == "titre") {
            cmpFunc = function (a, b) {
                return (a.titre_numero > b.titre_numero) ? 1 : -1;
            }
        } else if (optionDeTri == "quantite") {
            cmpFunc = function (a, b) {
                var quantite1 = parseInt(a.liste_comptages[a.liste_comptages.length - 1].quantite_comptage)
                var quantite2 = parseInt(b.liste_comptages[b.liste_comptages.length - 1].quantite_comptage)
                if (indexLieu != undefined) {
                    if (a.liste_derniers_stockages[indexLieu] == undefined) return -1
                    if (b.liste_derniers_stockages[indexLieu] == undefined) return 1
                    quantite1 = parseInt(a.liste_derniers_stockages[indexLieu].quantite_stockage)
                    quantite2 = parseInt(b.liste_derniers_stockages[indexLieu].quantite_stockage)
                }
                return quantite1 > quantite2 ? 1 : -1;
            }
        } else if (optionDeTri == "numero") {
            cmpFunc = getters.cmpSortFunction
        } else if (optionDeTri == "nombreImpressions") {
            cmpFunc = function (a, b) {
                return (parseInt(a.nombre_impressions_numero) < parseInt(b.nombre_impressions_numero)) ? 1 : -1;
            }
        } else if (optionDeTri == "dateComptage") {
            cmpFunc = function (a, b) {
                if (a.liste_comptages.length == 0) return 1;
                if (b.liste_comptages.length == 0) return -1;
                var date1 = a.liste_comptages[a.liste_comptages.length - 1].date_comptage.split('-');
                var date2 = b.liste_comptages[b.liste_comptages.length - 1].date_comptage.split('-');
                return (date1 > date2) ? 1 : -1;
            }
        } else if (optionDeTri == "erreurComptage") {
            cmpFunc = function (a, b) {
                if (a.erreur_comptage && !b.erreur_comptage) return -1;
                if (!a.erreur_comptage && b.erreur_comptage) return 1;
                else {
                    var parseA = parseInt(a.numero_numero);
                    var parseB = parseInt(b.numero_numero);
                    if (!isNaN(parseA) && !isNaN(parseB))
                        return (parseInt(a.numero_numero) > parseInt(b.numero_numero)) ? 1 : -1;
                    else if (isNaN(parseA) && !isNaN(parseB)) return 1;
                    else if (!isNaN(parseA) && isNaN(parseB)) return -1;
                    else return a.numero_numero.toLowerCase() > b.numero_numero.toLowerCase() ? 1 : -1;
                }
            }
        } else if (optionDeTri == "ventes") {
            cmpFunc = function (a, b) {
                return parseInt(a.nombre_ventes_numero) > parseInt(b.nombre_ventes_numero) ? -1 : 1;
            }
        }

        return [...state.listeNumeros].sort(cmpFunc);
    },
    trouveNumeroParId: (state) => (id_numero) => {
        if (state.listeNumeros.length > 0) {
            var searchResult = state.listeNumeros.filter((numero) => { return numero.id_numero == id_numero });
            if (searchResult.length > 1 || searchResult.length == 0) return false
            return JSON.parse(JSON.stringify(searchResult[0]));
        } else return state.numeroVide
    },
    trouveLieuParId: (state) => (id_lieu) => {
        if (state.listeLieux.length > 0) {
            var searchResult = state.listeLieux.filter((lieu) => { return lieu.id_lieu == id_lieu });
            if (searchResult.length > 1 || searchResult.length == 0) return false
            return { ...searchResult[0] };
        } else return state.numeroVide
    },
    trouveContributeurParId: (state) => (id_contributeur) => {
        if (id_contributeur == 0) return state.contributeurVide
        if (state.listeContributeurs.length > 0) {
            var searchResult = state.listeContributeurs.filter((contributeur) => { return contributeur.id_contributeur == id_contributeur });
            if (searchResult.length > 1 || searchResult.length == 0) return false
            return JSON.parse(JSON.stringify(searchResult[0]));
        } else return state.contributeurVide
    },
    formatDateNomDuMois: (state) => (dateStr) => {
        const listeMois = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Aout',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ];

        var errMessage = 'format de date incorrect';
        if (dateStr != undefined) {
            var splitDate = undefined;
            if (dateStr.indexOf('/') != -1) {
                splitDate = dateStr.split('/');
                splitDate.reverse();
                if (splitDate.length == 3) splitDate.pop();
                splitDate.reverse();
            }
            else if (dateStr.indexOf('-') != -1) {
                splitDate = dateStr.split('-');
                if (splitDate.length == 3) splitDate.pop();
                splitDate.reverse();
            } else {
                if (state.afficherLogsConsole) console.warn("Impossible de convertir la date ", dateStr, "Pos #1")
                return errMessage
            }


            var month = parseInt(splitDate[0]);
            var year = parseInt(splitDate[1]);

            if (!(month >= 1 && month <= 12)) {
                if (state.afficherLogsConsole) console.warn("Impossible de convertir la date ", dateStr, "Pos #2")
                return errMessage
            }

            splitDate[0] = listeMois[parseInt(splitDate[0]) - 1];
            return splitDate.join(' ');
        } else {
            if (state.afficherLogsConsole) console.warn("Impossible de convertir la date ", dateStr, "Pos #3")
            return errMessage
        }
    },
    dateFrancaise: (state) => (date) => {
        if (date == undefined || date == '') return ''
        return date.split('-').reverse().join('/');
    },
    cmpSortFunction: (state) => (a, b) => {
        var parseA = parseInt(a.numero_numero);
        var parseB = parseInt(b.numero_numero);
        if (!isNaN(parseA) && !isNaN(parseB))
            return parseInt(a.numero_numero) - parseInt(b.numero_numero);
        else if (isNaN(parseA) && !isNaN(parseB)) return 1;
        else if (!isNaN(parseA) && isNaN(parseB)) return -1;
        else
            return a.numero_numero.toLowerCase() >
                b.numero_numero.toLowerCase()
                ? 1
                : -1;
    },
}

export var mutations = {
    setBarProgression(state, value) {
        state.barProgression = value;
    },
    definirAffichageLogsRetour(state, etat) {
        state.afficherLogsRetour = etat
    },
    definirAffichageLogsConsole(state, etat) {
        state.afficherLogsConsole = etat
    },
    definirScreenIsPhone(state, etat) {
        state.screenIsPhone = etat
    },
    ajouterRequete(state) {
        state.requetesEnCours += 1;
    },
    supprimerRequete(state) {
        if (state.requetesEnCours > 0) state.requetesEnCours -= 1;
    },
    definirDureeVignette(state, etat) {
        state.vignette.duree = etat
    },
    setAuthorization: (state, etat) => {
        state.authorized = etat
    },

    resetModificationPresente: state => state.modificationPresente = false,
    marquerModificationPresente: state => state.modificationPresente = true,

    resetListeNumerosPrete: (state) => {
        // state.listeNumeros=[]
        state.listeNumerosPrete = false
    },
    resetListeLieuxPrete: (state) => {
        // state.listeLieux=[]
        state.listeLieuxPrete = false
    },
    resetListeContributeursPrete: (state) => {
        // state.listeContributeurs=[]
        state.listeContributeursPrete = false
    },

    definirListeNumeros: (state, liste) => {
        state.listeNumeros = liste
        state.listeNumeros.forEach((numero) => {
            if (numero.liste_comptages.length == 0)
                numero.liste_comptages = [{
                    date_comptage: 'Aucun comptage',
                    id_comptage: 'noId',
                    quantite_comptage: 'inconnu',
                    aucunComptage: true
                }]
        })
        state.listeNumerosPrete = true
    },
    definirListeLieux: (state, liste) => {
        state.listeLieux = liste
        state.listeLieuxPrete = true
    },

    definirListeContributeurs: (state, liste) => {
        state.listeContributeurs = liste
        state.listeContributeursPrete = true
    },

    supprLieu: (state, lieu) => {
        state.listeLieux.splice(state.listeLieux.indexOf(lieu), 1)
    },

    /*** connection utilisateur *****/
    definirStatutConnexion: (state, etat) => {
        state.userConnected = etat;
    },
    definirUser: (state, user) => {
        state.user = user
    },

    /***** Thumbnail *****/
    displayMessage: (state, message) => {
        // v-model="vignette.visible" 
        // position = "centered"
        // :thumbnailText = "vignette.message"
        // :thumbnailType="vignette.type" 
        // :autoHide="vignette.type == 'success' && vignette.autoHide"
        // :closeCross="vignette.type == 'error' || !vignette.autoHide"
        // :elementClickable="vignette.elementClickable"
        // :detailsButton="vignette.detailsButton"
        // @details-click="afficherDetailsVignette"

        if (message.type == undefined) message.type = 'neutral'
        if (message.autoHide == undefined) message.autoHide = true
        if (message.duree == undefined) message.duree = 5000

        state.vignette.duree = message.duree
        state.vignette.detailsButton = undefined
        state.vignette.message = message.texte,
            state.vignette.type = message.type
        state.vignette.autoHide = message.autoHide
        state.vignette.visible = true
    },


    /***** Modale *****/
    definirVisibiliteModale: (state, visible) => {
        state.modale.visible = visible
        if (!visible) state.modale.content = undefined
    },
    masquerModale: (state, options) => {
        if (options != undefined) {
            if (options.ignoreAllWaitingWindows) {
                state.modale.content = undefined
                state.modale.visible = false
                return
            }
        }
        let waitingContent = undefined
        if (state.modale.content != undefined)
            if (state.modale.content.props != undefined)
                if (state.modale.content.props.params != undefined)
                    if (state.modale.content.props.params.closeAllAfter != true) {
                        waitingContent = state.modale.content.props.params.waitingContent
                    } else {
                        state.modale.visible = false
                        state.modale.content = undefined
                    }
        if (waitingContent != undefined) state.modale.content = waitingContent
        else {
            state.modale.content = undefined
            state.modale.visible = false
        }
    },
    afficherModale: (state) => {
        state.modale.visible = true
    },
    definirContenuModale: (state, contenu) => { state.modale.content = contenu },
    definirComportementModale: (state, etat) => { state.modale.allowQuitOnClick = etat },
    modaleFetch: (state, contenu) => {
        state.modale.fetchedData = contenu
    }


}

export var actions = {
    consoleMessage(context, data) {
        if (context.state.afficherLogsConsole) {
            if (data == undefined) return console.error("Aucun message transmis lors de l'appel console")
            if (typeof (data) == "string") return console.log(data)
            if (typeof (data) == "object" && data.message == undefined) {
                for (let key in data) {
                    console.log(key, data[key])
                }
                return
            }
            if (data.type == undefined) data.type = "log"
            console[data.type](data.message)
        }
    },
    clearData(context) {
        context.commit('definirListeNumeros', []);
        context.commit('definirListeLieux', []);
        context.commit('definirListeContributeurs', []);
        context.commit('masquerModale')
    },
    initialisation(context) {
        context.dispatch('clearData');
        context.commit(storeConst.RESET_LISTE_NUMEROS_PRETE)
        context.commit(storeConst.RESET_LISTE_LIEUX_PRETE)
        context.commit(storeConst.RESET_LISTE_CONTRIBUTEURS_PRETE)

        context.dispatch(storeConst.MISE_A_JOUR_NUMEROS)
        context.dispatch(storeConst.MISE_A_JOUR_LIEUX)
        context.dispatch(storeConst.MISE_A_JOUR_CONTRIBUTEURS)
    },
    modaleAlert(context, data) {
        /* Options pour data : 
            {
                message: string,
                callBack: optionnel
            }
            data peut aussi être directement un string, auquel cas une modale est affichée avec le message
        */

        var contenuAlert = {
            componentName: 'ComposantModaleAlert',
            props: { params: undefined }
        }

        if (typeof (data) == 'string') data = { message: data }

        // contenuAlert.props = { params: { message: data } }
        contenuAlert.props = {
            params: {
                ...data,
                waitingContent: context.state.modale.content
            }
        }

        /*  Structure de l'objet envoyé, équivalent à <ComposantModaleAlert params = {message: '...', callBack: callBaclFn} />
            contenuAlert: {
                componentName: 'ComposantModaleAlert',
                props: { 
                    params: {
                        message: string,
                        callBack: ?
                    }
                }
            }
        */
        context.commit('definirContenuModale', contenuAlert)
        context.commit('definirVisibiliteModale', true)
    },
    modaleOuiNon(context, data) {
        /* Options pour data : 
           {
               message: string,
               callBackYes: optionnel
               callBackNo: optionnel
               

           }

           data peut aussi être directement un string, auquel cas une modale est afficher avec le message et un bouton OK
       */
        context.commit(
            'definirContenuModale',
            {
                componentName: 'ComposantModaleOuiNon',
                props: {
                    params: {
                        ...data,
                        waitingContent: context.state.modale.content
                    }
                }
            }
        )
        context.commit('definirVisibiliteModale', true)
    },
    modaleComposant(context, data) {
        context.commit('definirContenuModale', data)
        context.commit('definirVisibiliteModale', true)
    },
    initDetectionModification(context) {
        context.commit('resetModificationPresente')
        $('input').off('.modifCheck');
        $('input').on('change.modifChek keypress.modifChek', (e) => {
            if (!$(e.target).hasClass('ignore-detection'))
                context.commit('marquerModificationPresente')
        })
        $('input').on('keyup.modifCheck', (e) => { // Detection de backspace et suppr
            if (!$(e.target).hasClass('ignore-detection'))
                if (e.which == 46 || e.which == 8) context.commit('marquerModificationPresente')
        })
        $('.ignore-detection').off('.modifCheck')
    },
    miseAJourContributeurs(context) {
        context.commit(storeConst.RESET_LISTE_CONTRIBUTEURS_PRETE)
        context.dispatch('checkConnection')
        context.dispatch(
            'phpPost',
            {
                phpFile: './assets/php/listeContributeurs.php',
                disableThumbnailForAll: true,
                callBack: (response) => {
                    if (response.reqSuccess) {
                        context.commit('definirListeContributeurs', response.reqList)
                        // state.listeContributeurs = response.reqList
                        // context.commit('definirListeContributeursPrete', true)
                    }
                }
            }
        )
    },
    miseAJourNumeros(context) {
        context.commit(storeConst.RESET_LISTE_NUMEROS_PRETE)
        context.dispatch(
            'phpPost',
            {
                // phpFile: './assets/php/__shortListeNumeros.php', 
                phpFile: './assets/php/listeNumeros.php',
                disableThumbnailForAll: true,
                callBack: (response) => {
                    if (response.reqSuccess) {
                        context.commit('definirListeNumeros', response.reqList)
                        context.commit('definirListeNumeros', context.getters.listeNumerosTriee())
                    }
                }
            }
        )

    },
    miseAJourLieux(context) {
        context.commit(storeConst.RESET_LISTE_LIEUX_PRETE)
        context.dispatch(
            'phpPost',
            {
                phpFile: './assets/php/listeLieux.php',
                disableThumbnailForAll: true,
                callBack: (response) => {
                    if (response.reqSuccess) {
                        context.commit('definirListeLieux', response.reqList)
                    }
                }
            }
        )
    },
    avertissementAucunDispatch(context, action) {
        if (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || RUNTIME_MODE == RUNTIME_MODE_DEBUG || this.context.state.afficherLogsRetour) console.warn('Dispatch inconnu :', action);
    },
    phpPost(context, data) {
        /*
            Paramétrages possibles de l'objet data : 
            {   
                sendObject: objet a envoyer (ou à modifier si 'modifications' n'est pas undefined
                modifications: { (si undefined, l'objet est envoyé tel quel)
                    param1: newValue1
                    param2: newValue2
                    ...
                }
                phpFile: "*.php"
                displayLoader: String message => Si defini, affiche une fenêtre avec un loader et le texte 'message' jusqu'au retour de la requête php. La fenêtre précédente est ensuite réaffichée
                disableThumbnail: true / false (false si undefined) - à noter : si disableThumbnail est false, la vignette s'affichera tout de même en cas d'erreur
                disableThumbnailForAll: true /false - si false, aucun message n'apparaîtra, même en cas d'échec
                autoHideThumbnail: true / false (true si undefined) - à noter : si auto hide est à false, une croix de fermeture sera affiché
                callBack: non appelée si undefined
                dispatchAtTheEnd: nom (ou array de noms) de l'action à dispatcher à la fin des opérations
                                  à noter : les dispatch sont asynchrone et  en conséquence l'ordre dans 
                                  lequel sont effectué les dispatch n'est pas garanti
                dispatchOnlyIfSuccess: par defaut true
                
            }
    
            si callBack est definie, un objet est passé en argument :
            {
                reqSuccess: true / false
                reqType: string
                reqMessage: string
                reqList: -> - si le retour est le fruit de plusieurs actions, contient le résultat de chaque action
                            - si la demande concerne l'obtention d'une liste, contient la liste (par exemple, appel à 'listeNumeros.php') ou la liste des erreurs si l'opération n'a pas abouti
                            - undefined sinon
    
                newObject: ...   Note : newObject peut-être :
                                    - l'objet modifié si l'opération d'écriture a réussi
                                    - l'objet d'origine (si l'objet modifications était présent) si l'opération a échoué
                                    - undefined si aucun objet n'a été passé
            }
            utilise par default la vignette de bas de page pour afficher le retour de l'operation demandée
        */


        if (data.dontSendObjectBack == undefined) data.dontSendObjectBack = false;
        if (data.postType == undefined) data.postType = 'post'
        if (data.closeAllAfter == undefined) data.closeAllAfter = false
        if (data.autoHideThumbnail == undefined) data.autoHideThumbnail = true
        if (data.disableThumbnail == undefined) data.disableThumbnail = false
        if (data.disableThumbnailForAll == undefined) data.disableThumbnailForAll = false
        if (data.dispatchOnlyIfSuccess == undefined) data.dispatchOnlyIfSuccess = false
        if (data.dontTrackRequest == undefined) data.dontTrackRequest = false

        if (data.displayLoader != undefined) {
            let message = 'Patientez...'
            if (data.displayLoader != true) message = data.displayLoader
            context.dispatch('modaleAlert', {
                message: message,
                displayLoader: true,
                closeAllAfter: data.closeAllAfter
            })
        }

        // context.state.vignette.detailsContent = ''

        const dispatchList = [
            storeConst.MISE_A_JOUR_NUMEROS,
            storeConst.MISE_A_JOUR_LIEUX,
            storeConst.MISE_A_JOUR_CONTRIBUTEURS,
            'initialisation'
        ]

        var sendObject = data.sendObject;
        var modifications = data.modifications

        var sendObjectAvantModification = sendObject == undefined ? undefined : JSON.parse(JSON.stringify(sendObject))
        var modificationsPrecises = modifications != undefined

        context.commit('definirDureeVignette', 5000)

        // modification des propriétés précisées dans 'modifications'

        if (modificationsPrecises) {
            var listePropSendObject = Object.getOwnPropertyNames(sendObject)
            var listePropTransmises = Object.getOwnPropertyNames(modifications)
            for (var prop of listePropTransmises) {
                if (listePropSendObject.indexOf(prop) != -1) {
                    sendObject[prop] = modifications[prop]
                } else {
                    if (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || RUNTIME_MODE == RUNTIME_MODE_DEBUG || context.state.afficherLogsRetour) console.error('Propriété transmise inconnue dans l\'objet à modifier : ' + prop + ' n\'appartient pas à l\'objet')
                }
            }
        }

        var gestionRetourPhp = (retourPhp) => {
            if (!data.dontTrackRequest) context.commit('supprimerRequete')
            if (data.displayLoader) context.commit('masquerModale')
            var message;
            try {
                message = JSON.parse(retourPhp)
            } catch (e) {
                context.state.vignette.type = 'error'
                message = {
                    reqType: 'Php error ' + data.phpFile,
                    reqMessage: retourPhp,
                    reqSuccess: false
                }
            }

            // DEBUG 

            if (!message.reqSuccess) {
                if (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || context.state.afficherLogsRetour) console.error(message);
                else if (RUNTIME_MODE == RUNTIME_MODE_DEBUG) console.error(message.reqType, ':', message.reqMessage);
            } else if (context.state.afficherLogsRetour) console.log(message);


            // Recherche de warnings

            function collectWarnings(retourPhp) {
                let message = JSON.parse(JSON.stringify(retourPhp));
                let warningMessageList = []
                if (message.reqList != undefined) {
                    for (let sousMessage of message.reqList) {
                        warningMessageList = warningMessageList.concat(collectWarnings(sousMessage))
                    }
                }
                if (message.warning) warningMessageList.push({
                    reqType: message.reqType,
                    reqSuccess: message.reqSuccess,
                    reqMessage: message.reqMessage,
                    warnMessage: message.warnMessage,
                    warning: true
                })
                return warningMessageList
            }

            let warningsList = collectWarnings(message);
            if (warningsList.length > 0) {
                if (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || RUNTIME_MODE == RUNTIME_MODE_DEBUG || context.state.afficherLogsRetour) console.warn('Warnings', warningsList);
            }

            if (!message.reqSuccess && modificationsPrecises) {
                sendObject = sendObjectAvantModification
            }

            // Appel des actions définies dans dispatchAtTheEnd

            if (data.dispatchAtTheEnd != undefined && (!data.dispatchOnlyIfSuccess || data.dispatchOnlyIfSuccess && message.reqSuccess)) {
                if (!Array.isArray(data.dispatchAtTheEnd)) {
                    var actionToDispatch = undefined
                    if (dispatchList.indexOf(data.dispatchAtTheEnd) != -1)
                        actionToDispatch = data.dispatchAtTheEnd
                    else actionToDispatch = 'avertissementAucunDispatch'
                    context.dispatch(actionToDispatch, data.dispatchAtTheEnd)

                } else for (var action of data.dispatchAtTheEnd) {
                    actionToDispatch = undefined
                    if (dispatchList.indexOf(action) != -1)
                        actionToDispatch = action
                    else actionToDispatch = 'avertissementAucunDispatch'
                    context.dispatch(actionToDispatch, action)
                }
            }

            if (!data.dontSendObjectBack) message.newObject = sendObject

            // Appel de la fonction de callBack

            if (data.callBack != undefined) data.callBack(message)


            // Ecriture des logs
            if (message.reqType != "Session inventaire" && message.reqCode != DONT_LOG) {
                $.post(
                    "assets/php/ajouterLog.php",
                    {
                        idContributeur: context.state.user.id_contributeur,
                        identifiantContributeur: context.state.user.identifiant,
                        messageALoguer: JSON.stringify(message)
                    },
                    (data) => {
                        try {
                            data = JSON.parse(data)
                        } catch (err) {
                            data = {
                                reqType: "Ecriture log",
                                reqSuccess: false,
                                reqMessage: "Erreur retour log : " + err.message + " -- " + data
                            }
                        }
                        if (!data.reqSuccess && (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || context.state.afficherLogsRetour)) console.error(data)
                    }
                )
            }

            if ((!data.disableThumbnail || !message.reqSuccess) && !data.disableThumbnailForAll) { // Affichage de la vignette information

                // affichage d'un bouton "ℹ️" pour obtenir la liste des messages retour
                // le contenu du message a afficher en cas de click est stocké dans vignette.detailsContent
                context.state.vignette.detailsContent = '';
                if (message.reqList != undefined && (RUNTIME_MODE == RUNTIME_MODE_DEBUG_DETAILS || RUNTIME_MODE == RUNTIME_MODE_DEBUG)) {
                    context.state.vignette.detailsButton = true;
                    context.state.vignette.detailsContent = '<h3>Détails des messages </h3>';
                    message.reqList.forEach((subMessage) => {
                        let messageClass = subMessage.reqSuccess ? 'successMessage' : 'errorMessage'
                        context.state.vignette.detailsContent += '<div class="' + messageClass + '" >- ' + subMessage.reqType + ' : ' + subMessage.reqMessage + '</div><br>'
                    })
                    // context.state.vignette.detailsContent += warningLine
                } else context.state.vignette.detailsButton = false;
                context.state.vignette.message = message.reqType + " : " + message.reqMessage;
                context.state.vignette.type = message.reqSuccess ? 'success' : 'error';
                context.state.vignette.autoHide = data.autoHideThumbnail
                context.state.vignette.visible = true;
            }

        }

        var gestionErreurAjax = function (AJAXdata) {
            if (data.displayLoader) context.commit('masquerModale')
            if (!data.dontTrackRequest) context.commit('supprimerRequete')
            var returnMessage = {
                reqMessage: 'Erreur ' + AJAXdata.status + ' | ' + AJAXdata.statusText + ' | ' + AJAXdata.responseText,
                reqSuccess: false,
                reqType: 'Ajax error',
                newObject: sendObjectAvantModification,
                AJAXdata
            }
            if (data.callBack != undefined) data.callBack(returnMessage);
            sendObject = sendObjectAvantModification
            if (!data.disableThumbnailForAll) {
                context.state.vignette.message = 'Erreur ' + AJAXdata.status + ' | ' + AJAXdata.statusText
                context.state.vignette.type = 'error';
                context.state.vignette.visible = true;
            }
            if (context.state.afficherLogsRetour) console.error(returnMessage)
        }

        if (!data.dontTrackRequest) context.commit('ajouterRequete')

        if (data.postType == 'post') $.post(data.phpFile, sendObject, gestionRetourPhp).fail(gestionErreurAjax)

        if (data.postType == 'ajax') {
            $.ajax({
                url: data.phpFile,    // Upload le fichier dans le répertoire tmp et stocke l'info dans $_SESSION['fichierCouvTmp']
                type: 'post',                               // La BDD ne sera modfié qu'après validation des modifs / enregistrement du nouveau numéro
                data: data.sendObject,
                contentType: false,
                processData: false,
                success: gestionRetourPhp,
                error: gestionErreurAjax
            });
        }

        // gestionRetourPhp ).fail(gestionErreurAjax)


        return (context.state.vignette.type == 'success')
    },
    checkConnection(context) {
        context.dispatch(
            'phpPost',
            {
                phpFile: './assets/php/checkConnection.php',
                disableThumbnailForAll: true,
                callBack: (response) => {
                    context.commit(storeConst.USER_CONNECTED, response.reqSuccess);

                    if (context.state.userConnected) {
                        context.commit('definirUser', response.user);
                    }
                    else context.commit('definirUser', context.state.contributeurVide);
                }
            }
        )
    },
}

// export var store = new Vuex.Store({

export var storeConfig = {
    state,
    getters,
    mutations,
    actions
}
// )