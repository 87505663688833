<template>
  <div 
    class='radio disabledInfo' 
    :class="disabled && disabledInfo!='' ? 'infobulle' : ''"
    :bulle-content="disabledInfo"  
  >
      <input
        ref='checkbox'
        type="radio" 
        :name="radioGroup"
        :id="idComputed()" 
        :value="value" 
        :disabled="disabled"
        @change="$emit('change', value)"
        v-model="checkStatus"
      /><label :for="idComputed()" v-html="label"></label>
  </div>
</template>

<script>
export default {
    name: 'ComposantRadio',
    model: {
        prop: 'vModelValue',
        event: 'change'
    },
    props: {
        radioGroup: {default: 'defaultGroupe'}, // The group among which the radio is picked by user
        vModelValue: {default: 'empty'},        // prop only used for v-model
        label: { default: 'empty'},             // the displayed label
        value: {                                // the value sent to v-model or to @change event
            default: function () {              // Default is the label value, which allows you to only fill the label value
                return this.label
            }
        },
        checked: {default: false},
        disabled: { default: false },
        disabledInfo: { default: "" }
    },
    data: ()=>{
        return { 
            salt: undefined,
            privateValue: 'empty',
            checkStatus: false
        }
    },
    methods: {
        update(){
            this.$emit('change', this.privateValue);
        },
        idSalt() {
            if (this.salt == undefined) this.salt = Math.round(Math.random()*1000000)
            return this.salt
        },
        idComputed() {
            return 'radio-' + this.labelComputed + '-' + this.idSalt()
        }
    },
    computed: {
        labelComputed() {
            return this.label.replace(/ /g, '-')
        }
    },
    mounted: function () {
        this.$refs.checkbox.checked = this.vModelValue == this.value
    }
}
</script>

<style lang="scss">
// .radio{

// }
input {
    margin-right: 3px;
}
</style>