<template>
  <div class="frame-generale">
    <!-- <h1>Création d'une nouvelle session festival</h1> -->
    <div class="corps-de-page">
      <ComposantParametresStatistiques
        :updateParams="updateParams"
        :parametresVisualisation="parametresVisualisation"
        :checkListeNumerosTriee="checkListeNumerosTriee"
      />
      <div class="graphique-wrapper">
        <h1 class="graph-title">{{ currentTitle }}</h1>
        <div class="frame-graphique" @click="graphClick">
          <canvas id="graphique"></canvas>
        </div>
      </div>
    </div>
    <ComposantInfosStatistiques :infos="listeInfosComplementaires" />
    <!-- <div class="btn btn-secondary" @click="debug">Tests</div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { consoleMessage } from "../js/consoleMessage.js";

function createDataSet({
  label,
  backgroundColor,
  borderColor,
  data,
  graphNumber,
  graphDirection,
  type,
  yAxisID,
}) {
  if (yAxisID == undefined) yAxisID = "y";
  if (graphNumber == undefined) graphNumber = 1;
  if (borderColor == undefined) borderColor = colorSet[graphNumber];
  if (backgroundColor == undefined) backgroundColor = borderColor;
  // if (graphDirection == undefined) graphDirection = HORIZONTAL;

  return {
    label,
    backgroundColor,
    borderColor,
    data,
    type,
    yAxisID,
    // indexAxis: HORIZONTAL,
  };
}

function createInfosComplementaire({ label, data }) {
  return { label, data };
}

const colorSet = ["#845EC2", "#D65DB1", "#FF6F91", "#FF9671", "#FFC75F", "#F9F871"];

const HORIZONTAL = "x";
const VERTICAL = "y";

import {
  MODE_VISUALISATION_GLOBAL,
  MODE_VISUALISATION_SOLO,
  ORDRE_CROISSANT,
  ORDRE_DECROISSANT,
  ORDRE_CHRONOLOGIQUE,
  FILTRE_SUPERIEUR,
  FILTRE_INFERIEUR,
  PERIODE_VISUALISATION_DEFINIE,
  PERIODE_VISUALISATION_ENTIERE,
  PERIODE_VISUALISATION_PREMIERS_MOIS,
  TYPE_CLASSEMENT_STOCK,
  TYPE_VISUALISATION_STOCK,
  TYPE_VISUALISATION_VENTES,
  TYPE_CLASSEMENT_RUPTURE_DE_STOCK,
  INPUT_PREMIERS_MOIS,
  INPUT_DATE_DEPART,
  INPUT_DATE_FIN,
  INPUT_QUANTITE_FILTRE,
  LISTE_NUMEROS_A_VISUALISER,
  LISTE_NUMEROS_TRIEE,
  TYPE_VISUALISATION,
  MODE_VISUALISATION,
  PERIODE_VISUALISATION,
  TYPE_CLASSEMENT,
  ORDRE_CLASSEMENT,
  FILTRE_SUPERIEUR_OU_INFERIEUR,
  MODE_VISUALISATION_CLASSEMENT,
  TYPE_CLASSEMENT_VENTES,
  TYPE_CLASSEMENT_VITESSE,
  MODE_VISUALISATION_MULTIPLE,
  TOUS_LIEUX_CONFONDUS,
  LIEU_A_VISUALISER,
  FILTRE_INDIFFERENT,
  NO_RECOMPUTE_OPTION_LIST,
  FILTRE_EGALITE,
  TYPE_CLASSEMENT_RENTABILITE,
  MODE_VISUALISATION_FINANCES,
  LISTE_TYPE_CLASSEMENT,
  AUCUN_FESTIVAL_CHOISI,
  FESTIVAL_A_VISUALISER,
  DEFAULT_MODE_VISUALISATION,
  DEFAULT_TYPE_VISUALISATION,
  DEFAULT_PERIODE_VISUALISATION,
  DEFAULT_TYPE_CLASSEMENT,
  DEFAULT_LIEU_A_VISUALISER,
  DEFAULT_FESTIVAL_A_VISUALISER,
  DEFAULT_ORDRE_CLASSEMENT,
  DEFAULT_FILTRE_SUPERIEUR_OU_INFERIEUR,
  INPUT_DUREE_PROJECTION,
  CHECKBOX_AFFICHER_RENTABLES,
  CHECKBOX_AFFICHER_NON_RENTABLES,
  CHECKBOX_MASQUER_VALEURS_NEGATIVES,
  CHECKBOX_AFFICHER_PACK,
  CHECKBOX_AFFICHER_HORS_SERIE,
} from "../js/globalConst";

const RECUL_CALCUL_VITESSE_VENTES = 6;
const RECUL_MINIMAL_CALCUL_VITESSE_VENTES = 0;

const emptyGraph = {
  labels: [],
  data: {
    labels: [],
    datasets: [
      {
        label: "Choisissez une option",
        data: [],
      },
    ],
  },
  options: {
    indexAxis: HORIZONTAL,
    elements: {
      point: {
        hitRadius: 10,
        radius: 5,
      },
    },
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 18,
          },
        },
      },
    },
  },
  type: "bar",
};

const defaultParams = {
  [FILTRE_SUPERIEUR_OU_INFERIEUR]: DEFAULT_FILTRE_SUPERIEUR_OU_INFERIEUR,
  [INPUT_DATE_DEPART]: 0,
  [INPUT_DATE_FIN]: 0,
  [INPUT_PREMIERS_MOIS]: 6,
  [INPUT_QUANTITE_FILTRE]: 0,
  [INPUT_DUREE_PROJECTION]: 0,
  [LIEU_A_VISUALISER]: DEFAULT_LIEU_A_VISUALISER,
  [FESTIVAL_A_VISUALISER]: DEFAULT_FESTIVAL_A_VISUALISER,
  [LISTE_NUMEROS_A_VISUALISER]: [],
  [LISTE_NUMEROS_TRIEE]: [],
  [MODE_VISUALISATION]: DEFAULT_MODE_VISUALISATION,
  [ORDRE_CLASSEMENT]: DEFAULT_ORDRE_CLASSEMENT,
  [PERIODE_VISUALISATION]: DEFAULT_PERIODE_VISUALISATION,
  [TYPE_CLASSEMENT]: DEFAULT_TYPE_CLASSEMENT,
  [TYPE_VISUALISATION]: DEFAULT_TYPE_VISUALISATION,
  [CHECKBOX_AFFICHER_RENTABLES]: true,
  [CHECKBOX_AFFICHER_NON_RENTABLES]: true,
  [CHECKBOX_MASQUER_VALEURS_NEGATIVES]: false,
  [CHECKBOX_AFFICHER_PACK]: false,
  [CHECKBOX_AFFICHER_HORS_SERIE]: false,
};

const indexNumeroRentable = 0;
const indexNumeroNonRentable = 1;

export default {
  name: "pageStatistiques",
  components: {
    ComposantParametresStatistiques: () => import("./composantParametresStatistiques"),
    ComposantInfosStatistiques: () => import("./composantInfosStatistiques"),
  },
  data: () => {
    return {
      graphConfig: emptyGraph,
      parametresVisualisation: defaultParams,
      listeFonctionsClassement: {},
      graphDirection: VERTICAL,
      graphInfos: undefined,
      graphique: undefined,
      listeEnCours: [],
      listeInfosComplementaires: [],
      nombreDeDonnees_AnalyseEnCours: 0,
      nombreDeNumerosSimultanes: 0,
      screenIsPhone: false,
      valeursNegativesPresentes: false,
      forceRecompute: 0,
      updateListe: false,
    };
  },
  computed: {
    ...mapGetters([
      "listeNumerosTriee",
      "formatDateNomDuMois",
      "trouveNumeroParId",
      "dateFrancaise",
    ]),
    graphTypeDirection() {
      if (
        this.modeVisualisation == MODE_VISUALISATION_CLASSEMENT &&
        this.typeClassement == TYPE_CLASSEMENT_RENTABILITE
      )
        return VERTICAL;
      return HORIZONTAL;
    },

    dureeProjection() {
      return this.parametresVisualisation[INPUT_DUREE_PROJECTION];
    },
    periodeVisualisation() {
      return this.parametresVisualisation[PERIODE_VISUALISATION].value;
    },
    listeAVisualiser() {
      if (!Array.isArray(this.parametresVisualisation.listeNumerosAVisualiser)) {
        return [this.parametresVisualisation.listeNumerosAVisualiser];
      } else {
        return this.parametresVisualisation.listeNumerosAVisualiser;
      }
    },
    typeVisualisation() {
      return this.parametresVisualisation[TYPE_VISUALISATION].value;
    },
    modeVisualisation() {
      return this.parametresVisualisation[MODE_VISUALISATION].value;
    },
    ordreClassement() {
      return this.parametresVisualisation[ORDRE_CLASSEMENT].value;
    },
    typeClassement() {
      return this.parametresVisualisation[TYPE_CLASSEMENT].value;
    },
    filtreSuperieurOuInferieur() {
      return this.parametresVisualisation[FILTRE_SUPERIEUR_OU_INFERIEUR].value;
    },
    quantiteFiltreSuperieurOuInferieur() {
      return parseInt(this.parametresVisualisation[INPUT_QUANTITE_FILTRE]);
    },
    periodeVisualisationLabel() {
      return this.parametresVisualisation[PERIODE_VISUALISATION].label;
    },
    listeAVisualiserLabel() {
      if (!Array.isArray(this.parametresVisualisation.listeNumerosAVisualiser)) {
        return [this.parametresVisualisation.listeNumerosAVisualiser];
      } else {
        return this.parametresVisualisation.listeNumerosAVisualiser;
      }
    },
    typeVisualisationLabel() {
      return this.parametresVisualisation[TYPE_VISUALISATION].label;
    },
    modeVisualisationLabel() {
      return this.parametresVisualisation[MODE_VISUALISATION].label;
    },
    ordreClassementLabel() {
      return this.parametresVisualisation[ORDRE_CLASSEMENT].label;
    },
    typeClassementLabel() {
      return this.parametresVisualisation[TYPE_CLASSEMENT].label;
    },
    filtreSuperieurOuInferieurLabel() {
      return this.parametresVisualisation[FILTRE_SUPERIEUR_OU_INFERIEUR].label;
    },
    lieuAVisualiser() {
      return this.parametresVisualisation[LIEU_A_VISUALISER].value;
    },
    afficherNumerosRentables() {
      return this.parametresVisualisation[CHECKBOX_AFFICHER_RENTABLES];
    },
    afficherNumerosNonRentables() {
      return this.parametresVisualisation[CHECKBOX_AFFICHER_NON_RENTABLES];
    },
    masquerValeursNegatives() {
      return this.parametresVisualisation[CHECKBOX_MASQUER_VALEURS_NEGATIVES];
    },
    festivalAVisualiser() {
      return this.parametresVisualisation[FESTIVAL_A_VISUALISER].value;
    },
    checkboxAfficherPack() {
      return this.parametresVisualisation[CHECKBOX_AFFICHER_PACK];
    },
    checkboxAfficherHorsSerie() {
      return this.parametresVisualisation[CHECKBOX_AFFICHER_HORS_SERIE];
    },

    currentTitle() {
      let title = this.modeVisualisationLabel;

      if (this.modeVisualisation == MODE_VISUALISATION_CLASSEMENT)
        title += " - " + this.typeClassementLabel;
      else if (
        this.modeVisualisation == MODE_VISUALISATION_SOLO ||
        this.modeVisualisation == MODE_VISUALISATION_MULTIPLE
      ) {
        title +=
          " / " + this.typeVisualisationLabel + " - Période : " + this.periodeVisualisationLabel;
      }
      return title;
    },
    listeNumerosFiltree() {
      const listeFiltree = this.listeNumerosTriee().filter(
        (numero) =>
          (!numero.estUnPack || this.checkboxAfficherPack) &&
          (!numero.estUnHorsSerie || this.checkboxAfficherHorsSerie)
      );
      return listeFiltree;
    },
    graphLabels() {
      return this.graphConfig.data.labels;
    },
    graphLegend() {
      if (this.typeClassement == TYPE_CLASSEMENT_VITESSE)
        return "Vitesse de ventes (nombre de ventes / mois)";
      if (this.typeClassement == TYPE_CLASSEMENT_RUPTURE_DE_STOCK)
        return ["Durée (en mois) avant rupture de stock", "Vitesses de ventes"];
      if (this.typeClassement == TYPE_CLASSEMENT_RENTABILITE) return "Rentabilité (en mois)";
      if (this.typeClassement == TYPE_CLASSEMENT_STOCK) return "Classement par quantité en stock";
      if (this.typeClassement == TYPE_CLASSEMENT_VENTES) return "Classement par nombre de ventes";
      else return "Option inconnue";
    },
  },
  methods: {
    ...mapActions(["phpPost", "consoleMessage", "modaleAlert"]),
    graphClick() {
      var activePoint = this.graphique.getActiveElements()[0];
      if (activePoint == undefined) return;
      this.consoleMessage({ activePoint });

      let routeData;

      // if (this.modeVisualisation == MODE_VISUALISATION_GLOBAL) {
      //   let infos = this.filtrerListeEnCours()[activePoint.index];
      //   routeData = this.$router.resolve(
      //     "/edition/consulter-" + infos.id_numero
      //   );
      // } else
      if (this.modeVisualisation == MODE_VISUALISATION_SOLO) {
        let numero = this.listeAVisualiser[activePoint.datasetIndex];
        if (this.typeVisualisation == TYPE_VISUALISATION_STOCK) {
          let comptage = numero.liste_comptages[activePoint.index];
          routeData = this.$router.resolve(
            `/modifcomptage-${numero.id_numero}/${comptage.id_comptage}`
          );
        } else {
          routeData = this.$router.resolve(`/edition/consulter-${numero.id_numero}`);
        }
      }

      if (routeData) window.open(routeData.href, "_blank");
    },
    dontNeedRecompute(option) {
      return NO_RECOMPUTE_OPTION_LIST.indexOf(option) != -1;
    },
    checkListeNumerosTriee() {
      if (this.parametresVisualisation.listeNumerosTriee.length == 0 || this.updateListe) {
        this.parametresVisualisation = {
          ...this.parametresVisualisation,
          listeNumerosTriee: this.listeNumerosFiltree,
        };
        this.updateListe = false;
        this.$forceUpdate();
      }
    },
    /**
     * input []
     * - ventes_du_mois
     * - ventes_totales_a_date
     * - date_comptage
     *
     * return {}
     * - data[],
     * - dates[],
     * - datesBrutes[]
     *
     */
    convertirComptagesEnVentes(listeComptages) {
      let listeVentes = listeComptages.filter((comptage) => {
        return comptage.ventes_du_mois != undefined;
      });

      let data = [];
      let dates = [];
      let datesBrutes = [];
      listeVentes.forEach((vente, index) => {
        data.push(vente.ventes_totales_a_date);
        datesBrutes.push(vente.date_comptage);
        dates.push(this.formatDateNomDuMois(vente.date_comptage));
      });

      return this.interpolationSiBesoin({
        data,
        dates,
        datesBrutes,
      });
    },
    /**
     * input {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     *
     * output {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     */
    listeEntreDatesDepartEtFin(numero) {
      let dateDepart = new Date(this.parametresVisualisation.dateDepart);
      // .addMonths(-2);
      let dateFin = new Date(this.parametresVisualisation.dateFin);
      let borneInf = 0;
      while (
        new Date(numero.datesBrutes[borneInf]) < dateDepart &&
        borneInf < numero.datesBrutes.length
      )
        borneInf++;
      let borneSup = borneInf;
      while (
        new Date(numero.datesBrutes[borneSup + 1]) < dateFin &&
        borneSup < numero.datesBrutes.length - 1
      )
        borneSup++;
      let datesBrutes = numero.datesBrutes.filter(
        (date, index) => index >= borneInf && index <= borneSup
      );
      return {
        ...numero,
        data: numero.data.filter((data, index) => index >= borneInf && index <= borneSup),
        datesBrutes,
        dates: datesBrutes.map((dateBrute) => this.formatDateNomDuMois(dateBrute)),
      };
    },
    /**
     * input {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     *
     * output {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     */
    listePremiersMois(numero) {
      let datesBrutes = numero.datesBrutes.filter(
        (data, index) => index <= this.parametresVisualisation.premiersMois
      );
      return {
        ...numero,
        data: numero.data.filter(
          (data, index) => index <= this.parametresVisualisation.premiersMois
        ),
        datesBrutes,
        dates: datesBrutes.map((dateBrute) => this.formatDateNomDuMois(dateBrute)),
      };
    },
    /**
     * input {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     *
     * output {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     */
    listeDonneesBornesTemporelles(dataBrut) {
      if (this.periodeVisualisation == PERIODE_VISUALISATION_DEFINIE) {
        return this.listeEntreDatesDepartEtFin(dataBrut);
      } else if (this.periodeVisualisation == PERIODE_VISUALISATION_PREMIERS_MOIS) {
        return this.listePremiersMois(dataBrut);
      } else
        return {
          ...dataBrut,
        };
    },
    /**
     * dataDebut, dataFin : {}
     * - date
     * - data
     *
     * Return : {}
     * - newData
     * - newDates
     */
    interpolation(dataDebut, dataFin) {
      let borneInf = new Date(dataDebut.date);
      let borneSup = new Date(dataFin.date);
      let anneeInf = borneInf.getFullYear();
      let anneeSup = borneSup.getFullYear();
      let moisInf = borneInf.getMonth();
      let moisSup = borneSup.getMonth();

      let difference = moisSup - moisInf + (anneeSup - anneeInf) * 12;
      if (difference <= 1) return false;

      let ventesInf = dataDebut.data;
      let ventesSup = dataFin.data;

      let ventesTotales = ventesSup - ventesInf;
      let ventesSteps = Math.round(ventesTotales / difference);
      let valeursInterpolees = [ventesInf];
      let datesInterpolees = [dataDebut.date];
      let startMonth = moisInf;
      let currentYear = anneeInf;
      for (let indexTmp = 1; indexTmp < difference; indexTmp++) {
        valeursInterpolees.push(valeursInterpolees[valeursInterpolees.length - 1] + ventesSteps);
        let month = startMonth + indexTmp + 1;
        if (month > 12) {
          currentYear++;
          month = month - 12;
        }
        datesInterpolees.push(`${currentYear}-${month.toString().padStart(2, "0")}-01`);
      }
      return {
        newData: valeursInterpolees,
        newDates: datesInterpolees,
      };
    },
    /**
     * Parcourt liste_comptages et interpole si des dates sont manquantes
     * - dataKey : "ventes_du_mois" ou "quantite_comptage"
     * - default dataKey : "vente_du_mois"
     *
     *   Renvoie un objet : {
     * - datesBrutes
     * - data
     * }
     */
    interpolationNumeroSiBesoin(numero, dataKey = "ventes_du_mois") {
      let data = [];
      let datesBrutes = [];
      numero.liste_comptages.map((comptage) => {
        if (!numero.estUnPack) {
          let ventes = parseInt(comptage[dataKey]);
          if (isNaN(ventes)) ventes = 0;
          data.push(ventes);
          datesBrutes.push(comptage.date_comptage);
        }
      });

      return this.interpolationSiBesoin({
        datesBrutes,
        data,
      });
    },
    /**
     * input {...}
     * - data[]
     * - datesBrutes[]
     * - dates[]
     *
     * ouput
     * - data[]
     * - datesBrutes[]
     * - dates[]
     */
    interpolationSiBesoin(graphInfos) {
      // return graphInfos;
      graphInfos = { ...graphInfos };
      for (let dateIndex = 0; dateIndex < graphInfos.datesBrutes.length - 1; dateIndex++) {
        let interpolation = this.interpolation(
          {
            data: graphInfos.data[dateIndex],
            date: graphInfos.datesBrutes[dateIndex],
          },
          {
            data: graphInfos.data[dateIndex + 1],
            date: graphInfos.datesBrutes[dateIndex + 1],
          }
        );
        if (interpolation != false) {
          graphInfos.data.splice(dateIndex, 1, ...interpolation.newData);
          graphInfos.datesBrutes.splice(dateIndex, 1, ...interpolation.newDates);
        }
        // }
      }
      graphInfos.dates = graphInfos.datesBrutes.map((date) => {
        return this.formatDateNomDuMois(date);
      });
      return graphInfos;
    },
    stockActuel(numero) {
      return parseInt(numero.liste_comptages[numero.liste_comptages.length - 1].quantite_comptage);
    },
    ventes(numero) {
      return parseInt(numero.nombre_ventes_numero);
    },
    miseEnFormeSoloOuMultiple() {
      this.resetData();
      this.setGraphType("line");
      if (
        this.parametresVisualisation.listeNumerosAVisualiser == [] ||
        this.parametresVisualisation.listeNumerosAVisualiser == undefined
      ) {
        this.setEmptyGraph("Choisissez un ou plusieurs numéros");
        return;
      }

      this.nombreDeNumerosSimultanes = 0;
      this.listeInfosComplementaires.push({
        type: "titre",
        label: "Total à la fin de la période",
      });

      this.listeAVisualiser.forEach((numero) => {
        this.nombreDeNumerosSimultanes++;

        let graphInfos;
        if (this.typeVisualisation == TYPE_VISUALISATION_STOCK) {
          graphInfos = this.listeDonneesBornesTemporelles({
            ...numero,
            data: numero.liste_comptages.map((comptage) => comptage.quantite_comptage),
            dates: numero.liste_comptages.map((comptage) =>
              this.formatDateNomDuMois(comptage.date_comptage)
            ),
            datesBrutes: numero.liste_comptages.map((comptage) => comptage.date_comptage),
          });
        } else if (this.typeVisualisation == TYPE_VISUALISATION_VENTES) {
          let numeroData = {
            ...numero,
            ...this.convertirComptagesEnVentes(numero.liste_comptages),
          };
          graphInfos = this.listeDonneesBornesTemporelles(numeroData);
        }

        if (
          (this.graphLabels.length != 0 && graphInfos.dates.length < this.graphLabels.length) ||
          this.nombreDeNumerosSimultanes == 1
        ) {
          this.setLabels(graphInfos.dates); // Restriction des dates en fonction de la plus petite serie de date
        }
        // this.listeEnCours = graphInfos
        // this.afficherListeEnCours()
        this.addDataSet(
          createDataSet({
            data: [...graphInfos.data],
            label: numero.numero_numero + " - " + numero.titre_numero,
            graphNumber: this.nombreDeNumerosSimultanes,
          })
        );

        let derniereValeur = graphInfos.data.pop();

        this.listeInfosComplementaires.push({
          label: (numero.numero_numero + " - " + numero.titre_numero).slice(0, 18) + "...",
          data: derniereValeur,
        });
      });

      if (
        this.nombreDeNumerosSimultanes > 1 ||
        this.periodeVisualisation == PERIODE_VISUALISATION_PREMIERS_MOIS
      ) {
        this.setLabels(
          this.graphLabels.map((label, index) => {
            return index == 0 ? "Parution" : "Mois " + index;
          })
        );
      }
      this.rerenderGraph();
    },
    compare(n1, n2) {
      if (!n1.date_parution_numero) return 1;

      if (this.ordreClassement == ORDRE_CHRONOLOGIQUE)
        return new Date(n1.date_parution_numero) <= new Date(n2.date_parution_numero) ? -1 : 1;

      let first;
      let second;
      if (this.ordreClassement == ORDRE_CROISSANT) {
        first = n1;
        second = n2;
      }
      if (this.ordreClassement == ORDRE_DECROISSANT) {
        first = n2;
        second = n1;
      }

      if (first.dataAAfficher[0]) return first.dataAAfficher[0] - second.dataAAfficher[0];
      else return first.dataAAfficher[1] - second.dataAAfficher[1];
    },
    vitesseVentesNumeroMiseForme(numero, periode, periodeMin) {
      // periode = nombre de mois de référence pour le calcul de la vitesse
      // periodeMin = nombre de mois de recul minimum pour inclure le numéros dans l'analyse
      let listeVentes = this.convertirComptagesEnVentes(numero.liste_comptages);
      // return this.corrigerVentesAsync(listeVentes, numero).then(
      //   (listeVentes) => {
      if (listeVentes.data.length < periodeMin)
        return {
          id_numero: numero.id_numero,
          titre_numero: numero.titre_numero,
          numero_numero: numero.numero_numero,
          quantite_stock: this.stockActuel(numero),
          vitesseVentes: "DONT SHOW",
          dataAAfficher: ["DONT SHOW"],
        };
      let borneInf = listeVentes.data.length >= periode ? listeVentes.data.length - periode : 0;
      let ventesTotales =
        listeVentes.data[listeVentes.data.length - 1] - listeVentes.data[borneInf];
      let vitesseVentes =
        listeVentes.data.length - borneInf > 0
          ? ventesTotales / (listeVentes.data.length - borneInf)
          : 0;
      return {
        ...numero,
        quantite_stock: this.stockActuel(numero),
        vitesseVentes,
        dataAAfficher: [vitesseVentes],
        label: [numero.numero_numero + " - " + numero.titre_numero],
      };
      // }
      // );
    },

    classerListeEnCours() {
      this.listeEnCours.sort(this.compare);
    },
    /**
     * dataSet = index du set de dataAAfficher à utiliser pour le filtre
     */
    filtrerListeEnCours(dataSet = 0) {
      if (this.modeVisualisation != MODE_VISUALISATION_CLASSEMENT) {
        return this.listeEnCours;
      }
      return this.listeEnCours.filter((numero) => {
        let filtreQuantite = true;
        let data =
          numero.dataAAfficher[0] === false ? numero.dataAAfficher[1] : numero.dataAAfficher[0];

        if (this.filtreSuperieurOuInferieur != FILTRE_INDIFFERENT) {
          if (this.filtreSuperieurOuInferieur == FILTRE_SUPERIEUR)
            filtreQuantite = data >= this.quantiteFiltreSuperieurOuInferieur;
          if (this.filtreSuperieurOuInferieur == FILTRE_INFERIEUR)
            filtreQuantite = data <= this.quantiteFiltreSuperieurOuInferieur;
          if (this.filtreSuperieurOuInferieur == FILTRE_EGALITE)
            filtreQuantite = data == this.quantiteFiltreSuperieurOuInferieur;
        }
        // afficherNumerosRentables
        let filtreParam = true;
        if (this.typeClassement == TYPE_CLASSEMENT_RENTABILITE)
          filtreParam =
            (data >= 0 && this.afficherNumerosNonRentables) ||
            (data < 0 && this.afficherNumerosRentables);

        if (this.typeClassement == TYPE_CLASSEMENT_RUPTURE_DE_STOCK)
          filtreParam = filtreParam && (!this.masquerValeursNegatives || data >= 0);
        return filtreQuantite && filtreParam;
      });
    },
    remplirInfosComplementaires() {
      switch (this.typeClassement) {
        case TYPE_CLASSEMENT_RUPTURE_DE_STOCK:
          return this.listeInfosComplementairesRuptureStock();
        case TYPE_CLASSEMENT_RENTABILITE:
          return this.listeInfosComplementairesRentabilite();
        case TYPE_CLASSEMENT_VITESSE:
          return this.listeInfosComplementairesVitesse();
      }
    },
    /**
     * Affiche la liste contenue dans this.listeEnCours. Chaque element de la liste doit posséder :
     *  - label
     *  - dataAAfficher
     *  - date_parution_numero (optionnel - pour tri chronologique)
     *  - background (optionnel - couleur de background de l'élément)
     */
    afficherListeEnCours({ label, graphType }) {
      if (label == undefined) label = "Classement";
      if (graphType == undefined) graphType = "bar";
      this.resetData();
      this.classerListeEnCours();
      let listeFiltree = this.filtrerListeEnCours();
      // let listeFiltree = this.listeEnCours;
      if (listeFiltree == undefined || listeFiltree.length == 0) return this.rerenderGraph();

      let nombreDeDatas = listeFiltree[0].dataAAfficher.length;
      for (let dataEnCours = 0; dataEnCours < nombreDeDatas; dataEnCours++) {
        let listeData = listeFiltree
          .filter((data) => {
            return data;
          })
          .map((numero) => {
            return numero.dataAAfficher[dataEnCours];
          });
        let listeTitres = listeFiltree.map((numero) => {
          return numero.label[0];
          // return numero.numero_numero + " - " + numero.titre_numero;
        });
        let listeBackgrounds;
        if (listeFiltree[0].background != undefined)
          listeBackgrounds = listeFiltree.map((numero) => {
            return numero.background;
          });
        this.setGraphType(graphType);
        this.setLabels(listeTitres);
        this.addDataSet(
          createDataSet({
            label: Array.isArray(label) ? label[dataEnCours] : label,
            data: listeData,
            graphNumber: dataEnCours,
            backgroundColor: listeBackgrounds,
            // graphDirection: this.graphDirection,
          })
        );
      }
      this.remplirInfosComplementaires();
      this.rerenderGraph();
    },
    listeInfosComplementairesVitesse() {
      this.listeInfosComplementaires.push({
        label: "Calcul des vitesses",
        data: "Les vitesses de ventes affichées sont calculées sur la base des 6 derniers mois",
      });
    },
    miseEnFormeClassementVitesse() {
      this.listeEnCours = this.listeNumerosFiltree
        .map((numero) => {
          return this.vitesseVentesNumeroMiseForme(
            numero,
            RECUL_CALCUL_VITESSE_VENTES,
            RECUL_MINIMAL_CALCUL_VITESSE_VENTES
          );
        })
        .filter((numero) => numero.dataAAfficher[0] != "DONT SHOW");
    },
    listeInfosComplementairesRuptureStock() {
      this.listeInfosComplementaires.push({
        label: "Avertissement",
        data:
          "Les valeurs en orange concernent les numéros publiés moins de 8 mois avant le dernier comptage - elles sont donc fortement influencées par les ventes des abonnements",
      });
      if (this.valeursNegativesPresentes) {
        this.listeInfosComplementaires.push({
          label: "Valeurs négatives",
          data:
            "Certaines durées avant rupture sont négatives. Ceci est probablement causé par des valeurs de ventes négatives, elles-mêmes probablement dûes a des impressions non déclarées.",
        });
      }

      this.phpPost({
        phpFile: "assets/php/dateDernierComptage.php",
        disableThumbnailForAll: true,
        callBack: (response) => {
          let dateDernierComptage = "Inconnu";
          if (response.reqSuccess) {
            dateDernierComptage = this.dateFrancaise(response.dateDernierComptage);
          }
          this.listeInfosComplementaires.push({
            label: "Date de référence",
            data: dateDernierComptage,
          });
        },
      });
    },
    miseEnFormeClassementRupture() {
      this.valeursNegativesPresentes = false;
      this.listeEnCours = this.listeNumerosFiltree
        .map((numero) => {
          // Temps restant avant rentabilité
          let infosVitesse = this.vitesseVentesNumeroMiseForme(
            numero,
            RECUL_CALCUL_VITESSE_VENTES,
            RECUL_MINIMAL_CALCUL_VITESSE_VENTES
          );
          // ).then((infosVitesse) => {
          let nombreMoisAvantRupture;
          if (infosVitesse.vitesseVentes == "DONT SHOW") nombreMoisAvantRupture = "DONT SHOW";
          else
            nombreMoisAvantRupture = Math.round(
              this.stockActuel(numero) / infosVitesse.vitesseVentes
            );
          if (nombreMoisAvantRupture < 0) {
            this.valeursNegativesPresentes = true;
          }

          let dateParution = new Date(numero.date_parution_numero);

          let dateDernierComptage = new Date(
            numero.liste_comptages[numero.liste_comptages.length - 1].date_comptage
          );

          let nombreMoisDepuisParution =
            (dateDernierComptage - dateParution) / (1000 * 3600 * 24 * 30);

          return {
            ...numero,
            dataAAfficher: [nombreMoisAvantRupture],
            label: [numero.numero_numero + " - " + numero.titre_numero],
            background:
              nombreMoisDepuisParution > RECUL_CALCUL_VITESSE_VENTES ? "#845EC2" : "#ffa95e",
          };
        })
        .filter((numero) => numero.dataAAfficher[0] != "DONT SHOW");
    },
    listeInfosComplementairesRentabilite() {
      let listeNumerosSansImpression = [];
      let listeNumerosDureeAvantRentabilite = this.listeNumerosFiltree.map((numero) => {
        if (numero.listeImpressions[0] == undefined) {
          listeNumerosSansImpression.push(numero.numero_numero + " " + numero.titre_numero);
          return { ...numero, dureeAvantRentabilite: 0 };
        }

        let coutPremiereImpression = parseInt(numero.listeImpressions[0].cout_impression);
        let infosVentes = this.convertirComptagesEnVentes(numero.liste_comptages);
        let beneficeParNumero = 1;
        let dureeAvantRentabilite = 1;

        while (
          infosVentes.data[dureeAvantRentabilite] < coutPremiereImpression * beneficeParNumero &&
          dureeAvantRentabilite < infosVentes.data.length - 1
        ) {
          dureeAvantRentabilite++;
        }
        if (dureeAvantRentabilite == infosVentes.data.length - 1) dureeAvantRentabilite = false;
        return { ...numero, dureeAvantRentabilite };
      });
      listeNumerosDureeAvantRentabilite = listeNumerosDureeAvantRentabilite.filter(
        (numero) => numero.dureeAvantRentabilite != false
      );
      let infosMoyennes = listeNumerosDureeAvantRentabilite.reduce((total, numero) => {
        let annee = numero.date_parution_numero.split("-")[0];
        if (total[annee] == undefined)
          total[annee] = {
            totalAnnee: 0,
            nombreDeNumeros: 0,
          };
        return {
          ...total,
          [annee]: {
            totalAnnee: total[annee].totalAnnee + numero.dureeAvantRentabilite,
            nombreDeNumeros: total[annee].nombreDeNumeros + 1,
          },
        };
      });

      // *************** Création de la liste d'infos
      this.listeInfosComplementaires.push({
        label: "Moyenne avant rentabilité",
        type: "titre",
      });
      for (let annee in infosMoyennes) {
        if (infosMoyennes[annee].nombreDeNumeros >= 8)
          this.listeInfosComplementaires.push({
            label: "Moyenne de " + annee,
            data:
              Math.round(
                (infosMoyennes[annee].totalAnnee / infosMoyennes[annee].nombreDeNumeros) * 100
              ) /
                100 +
              " mois",
          });
      }
    },
    miseEnFormeClassementRentabilite() {
      this.listeEnCours = this.listeNumerosFiltree
        .map((numero) => {
          let infosVitesse = this.vitesseVentesNumeroMiseForme(
            numero,
            RECUL_CALCUL_VITESSE_VENTES,
            RECUL_MINIMAL_CALCUL_VITESSE_VENTES
          );
          let nombreMoisAvantRentabilite;
          if (infosVitesse.vitesseVentes == "DONT SHOW") nombreMoisAvantRentabilite = "DONT SHOW";
          else {
            let nombreExemplairesAVendre =
              numero.listeImpressions.reduce((totalCoutsImpressions, impression) => {
                return totalCoutsImpressions + parseInt(impression.cout_impression);
              }, 0) / 1; // 1 = montant encaissé par l'epicerie pour chaque numéro

            let nombreExemplairesRestantAVendre =
              nombreExemplairesAVendre - parseInt(numero.nombre_ventes_numero);

            nombreMoisAvantRentabilite = 0;

            if (nombreExemplairesRestantAVendre > 0) {
              nombreMoisAvantRentabilite = Math.round(
                nombreExemplairesRestantAVendre / infosVitesse.vitesseVentes
              );
            } else {
              let indexDepassementVentes = -1;
              for (let index in numero.liste_comptages) {
                if (
                  numero.liste_comptages[index].ventes_totales_a_date >= nombreExemplairesAVendre &&
                  indexDepassementVentes == -1
                )
                  indexDepassementVentes = index;
              }
              if (indexDepassementVentes >= 0) {
                let dateDeRentabilite = new Date(
                  numero.liste_comptages[indexDepassementVentes].date_comptage
                );
                nombreMoisAvantRentabilite =
                  (dateDeRentabilite - new Date()) / (1000 * 3600 * 24 * 30);
              } else {
                console.error(
                  "Rentabilité - numéro déjà rentable : Erreur de recherche de date de rentabilité. Cause probable : ventes non corrigés par les impressions"
                );
              }
            }
          }

          let dataAAfficher = false;

          dataAAfficher = [nombreMoisAvantRentabilite];

          return {
            ...numero,
            dataAAfficher,
            background: nombreMoisAvantRentabilite > 0 ? "#ffb39e" : "#adff9e",
            label: [numero.numero_numero + " - " + numero.titre_numero],
          };
          // let dataAAfficher = [false, false];

          // if (nombreMoisAvantRentabilite >= 0) {
          //   dataAAfficher[indexNumeroNonRentable] = nombreMoisAvantRentabilite;
          //   // dataAAfficher[indexNumeroRentable] = undefined;
          // } else {
          //   // dataAAfficher[indexNumeroNonRentable] = undefined;
          //   dataAAfficher[indexNumeroRentable] = nombreMoisAvantRentabilite;
          //   // dataAAfficher[indexNumeroRentable] = -5;
          // }

          // return {
          //   ...numero,
          //   dataAAfficher,
          //   background: nombreMoisAvantRentabilite > 0 ? "#ffb39e" : "#adff9e",
          //   label: [
          //     numero.numero_numero + " - " + numero.titre_numero,
          //     numero.numero_numero + " - " + numero.titre_numero,
          //   ],
          // };
        })
        .filter(
          (numero) =>
            numero.dataAAfficher[0] != "DONT SHOW" || numero.dataAAfficher[1] != "DONT SHOW"
        );
    },
    miseEnFormeClassementStock() {
      this.listeEnCours = this.listeNumerosFiltree
        .map((numero) => {
          return {
            ...numero,
            dataAAfficher: [this.stockActuel(numero)],
            label: [numero.numero_numero + " - " + numero.titre_numero],
          };
        })
        .filter((numero) => numero.dataAAfficher[0] != "DONT SHOW");
    },
    miseEnFormeClassementVentes() {
      this.listeEnCours = this.listeNumerosFiltree
        .map((numero) => {
          return {
            ...numero,
            dataAAfficher: [parseInt(numero.nombre_ventes_numero)],
            label: [numero.numero_numero + " - " + numero.titre_numero],
          };
        })
        .filter((numero) => numero.dataAAfficher[0] != "DONT SHOW");
    },
    miseEnFormeClassement(paramRef) {
      this.resetData();
      this.setGraphType("bar");

      // Si option secondaire seulement, mise en forme simple sans recalcul
      if (this.dontNeedRecompute(paramRef)) {
        this.afficherListeEnCours({ label: this.graphLegend });
        return;
      }

      // this.listeEnCours = this.listeFonctionsClassement[this.typeClassement]();
      this[this.typeClassement]();
      this.afficherListeEnCours({ label: this.graphLegend });

      // ******************** Infos complementaires
      // Temps avant rentabilité

      // this.consoleMessage(
      //   "moyenneDureeRentabiliteParAnnee",
      //   moyenneDureeRentabiliteParAnnee
      // );
      // let moyenneAvantRentabilite =
      //   Math.round((somme / listeNumerosDureeAvantRentabilite.length) * 100) /
      //   100;

      // this.listeInfosComplementaires.push(
      //   createInfosComplementaire({
      //     label: "Durée moyenne avant rentabilité",
      //     data: moyenneAvantRentabilite + " mois",
      //   })
      // );
      // });
    },
    listeEvolutionStockLieu(listeNumeros) {
      return new Promise((resolve, reject) => {
        if (this.lieuAVisualiser == TOUS_LIEUX_CONFONDUS) return resolve(listeNumeros);
        this.phpPost({
          phpFile: "assets/php/evolutionStockLieu.php",
          sendObject: {
            listeIds: listeNumeros.map((numero) => numero.id_numero),
            idLieu: this.lieuAVisualiser,
          },
          disableThumbnail: true,
          callBack: (response) => {
            if (response.reqSuccess) {
              resolve(response.reqList);
            } else reject();
          },
        });
      });
    },
    miseEnFormeStockGlobal() {
      this.phpPost({
        phpFile: "assets/php/evolutionGlobaleStockVentes.php",
        sendObject: {
          afficherPack: this.checkboxAfficherPack,
          afficherHS: this.checkboxAfficherHorsSerie,
        },

        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            let listeEvolutionStock = {};

            Object.keys(response.list).forEach((date) => {
              listeEvolutionStock[this.formatDateNomDuMois(date)] = response.list[date];
            });
            this.resetData();
            this.setGraphType("line");
            this.addDataSet(
              createDataSet({
                label: "Evolution des stocks",
                data: listeEvolutionStock,
              })
            );
            this.rerenderGraph();
          }
        },
      });
    },
    /**
     * Affichage des données globales : évolutions des ventes, des stocks
     */
    miseEnFormeVenteGlobal() {
      let listeNumeros = [...this.listeNumerosFiltree];
      listeNumeros.sort((n1, n2) => {
        return new Date(n1.date_parution_numero) <= new Date(n2.date_parution_numero) ? -1 : 1;
      });

      let dataArray = [];
      function normaliserDate(date) {
        // Mets toute les dates au premier du mois
        let returnDate = date.split("-");
        returnDate.pop();
        // returnDate.push("01");
        return returnDate.join("-");
      }
      let nombreComptageADate = {};
      listeNumeros.forEach((numero, indexNumero) => {
        let dataInterpole = this.interpolationNumeroSiBesoin(numero, "ventes_totales_a_date");
        for (let index in dataInterpole.datesBrutes) {
          let date = normaliserDate(dataInterpole.datesBrutes[index]);
          let ventes = dataInterpole.data[index];
          if (dataArray[date] == undefined) {
            dataArray[date] = ventes;
            nombreComptageADate[date] = 1;
          } else {
            dataArray[date] += ventes;
            nombreComptageADate[date]++;
          }
        }
      });

      let dataArrayFiltered = {};
      Object.keys(dataArray).forEach((date) => {
        if (nombreComptageADate[date] > 5) dataArrayFiltered[date] = dataArray[date];
      });
      this.listeEnCours = Object.keys(dataArrayFiltered).map((date) => {
        return {
          dataAAfficher: [dataArrayFiltered[date]],
          label: [this.formatDateNomDuMois(date)],
        };
      });
      // this.listeInfosComplementaires.push({
      //   label: "Erreur récupération ventes",
      //   data:
      //     "Des erreurs (probablement liées a des erreurs de comptages) empêchent pour le moment une visualisation cohérente des ventes globales",
      // });

      this.afficherListeEnCours({
        label: "Ventes globales",
        graphType: "line",
      });
    },
    miseEnFormeGlobal() {
      if (this.typeVisualisation == TYPE_VISUALISATION_VENTES) this.miseEnFormeVenteGlobal();
      if (this.typeVisualisation == TYPE_VISUALISATION_STOCK) this.miseEnFormeStockGlobal();
    },
    miseEnFormeBeneficeParNumero() {
      this.listeEnCours = this.listeNumerosFiltree
        .filter((numero) => !numero.estUnPack && !numero.estUnHorsSerie)
        .map((numero) => {
          let chiffreAffaire = numero.nombre_ventes_numero * 2;
          let coutTotal = numero.listeImpressions.reduce(
            (total, impression) => total + parseInt(impression.cout_impression),
            0
          );
          let benefices = chiffreAffaire - coutTotal - parseInt(numero.nombre_ventes_numero); // 1 euro de droits d'auteur par numéro vendu
          return {
            ...numero,
            chiffreAffaire,
            coutTotal,
            benefices,
          };
        });
      this.resetData();
      this.setGraphType("bar");
      this.setLabels(
        this.listeEnCours.map((numero) => numero.numero_numero + " " + numero.titre_numero)
      );
      this.addDataSet(
        createDataSet({
          label: "Bénéfices",
          data: this.listeEnCours.map((numero) => numero.benefices),
          graphNumber: 4,
          type: "line",
        })
      );
      this.addDataSet(
        createDataSet({
          label: "Chiffre d'affaire",
          data: this.listeEnCours.map((numero) => numero.chiffreAffaire),
          graphNumber: 0,
        })
      );
      this.addDataSet(
        createDataSet({
          label: "Coûts d'impression",
          data: this.listeEnCours.map((numero) => numero.coutTotal),
          graphNumber: 2,
        })
      );

      // Affichage des infos complémentaires

      let currencyFormater = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });

      let totalBenef = this.listeEnCours.reduce((total, numero) => total + numero.benefices, 0);

      let totalChiffreAffaire = this.listeEnCours.reduce(
        (total, numero) => total + numero.chiffreAffaire,
        0
      );

      let totalImpressions = this.listeEnCours.reduce(
        (total, numero) => total + numero.coutTotal,
        0
      );

      let totalDroitsAuteur = this.listeEnCours.reduce(
        (total, numero) => total + parseInt(numero.nombre_ventes_numero),
        0
      );

      this.listeInfosComplementaires.push(
        createInfosComplementaire({
          label: "Chiffre d'affaire global",
          data: currencyFormater.format(totalChiffreAffaire),
        })
      );

      this.listeInfosComplementaires.push(
        createInfosComplementaire({
          label: "Coût impressions",
          data: currencyFormater.format(totalImpressions),
        })
      );

      this.listeInfosComplementaires.push(
        createInfosComplementaire({
          label: "Total droits auteur",
          data: currencyFormater.format(totalDroitsAuteur),
        })
      );

      this.listeInfosComplementaires.push(
        createInfosComplementaire({
          label: "Total benefices",
          data: currencyFormater.format(totalBenef),
        })
      );

      this.listeInfosComplementaires.push(
        createInfosComplementaire({
          label: "Benefices / numero",
          data: currencyFormater.format(Math.round(totalBenef / this.listeEnCours.length)),
        })
      );

      this.rerenderGraph();
    },
    affichageInfosComplementairesFestival() {
      this.listeInfosComplementaires.push({
        type: "titre",
        label: this.festivalAVisualiser.nom_festival,
      });
      this.listeInfosComplementaires.push({
        data: "Date festival : " + this.dateFrancaise(this.festivalAVisualiser.date_festival),
      });
      this.listeInfosComplementaires.push({
        data: "Ventes totales : " + this.festivalAVisualiser.ventesFestival,
      });
      this.listeInfosComplementaires.push({
        data:
          this.festivalAVisualiser.cloture_festival == 1
            ? `<img src="assets/icones/icone-festival-cloture.png"/> Festival clôturé`
            : `<img src="assets/icones/icone-festival-encours.png"/> Festival en cours`,
      });
    },
    miseEnFormeFestival() {
      this.resetData();
      this.setGraphType("bar");
      this.consoleMessage("******************** MiseEnFormeFestival");
      this.consoleMessage({ festival: this.festivalAVisualiser });
      this.resetInfosComplementaires();

      if (
        this.festivalAVisualiser != AUCUN_FESTIVAL_CHOISI
        // &&
        // this.festivalAVisualiser.cloture_festival == 1
      ) {
        this.affichageInfosComplementairesFestival();
        let graphInfos = {
          labels: [],
          data: [],
        };
        this.consoleMessage("************POST incoming");
        this.phpPost({
          phpFile: "assets/php/detailsFestival.php",
          disableThumbnail: true,
          sendObject: {
            id_festival: this.festivalAVisualiser.id_festival,
          },
          callBack: (response) => {
            let specificLabel = "";

            this.consoleMessage({ response });
            if (this.festivalAVisualiser.cloture_festival == 1) {
              if (response.reqSuccess) {
                let listeVentes = response.details;
                listeVentes.forEach((vente) => {
                  let numero = this.trouveNumeroParId(vente.id_numero);
                  graphInfos.labels.push(numero.numero_numero + " - " + numero.titre_numero),
                    graphInfos.data.push(vente.quantite_vente_festival);
                });
                specificLabel = "Ventes - festival " + this.festivalAVisualiser.nom_festival;
              } else
                specificLabel = "Un problème est survenu lors de la récurpération des données.";
            } else {
              graphInfos = {
                labels: [0],
                data: [0],
              };
              specificLabel =
                "Ventes - festival " +
                this.festivalAVisualiser.nom_festival +
                " / Festival en cours";
            }
            this.addDataSetFromGraphInfos(graphInfos, specificLabel);
            this.rerenderGraph();
          },
        });
      } else {
        this.setEmptyGraph("Choisissez un festival");
      }
    },
    miseEnFormeProjections() {
      this.phpPost({
        phpFile: "assets/php/evolutionGlobaleStockVentes.php",
        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            let listeEvolutionStock = response.list;
            let listeAnnees = Object.keys(listeEvolutionStock)
              .filter((date) => date.split("-")[1] == "01")
              .map((date) => date.split("-")[0]);
            let variationParAnnees = {};
            listeAnnees.forEach((year, index) => {
              if (index < listeAnnees.length - 1) {
                variationParAnnees[listeAnnees[index]] =
                  listeEvolutionStock[listeAnnees[index + 1] + "-01-01"] -
                  listeEvolutionStock[listeAnnees[index] + "-01-01"];
              }
            });

            listeAnnees.pop();
            let derniereAnnee = listeAnnees.pop();
            let moyenneParMoisDerniereAnnee = Math.round(variationParAnnees[derniereAnnee] / 12);

            let listeDatesComptages = Object.keys(listeEvolutionStock);
            let derniereDate = listeDatesComptages.pop();

            let datesProjetees = {
              ["Stock actuel"]: listeEvolutionStock[derniereDate],
            };

            for (let i = 1; i <= this.dureeProjection; i++) {
              datesProjetees[`+ ${i} mois`] =
                listeEvolutionStock[derniereDate] + moyenneParMoisDerniereAnnee * i;
            }

            let totalStockProjete =
              listeEvolutionStock[derniereDate] +
              moyenneParMoisDerniereAnnee * this.dureeProjection;

            this.resetInfosComplementaires();
            this.listeInfosComplementaires.push({
              type: "titre",
              label: "Resultat projection",
            });

            this.listeInfosComplementaires.push({
              label: `Stock à + ${this.dureeProjection} mois`,
              data: new Intl.NumberFormat().format(totalStockProjete),
            });

            this.listeInfosComplementaires.push({
              type: "titre",
              label: "Augmentation des stocks par année",
            });
            for (let annee in variationParAnnees) {
              let operateur = variationParAnnees[annee] >= 0 ? "+ " : "- ";
              this.listeInfosComplementaires.push({
                label: "En " + annee,
                data: operateur + new Intl.NumberFormat().format(variationParAnnees[annee]),
              });
            }

            this.setGraphType("line");
            this.resetData();
            this.addDataSet(
              createDataSet({
                label: `Projection des stocks`,
                data: datesProjetees,
              })
            );
            this.rerenderGraph();
          }
        },
      });
    },
    miseEnFormeFinances() {
      let chiffreAffaireGlobal =
        3 *
        this.listeNumerosFiltree
          .filter((numero) => !numero.estUnPack)
          .map((numero) => parseInt(numero.nombre_ventes_numero))
          .reduce((a, v) => a + v, 0);

      let annee = "2016";
      let debutAnnee = new Date(annee + "-01-01");
      let finAnnee = new Date(annee + "-12-31");
      let listeNumeroAnnee = this.listeNumerosFiltree.filter((numero) => {
        let dateParution = new Date(numero.date_parution_numero);
        return dateParution <= finAnnee && !numero.estUnPack;
      });

      listeNumeroAnnee = listeNumeroAnnee.map((numero) => {
        let infosInterpolees = this.interpolationNumeroSiBesoin(numero);
        let listeVentesNumero = {};
        infosInterpolees.datesBrutes.forEach((moisVente, index) => {
          moisVente = moisVente.split("-");
          moisVente.pop();
          moisVente = moisVente.join("-") + "-01";
          let moisVenteObj = new Date(moisVente);
          if (moisVenteObj >= debutAnnee && moisVenteObj <= finAnnee) {
            listeVentesNumero[moisVente] = infosInterpolees.data[index];
          }
        });
        // if (Object.keys(listeVentesNumero).length == 0) {
        // }
        let newNumero = {
          ...numero,
          listeVentesNumero,
        };
        return newNumero;
      });
      // listeNumero;listeNumeroAnnee
      let listeMoisAnnee = [];
      let ventesParMois = {};
      for (let i = 1; i <= 12; i++) {
        let moisEnCours = annee + "-" + i.toString().padStart(2, "0") + "-01";
        listeMoisAnnee.push(moisEnCours);
        ventesParMois[moisEnCours] = 0;
      }
      listeNumeroAnnee.forEach((numero) => {
        listeMoisAnnee.forEach((moisVente) => {
          if (!isNaN(numero.listeVentesNumero[moisVente]))
            ventesParMois[moisVente] += numero.listeVentesNumero[moisVente] * 2;
        });
      });
      this.resetData();
      this.setLabels(listeNumeroAnnee);
      // this.consoleMessage("listeNumeroAnnee", listeNumeroAnnee);
      // let labels = [...Object.keys(object)];
      // if (dateConversion)
      //   labels = labels.map((label) => this.formatDateNomDuMois(label));
      // this.setLabels(labels);
      this.addDataSet(
        createDataSet({
          label: "Ventes",
          data: ventesParMois,
        })
      );
      this.rerenderGraph();
    },
    updateParams(paramRef, newContent) {
      this.parametresVisualisation[paramRef] = newContent;
      if (paramRef == CHECKBOX_AFFICHER_HORS_SERIE || paramRef == CHECKBOX_AFFICHER_PACK) {
        this.updateListe = true;
      }

      this.setDirection(this.graphTypeDirection);

      this.resetInfosComplementaires();

      this[this.modeVisualisation](paramRef);
    },
    rerenderGraph() {
      let tmpConfig = JSON.parse(JSON.stringify(this.graphConfig));
      // tmpConfig.options = {
      //   ...tmpConfig.options,
      //   scales: {
      //     y: {
      //       type: "linear",
      //       display: true,
      //       position: "left",
      //     },
      //     y1: {
      //       type: "linear",
      //       display: true,
      //       position: "right",

      //       // // grid line settings
      //       // grid: {
      //       //   drawOnChartArea: false, // only want the grid lines for one axis to show up
      //       // },
      //     },
      //   },
      // };

      if (this.graphique != undefined) this.graphique.destroy();
      this.graphique = new window.Chart(document.getElementById("graphique"), {
        ...tmpConfig,
      });
    },
    setLabels(labels) {
      this.graphConfig.data.labels = labels;
    },
    setDirection(direction) {
      this.graphConfig.options.indexAxis = direction;
    },
    setData(data) {
      this.graphConfig.data.datasets[0].data = data;
    },
    setEmptyGraph(label) {
      let graphInfos = {
        labels: [0],
        data: [0],
      };
      this.addDataSetFromGraphInfos(graphInfos, label);
      this.rerenderGraph();
    },
    resetData() {
      this.config = JSON.parse(JSON.stringify(emptyGraph));
      this.graphConfig.data.datasets = [];
      this.setLabels([]);
      this.graphConfig.labels = [];
    },
    setDataPoints(dataPoints) {
      this.graphConfig.data = [...dataPoints];
      // this.graphConfig.data.push(dataPoints);
    },
    addDataSet(dataSet) {
      if (this.graphConfig.data.datasets == undefined) this.graphConfig.data.datasets = [];
      this.graphConfig.data.datasets.push(dataSet);
    },
    addDataSetFromGraphInfos(graphInfos, label) {
      this.setLabels(graphInfos.labels);

      this.addDataSet(
        createDataSet({
          label,
          data: graphInfos.data,
        })
      );
    },
    setGraphType(type) {
      this.graphConfig.type = type;
    },
    resetInfosComplementaires() {
      this.listeInfosComplementaires = [];
    },
    debug() {
      let debut = {
        date: "2019-12-10",
        data: 0,
      };
      let fin = {
        date: "2020-02-10",
        data: 40,
      };
      let test = this.interpolation(debut, fin);
      this.consoleMessage("test : " + test);
    },
  },
  watch: {
    listeNumerosFiltree() {
      this.checkListeNumerosTriee();
    },
  },
  mounted: function() {
    // this.parametresVisualisation = {
    //   ...this.parametresVisualisation,
    //   listeNumerosTriee: this.listeNumerosFiltree,
    // };
    this.resetData();
    this.rerenderGraph();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.frame-generale {
  display: flex;
  // flex-direction: column;
  align-items: left;
  // padding: 0 50px;
  width: 100%;
}

.corps-de-page {
  width: 100%;
  // height: 80vh;
  display: flex;
  justify-content: space-around;
  @media (max-width: $mobile-break-point) {
    flex-direction: column;
  }
}

.btn {
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  @media (max-width: $mobile-break-point) {
    width: 100px;
  }
}

.graph-title {
  text-align: center;
  @include typo-titre;
}

#graphique {
  height: 1000px;
}

.graphique-wrapper {
  width: 70%;
}

.frame-graphique {
  margin-top: 100px;
}

.bouton-selection-numeros-mobile {
  @include boutonMobile(left);
}

.bouton-details-lieux-mobile {
  @include boutonMobile(right);
}

.traitement-en-cours-mobile {
  @include boutonMobile(center);
  z-index: 20;
}
</style>
