<template>
  <div class="composant-festival">
    <!-- <h1>Création d'une nouvelle session festival</h1> -->
    <div class="corps-de-page">
      <ComposantSelectionNumerosFestival
        v-if="afficherSelectionNumerosMobile || !screenIsPhone"
        class="zone-selection"
        :listeNumerosFiltree="listeNumerosFiltree"
        :listeNumeros="listeNumerosTriee()"
        :idLieu="idLieu"
        :changerLieu="changerLieu"
        :resetListeEmmenes="resetListeEmmenes"
        :festivalCloture="festivalCloture"
        :typeUniteAAfficher="typeUniteAAfficher"
        :cacherChoixNumerosMobile="cacherChoixNumerosMobile"
        :listeNumerosEmmenes="listeNumerosEmmenes"
        :retirerNumero="
          (numero) => {
            retirerNumeroDB(
              numero.id_unite_festival != undefined
                ? numero.id_unite_festival
                : { idNumero: numero.id_numero, idUniteFestivalMissing: true }
            );
          }
        "
        :ajouterNumero="ajouterNumeroMultiSelect"
      />
      <div v-if="this.idLieu != INFOS_GENERALES" class="liste-numeros-emmenes">
        <div v-html="titreDetailsFestival" class="titre-zone-liste-numeros-emmenes">
        </div>
        <div class="zone-selection-page">
          <ComposantSelectionPage
            :listePages="listePages"
            v-model="pageAAfficher"
            :definirPageAAfficher="definirPageAAfficher"
            class="composant-selection-page"
          />
          <div class="frame-nav-btn">
            <div
              class="btn btn-primary bouton-gestion-page-festival"
              @click="() => resetListeEmmenes(UNITE_FESTIVAL_DEPART)"
            >
              Départ
            </div>
            <div
              class="btn btn-primary bouton-gestion-page-festival"
              @click="() => resetListeEmmenes(UNITE_FESTIVAL_RETOUR)"
            >
              Retour
            </div>

            <div
              class="btn btn-primary bouton-gestion-page-festival"
              @click="() => definirVerrouillageGlobal(false)"
            >
              Tout ouvrir
            </div>
            <div
              class="btn btn-primary bouton-gestion-page-festival"
              @click="() => definirVerrouillageGlobal(true)"
            >
              Tout fermer
            </div>
            <div
              v-if="!(festival.cloture_festival == 1)"
              class="btn btn-primary bouton-gestion-page-festival"
              @click="definirStockGlobal"
            >
              Définir quantité globale
            </div>
            <div
              class="btn btn-primary bouton-gestion-page-festival"
              @click="() => changerLieu(INFOS_GENERALES)"
            >
              Fiche Festival
            </div>
          </div>
        </div>
        <div
          class="frame-numero-festival"
          v-for="numero of listeNumerosEmmenesAAfficher"
          :key="numero.id_numero"
        >
          <ComposantNumeroFestival
            class="composant-numero-festival"
            :numero="numero"
            :verrouillageNumero="numero.verrouillageNumero"
            :festivalCloture="festivalCloture"
            :update-numero="(nouvelleInfos) => updateNumero(numero.indexGlobal, nouvelleInfos)"
            :updateListeLieuxUtilises="updateListeLieuxUtilises"
            @setVerrouillage="(statut) => setVerrouillage(numero.indexGlobal, statut)"
            @suppression="() => retirerNumeroDB(numero.id_unite_festival)"
          />
        </div>
        <ComposantSelectionPage
          :listePages="listePages"
          v-model="pageAAfficher"
          :definirPageAAfficher="definirPageAAfficher"
          :footer="true"
        />
      </div>
      <div v-else>
        <ComposantFicheFestival
          v-model="festival"
          :handleBoutonCloturerDecloturer="handleBoutonCloturerDecloturer"
          @update="updateInfosFestival"
        />
      </div>
      <ComposantZoneDetailsLieuxFestival
        class="zone-details-lieux"
        v-if="afficherDetailsLieuxMobile || !screenIsPhone"
        :listeLieuxUtilises="listeLieuxUtilises"
        :afficherLieu="afficherLieu"
        :cacherDetailsLieuxMobile="cacherDetailsLieuxMobile"
      />
    </div>
    <div class="bouton-selection-numeros-mobile" @click="afficherSelectionNumeros">
      Numéros
      <!-- <img src="assets/icones/icone-parametres.png" alt="Ajouter" /> -->
    </div>
    <div class="bouton-details-lieux-mobile" @click="afficherDetailsLieux">
      <!-- <img src="assets/icones/icone-festival-lieu.png" alt="Lieux" /> -->
      Résumé
    </div>
    <div class="traitement-en-cours-mobile" v-if="requetesEnCours > 0">
      <Loader size="25" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
 UNITE_FESTIVAL_DEPART,
 UNITE_FESTIVAL_RETOUR,
 UNITE_FESTIVAL_VIDE,
 UNITE_FESTIVAL_PLEIN,
 UNITE_FESTIVAL_PACK
} from "../js/storeConst";

const INFOS_GENERALES = "Aucun lieu choisi";

function creerUniteFestivaleVide(typeUnite, estUnpack) {
  const uniteVide = {
    stockTotal: 0,
    cartons_unite_festival: 0,
    ramettes_unite_festival: 0,
    libres_unite_festival: 0,
    type_unite_festival: typeUnite,
    verrouillage_unite_festival: 0,
    pack_vide_unite_festival: 0,
    pack_plein_unite_festival: 0
    // unitesFestival: ["", ""],
  };
  if (!estUnpack) return uniteVide;
  return {
    ...uniteVide,
    genre_unite_festival: UNITE_FESTIVAL_PACK,
    estUnPack: true
  };
}

export default {
  name: "pageFestival",
  components: {
    ComposantFicheFestival: () => import("./composantFicheFestival"),
    ComposantNumeroFestival: () => import("./composantNumeroFestival"),
    ComposantSelectionPage: () => import("./composantSelectionPage"),
    ComposantZoneDetailsLieuxFestival: () => import("./composantZoneDetailsLieuxFestival"),
    // ComposantSelectionNumerosFestival: () => ({
    //   name: "Composant",
    //   component: import("./composantSelectionNumerosFestival"),
    // }),
  },
  data: () => {
    return {
      festival: {
        nom_festival: "inconnu",
      },
      INFOS_GENERALES,
      pageAAfficher: 1,
      listeNumerosEmmenes: [],
      listeLieuxUtilises: [],
      numeroAAjouter: ["aucun"],
      idLieu: INFOS_GENERALES,
      nomLieu: INFOS_GENERALES,
      idFestival: undefined,
      typeUniteAAfficher: UNITE_FESTIVAL_DEPART,
      afficherSelectionNumerosMobile: false,
      afficherDetailsLieuxMobile: false,
      listeVerrouillage: [],
      timeoutHandler: undefined,
    };
  },
  computed: {
    ...mapGetters(["listeNumerosTriee", "cmpSortFunction", "trouveNumeroParId", "trouveLieuParId"]),
    ...mapState([
      "listeLieux",
      "UNITE_FESTIVAL_DEPART",
      "UNITE_FESTIVAL_RETOUR",
      "requetesEnCours",
      "screenIsPhone",
    ]),
    titreDetailsFestival() {
      return this.idLieu == INFOS_GENERALES
        ? `<h2>Informations générales - Festival "${this.festival.nom_festival}"</h2>`
        : `<h2>Festival ${this.festival.nom_festival}</h2>${this.nomLieu} : Stock au ${this.momentLabel}`;
    },
    listePages() {
      let listePages = ["Page 1"];
      if (this.listeNumerosEmmenes.length > 0) {
        for (let i = 1; i < this.listeNumerosEmmenes.length / 10; i++)
          listePages.push("Page " + (i + 1));
      }
      return listePages;
    },
    listeNumerosEmmenesAAfficher() {
      return this.listeNumerosEmmenes.filter((numero) => {
        return (
          numero.indexGlobal >= (this.pageAAfficher - 1) * 10 &&
          numero.indexGlobal < this.pageAAfficher * 10
        );
      });
    },
    listeNumerosFiltree() {
      // return this.listeNumerosTriee();
      return this.listeNumerosTriee().filter((numero) => {
        let listeIds = this.listeNumerosEmmenes.map((numero) => numero.id_numero);
        // return true;
        return listeIds.indexOf(numero.id_numero) == -1;
      });
    },
    momentLabel() {
      return this.typeUniteAAfficher == UNITE_FESTIVAL_DEPART ? "départ" : "retour";
    },
    festivalCloture() {
      return this.festival.cloture_festival == 1;
    },
    toutEstVerrouille() {
      for (let lieu of this.listeLieuxUtilises) {
        if (
          parseInt(lieu.nombreUnitesDeverrouilleesDepart) > 0 ||
          parseInt(lieu.nombreUnitesDeverrouilleesRetour) > 0
        )
          return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions([
      "phpPost",
      "modaleAlert",
      "modaleComposant",
      "modaleOuiNon",
      "modaleAlert",
      "miseAJourContributeurs",
      "miseAJourNumeros",
    ]),
    ...mapMutations([
      "displayMessage",
      "masquerModale",
      "ajouterRequete",
      "supprimerRequete",
      "setBarProgression",
    ]),
    afficherDetailsLieux() {
      this.afficherDetailsLieuxMobile = true;
    },
    cacherDetailsLieuxMobile() {
      this.afficherDetailsLieuxMobile = false;
      window.scrollTo(0, 0);
    },
    afficherSelectionNumeros() {
      this.afficherSelectionNumerosMobile = true;
    },
    cacherChoixNumerosMobile() {
      this.afficherSelectionNumerosMobile = false;
      window.scrollTo(0, 0);
    },
    updateInfosFestival(festival) {
      let tmpFestival = { ...this.festival };
      this.festival = { ...festival };
      this.phpPost({
        phpFile: "assets/php/modifierFestival.php",
        sendObject: this.festival,
        disableThumbnail: true,
        callBack: (response) => {
          if (!response.reqSuccess) this.festival = { ...tmpFestival };
        },
      });
    },
    modifierStockGlobal(nouveauStock) {
      nouveauStock.verrouillage_unite_festival = 1;
      this.phpPost({
        phpFile: "assets/php/modifierTousUnitesFestival.php",
        disableThumbnail: true,
        sendObject: {
          listeUnites: this.listeNumerosEmmenes
            .filter((numero)=>{
              return !numero.estUnPack
            })
            .map((numero) => {
              let returnObj = {
                id_unite_festival: numero.id_unite_festival,
                cartons_unite_festival: nouveauStock.cartons_unite_festival,
                ramettes_unite_festival: nouveauStock.ramettes_unite_festival,
                libres_unite_festival: nouveauStock.libres_unite_festival,
                pack_plein_unite_festival: nouveauStock.pack_plein_unite_festival ? nouveauStock.pack_plein_unite_festival : 0,
                pack_vide_unite_festival: nouveauStock.pack_vide_unite_festival ? nouveauStock.pack_vide_unite_festival : 0,
                verrouillage_unite_festival: 1,
              }
              return returnObj;
            }),
        },
        callBack: (response) => {
          if (response.reqSuccess) {
            this.masquerModale({ ignoreAllWaitingWindows: true });
            this.resetListeEmmenes();
          } else this.masquerModale();
        },
      });
    },
    definirStockGlobal() {
      this.modaleComposant({
        componentName: "ComposantDefinirNumeroFestival",
        allowQuitOnClick: true,
        props: {
          modifierStockGlobal: this.modifierStockGlobal,
        },
      });
    },
    definirPageAAfficher(newPage) {
      if (newPage > 0 && newPage <= this.listePages.length) this.pageAAfficher = newPage;
    },
    updateNumero(indexGlobal, numeroModifie) {
      this.listeNumerosEmmenes[indexGlobal] = {
        ...this.listeNumerosEmmenes[indexGlobal],
        ...numeroModifie,
      };
    },
    updateListeLieuxUtilises() {
      this.phpPost({
        phpFile: "assets/php/listeLieuxUtilises.php",
        sendObject: { id_festival: this.idFestival },
        disableThumbnail: true,
        dontTrackRequest: true,
        callBack: (response) => {
          if (response.reqSuccess) this.listeLieuxUtilises = response.reqList;
        },
      });
    },
    rebuildIndex() {
      this.listeNumerosEmmenes.map((numero, index) => {
        this.listeNumerosEmmenes[index].indexGlobal = index;
      });
    },
    updateListeEmmenes() {
      // Pas de lieu sélectionné = Affichage des stats globales
      let phpFile = "assets/php/listeNumerosFestivalLieu.php";
      // if (this.idLieu == INFOS_GENERALES) {
      //   // Si un lieu est sélectionné
      //   phpFile = "assets/php/infosGeneralesFestival.php";
      // }
      let listeVerrouillages = this.listeNumerosEmmenes.map((numero) => ({
        id_numero: numero.id_numero,
        verrouillageNumero: numero.verrouillageNumero,
      }));
      let sendObject = {
        id_lieu: this.idLieu,
        id_festival: this.idFestival,
        type_unite_festival: this.typeUniteAAfficher,
        listeVerrouillages,
      };
      this.phpPost({
        phpFile,
        sendObject,
        disableThumbnail: true,
        dontTrackRequest: true, // Evite l'affichage du loader dans le bandeau
        callBack: (response) => {
          if (response.reqSuccess) {
            this.listeNumerosEmmenes = response.reqList;
            this.listeNumerosEmmenes.sort(this.cmpSortFunction);
            let listeVerrouillages = response.listeVerrouillages;
            let indexesToCorrect = [];
            if (listeVerrouillages != undefined) {
              this.listeNumerosEmmenes.forEach((numero, indexNumero) => {
                let matchNum = listeVerrouillages.find(
                  (verrouillage) => verrouillage.id_numero == numero.id_numero
                );
                if (matchNum != undefined)
                  indexesToCorrect.push({
                    index: indexNumero,
                    verrouillage: matchNum.verrouillageNumero == "true",
                  });
              });
              indexesToCorrect.forEach((infos) => {
                this.setVerrouillage(infos.index, infos.verrouillage);
                // this.listeNumerosEmmenes[infos.index].verrouillageNumero =
                //   infos.verrouillage;
              });
            }
            // this.listeEmmenees[index].verrouillage =
            //   matchNum.verrouillageNumero;

            this.rebuildIndex();
            this.updateListeLieuxUtilises();
            // this.$nextTick(() => this.$forceUpdate());
          } else this.listeNumerosEmmenes = [];
        },
      });
    },
    ajouterNumeroMultiSelect(listeNumeros) {
      if (this.idLieu == INFOS_GENERALES) {
        return this.modaleAlert("Pour ajouter des numéros, vous devez choisir un lieu");
      }

      if (listeNumeros.length == 0) return;
      let listeNumerosAAjouter = [
        ...listeNumeros.filter((numeroAChercher) => {
          return this.listeNumerosEmmenes.indexOf(numeroAChercher) == -1;
        }),
      ];
      let listeNumerosARetirer;
      if (listeNumerosAAjouter.length == 0) {
        listeNumerosARetirer = [
          ...this.listeNumerosEmmenes.filter((numeroAChercher) => {
            return listeNumeros.indexOf(numeroAChercher) == -1;
          }),
        ];
      }
      if (
        listeNumerosAAjouter[0] != "aucun" &&
        listeNumerosAAjouter != undefined &&
        listeNumerosAAjouter.length > 0
      ) {
        let listeNumerosMiseEnForme;
        try {
          listeNumerosMiseEnForme = listeNumerosAAjouter.map((numero) => {
              let infosGeneriques = {
              id_numero: numero.id_numero,
              titre_numero: numero.titre_numero,
              numero_numero: numero.numero_numero,
              id_festival: this.idFestival,
              id_lieu: this.idLieu,
              nom_lieu: this.nomLieu,
              type_unite_festival: this.typeUniteAAfficher,
            };

            // if (numero.estUnPack){
            //   let infosUnitesFestival = creerUniteFestivaleVide(this.typeUniteAAfficher, numero.estUnPack)

            //   infosUnitesFestival.unitePackVide = {
            //     ...infosGeneriques,
            //     ...infosUnitesFestival.unitePackVide,
            //   }

            //   infosUnitesFestival.unitePackPlein = {
            //     ...infosGeneriques,
            //     ...infosUnitesFestival.unitePackPlein,
            //   }

            //   console.log('infosUnitesFestival', infosUnitesFestival)
            //   return {
            //     ...infosUnitesFestival,
            //     ...infosGeneriques
            //   }
            // } else {
            return {
              ...infosGeneriques,
              ...creerUniteFestivaleVide(this.typeUniteAAfficher, numero.estUnPack),
            };
            // }
          });
        } catch (err) {
          console.error("erreur de mise en forme de la liste des numéros", err);
          listeNumerosMiseEnForme = ["aucun"];
        }
        if (listeNumerosMiseEnForme != ["aucun"]) {
          this.phpPost({
            phpFile: "assets/php/ajouterUnitesFestival.php",
            sendObject: {
              listeNumeros: listeNumerosMiseEnForme,
            },
            disableThumbnail: true,
            callBack: (response) => {
              this.masquerModale()
              if (response.reqSuccess) {
                response.reqList.forEach((unite, index) => {
                  listeNumerosMiseEnForme[index].id_unite_festival = unite.id_unite_festival;
                });
                this.updateListeLieuxUtilises();
                this.listeNumerosEmmenes = [
                  ...this.listeNumerosEmmenes,
                  ...listeNumerosMiseEnForme,
                ];
                this.listeNumerosEmmenes.sort(this.cmpSortFunction);
                console.log("this.listeNumerosEmmenes", this.listeNumerosEmmenes);
                this.rebuildIndex();
              }
            },
          });
        }
        this.listeNumerosEnCours = ["aucun"];
      }
    },
    retirerNumeroListe(id) {
      this.listeNumerosEmmenes = this.listeNumerosEmmenes.filter((numero) => {
        return numero.id_unite_festival != id;
      });
      this.rebuildIndex();
    },
    retirerNumeroDB(infos) {
      if (infos.idUniteFestivalMissing) {
        let numeroARetirer;
        for (let numero of this.listeNumerosEmmenes) {
          if (numero.id_numero == infos.idNumero) numeroARetirer = { ...numero };
        }

        if (numeroARetirer != undefined) infos = numeroARetirer.id_unite_festival;
        else infos = undefined;
        // numeroARetirer = [...this.listeNumerosEmmenes].filter((numero) => {
        //   return (numero.id_numero = infos.idNumero);
        // });
        // if (numeroARetirer.length != 0) {
        //   numeroARetirer = numeroARetirer[0];
        //   infos = numeroARetirer.id_unite_festival;
        // } else infos = undefined;
      }
      if (infos == undefined)
        return console.warn("Recherche de numéro à retirer: recherche infructueuse");

      this.phpPost({
        phpFile: "assets/php/supprimerUnitesFestival.php",
        sendObject: {
          id_unite_festival: infos,
        },
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.retirerNumeroListe(infos);
            this.updateListeLieuxUtilises();
          }
        },
      });
    },
    changerLieu(idLieu) {
      this.idLieu = idLieu;
      this.nomLieu = idLieu == INFOS_GENERALES ? idLieu : this.trouveLieuParId(idLieu).nom_lieu;
      if (this.idLieu == INFOS_GENERALES && this.screenIsPhone) this.cacherChoixNumerosMobile();
      this.resetListeEmmenes();
    },
    afficherLieu(infos) {
      this.typeUniteAAfficher = infos.typeUniteFestival;
      this.changerLieu(infos.idLieu);
      if (this.screenIsPhone) this.cacherDetailsLieuxMobile();
    },
    resetListeEmmenes(typeUniteAAfficher) {
      this.listeNumerosEmmenes = [];
      if (typeUniteAAfficher != undefined) this.typeUniteAAfficher = typeUniteAAfficher;
      this.pageAAfficher = 1;
      this.$nextTick(() => this.updateListeEmmenes());
    },
    definirVerrouillageGlobal(statut) {
      for (let i = 0; i < this.listeNumerosEmmenes.length; i++)
        this.listeNumerosEmmenes.splice(i, 1, {
          ...this.listeNumerosEmmenes[i],
          verrouillageNumero: statut,
        });
    },
    setVerrouillage(index, statut) {
      this.listeNumerosEmmenes.splice(index, 1, {
        ...this.listeNumerosEmmenes[index],
        verrouillageNumero: statut,
      });
    },
    handleBoutonCloturerDecloturer() {
      if (this.festival.cloture_festival == 1) this.decloturerFestival();
      else this.cloturerFestival();
    },
    decloturerFestival() {
      this.modaleOuiNon({
        message: `Êtes-vous sûr de vouloir déclôturer le festival ? <br>
          Les stocks, ventes et droits engendrés par le fesival seront annulés tant que le festival sera déclôturé. <br><br>
          <h3>⚠️ Attention !</h3> <h4>Les modification effectuées après la déclôture NE POURRONT PAS être annulées.</h4><br>
          La seule option disponible sera de clôturer à nouveau le festival`,
        captionYes: "Déclôturer",
        captionNo: "Ne rien faire",
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/decloturerFestival.php",
            sendObject: {
              id_festival: this.festival.id_festival,
            },
            callBack: (response) => {
              if (response.reqSuccess) {
                this.setPolling();
                this.updateFestival();
                this.miseAJourNumeros();
                this.miseAJourContributeurs();
              }
            },
          });
        },
      });
    },
    cloturerFestival() {
      if (!this.toutEstVerrouille)
        return this.modaleAlert(
          "La clôture n'est possible que si tous les numéros sont verrouillés. Vérifiez dans la liste des lieux si tous les lieux sont bien marqués comme étant terminés."
        );
      this.modaleOuiNon({
        message: "Êtes-vous sûr de vouloir clôturer le festival ?",
        callBackYes: () => {
          clearTimeout(this.timeoutHandler);
          this.timeoutHandler = setInterval(() => {
            this.phpPost({
              phpFile: "assets/php/clotureFestivalAvancement.php",
              disableThumbnailForAll: true,
              callBack: (response) => {
                this.setBarProgression(response.avancementCloture);
              },
            });
          }, 1000);
          this.modaleAlert({
            message: "Clôture en cours...",
            displayProgressBar: true,
          });
          this.setBarProgression(0);
          this.phpPost({
            phpFile: "assets/php/cloturerFestival.php",
            sendObject: {
              id_festival: this.festival.id_festival,
            },
            callBack: (response) => {
              this.masquerModale();
              clearInterval(this.timeoutHandler);
              if (response.reqSuccess) {
                this.updateFestival();
                this.miseAJourNumeros();
                this.miseAJourContributeurs();
              }
            },
          });
        },
      });
    },
    updateFestival() {
      this.phpPost({
        phpFile: "assets/php/infosFestival.php",
        sendObject: {
          id_festival: this.idFestival,
        },
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.festival = response.festival;
            if (!(this.festival.cloture_festival == "1")) this.setPolling();
          } else {
            this.$router.push("/festival");
          }
        },
      });
    },
    timeoutTracker(handler) {
      this.timeoutHandler = handler;
    },
    setPolling() {
      // (function polling(whatToDo, timeoutTracker) {
      //   whatToDo();
      //   timeoutTracker(
      //     setTimeout(() => {
      //       polling(whatToDo, timeoutTracker);
      //     }, 2000)
      //   );
      // })(this.updateListeEmmenes, this.timeoutTracker);
    },
  },
  mounted: function() {
    // this.screenIsPhone = window.innerWidth < 1200;
    // window.addEventListener("resize", () => {
    //   this.screenIsPhone = window.innerWidth < 1200;
    // });
    this.idFestival = this.$route.params.id;
    this.typeUniteAAfficher = this.UNITE_FESTIVAL_DEPART;
    this.updateFestival();
    this.$nextTick(() => this.updateListeLieuxUtilises());
  },
  beforeDestroy: function() {
    clearTimeout(this.timeoutHandler);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-festival {
  display: flex;
  // flex-direction: column;
  align-items: left;
  // padding: 0 50px;
  width: 100%;
}

.corps-de-page {
  width: 100%;
  // height: 80vh;
  display: flex;
  justify-content: space-around;
  @media (max-width: $mobile-break-point) {
    flex-direction: column;
  }
}
.liste-numeros-emmenes {
  @include typo-festival;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  padding: 20px;
  border-radius: 10px;
  // width: 750px;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  max-height: 80vh;

  @media (max-width: $mobile-break-point) {
    width: 100%;
    height: 100%;
    overflow: none;
    max-height: none;
  }
}

.titre-zone-liste-numeros-emmenes {
  text-align: center;
  font-size: $title-font-size;
  // margin-bottom: 10px;
  margin-bottom: $marge-titre;
}

.zone-selection-page {
  display: flex;
  align-items: center;
  user-select: none;
  // width: 100%;
  justify-content: left;
  margin: 20px 20px;
  @media (max-width: $mobile-break-point) {
    // flex-direction: column;
    width: auto;
    margin: 0;
    flex-direction: column;
  }
}

.page-saisie {
}

.frame-nav-btn {
  // border: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    margin-bottom: 10px;
    padding: 4px;
  }
  justify-content: space-between;
}

.composant-selection-page {
  // border: 1px solid #000;
}
.bouton-gestion-page-festival {
  @media (max-width: $mobile-break-point) {
    width: 80px;
    margin-bottom: 10px;
  }

  // width: 300px !important;
}
.btn {
  height: 35px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  @media (max-width: $mobile-break-point) {
    @include typo-small;
    // width: 100px;
  }
}

.frame-numero-festival {
  margin-bottom: 10px;
  @media (max-width: $mobile-break-point) {
    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}

@mixin boutonMobile($position) {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  bottom: 20px;
  border: 3px solid $couleur-texte-fonce;
  position: fixed;

  @if ($position == center) {
    left: calc(50% - 30px);
    border-radius: 50%;

    width: 60px;
    height: 60px;
  } @else {
    border-radius: 15px;

    width: 90px;
    height: 40px;
    #{$position}: 20px;
  }
}

.bouton-selection-numeros-mobile {
  @include boutonMobile(left);
}

.bouton-details-lieux-mobile {
  @include boutonMobile(right);
}

.traitement-en-cours-mobile {
  @include boutonMobile(center);
  z-index: 20;
}
</style>
