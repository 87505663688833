<template>
  <div class="composant-numero" @click="$emit('click')">
    <img
      :src="couvertureNumero"
      alt=""
      class="couverture-numero"
    />
      <div class="separateur"></div>
    <div class="infos-numero">
      <p class="titre-numero">{{ titreNumero }}</p>
      <p class="stock-numero">{{ stockNumero }}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "ComposantNumero",
  props: {
    id_lieu: undefined,
    numero: {
        titre_numero: '...',
        liste_derniers_stockages: []
    },
  },
    computed: {
    stockNumero() {
        return this.numero.liste_derniers_stockages.filter((s)=>(s.id_lieu == this.id_lieu))[0] == undefined ? 
          "stock : inconnu"
          : "stock : " + this.numero.liste_derniers_stockages.filter((s)=>(s.id_lieu == this.id_lieu))[0].quantite_stockage;
    },
    titreNumero() {
        return this.numeroNumero + ' - ' + this.numero.titre_numero
    },
    numeroNumero() {
        return this.numero.numero_numero
    },
    couvertureNumero() {
        return this.numero.couverture_numero
    }
}


};
</script>
<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-numero {
  margin: 3px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 10px;
  padding: 6px;
  box-shadow: 0 5px 5px 0 $opacity-25;
  display: flex;
  align-items: center;
  width: 750px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.couverture-numero {
  margin-right: 17px;
  width: 62px;
  height: 20px;  
  margin-right: 16px;
  object-fit: cover;
}
.separateur {
  width: 1px;
  height: 100%;
  background-color: $couleur-fond-barre-titre;
  margin-right: 15px;
}
.infos-numero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
}
.titre-numero {
  @include typo-small-medium;
  color: rgba(44, 82, 130, 1);
  text-align: center;
  overflow: hidden;
  width: auto;
}
.stock-numero {
  width: 141px;
  @include typo-small-medium;
  color: rgba(44, 82, 130, 1);
}
</style>