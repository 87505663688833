<template>
  <div
    class="composant-infos-contributeur"
    @click="espacePersonnelContributeur"
  >
    <div class="onglets">
      <div
        @click="toggleMenu"
        v-if="screenIsPhone"
        class="menu-item frame-icone-menu"
      >
        <img src="assets/icones/icone-menu.png" alt="Menu" />
      </div>
      <div class="menu-item" @click="afficherFicheAuteur">
        <div class="person-outline">
          <img
            alt=""
            src="https://static.overlay-tech.com/assets/d8838077-890c-418e-8f73-909a59272a84.png"
          />
        </div>
        <p class="user-name">{{ userName }}</p>
      </div>
      <div class="divTextCenter" v-if="connected">
        <div class="btn btn-primary logout-button" @click="logout">
          Deconnexion
        </div>
      </div>
      <!-- <div class="divTextCenter" v-else>
          <div class='btn btn-primary' @click="login">Connexion</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ComposantInfosContributeur",
  model: {
    prop: "userName",
    event: "updateName",
  },
  props: {
    connected: { default: false },
    userName: { default: "Non connecté" },
    userId: { default: undefined },
    toggleMenu: {
      type: Function,
      default: () => {
        console.error(
          "composantInfosContributeur : Aucune fonction d'affichage de menu transmise."
        );
      },
    },
  },
  computed: {
    ...mapState(["screenIsPhone"]),
  },
  methods: {
    ...mapActions([
      "checkConnection",
      "phpPost",
      "modaleComposant",
      "clearData",
    ]),
    afficherFicheAuteur() {
      if (this.connected) this.$router.push("/ficheauteur-" + this.userId);
    },

    logout() {
      this.clearData();
      this.phpPost({
        phpFile: "./assets/php/__logout.php",
        dispatchAtTheEnd: "initialisation",
        callBack: () => {
          this.checkConnection();
        },
      });
    },
    login() {
      this.modaleComposant({
        componentName: "ComposantConnexion",
        allowQuitOnClick: true,
        props: {
          callBack: () => {
            this.checkConnection();
          },
        },
      });
    },
    afficherFicheContributeur() {
      if (this.userId != undefined) {
        var urlFicheAuteur = this.$router.resolve(
          "/ficheauteur-" + this.userId
        );
        window.open(urlFicheAuteur.href, "_blank");
      }
    },
    espacePersonnelContributeur() {
      // this.$router.push('/espacepersonnel')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.composant-infos-contributeur {
  background-color: $couleur-fond-menu-navigation;
  // box-shadow: 0 4px 26px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    padding: 0 10px;
  }
}
.onglets {
  display: flex;
  align-items: center;
  height: 100%;
}
.menu-item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 14px;
  }
  &.fiche-infos {
    cursor: pointer;
  }
}
.person-outline {
  margin-right: 5px;
  padding: 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  align-self: stretch;
}
.user-name {
  @include typo-small-medium;
  @media (max-width: $mobile-break-point) {
    @include typo-medium;
    // text-align: center;
  }
  color: $couleur-texte-fonce;
}
.file-text-outline {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
</style>
