<template>
  <div class="composant-liste-transfert">
    <p class="titre">Liste des transferts</p>
    <div class="separateur1"></div>
      <div v-if="listeTransfert.length != 0">
        <div
          class="transfert-container"
          v-for="(transferts, indexDate) of listeTransfert"
          :key="transferts[0].date_transfert"
        >
          <div 
            
            @click="changeFold(indexDate)"
            class="date-transfert-container element-clickable"
          >
            <p class="date-transfert">{{"Transfert du " + dateFrancaise(transferts[0].date_transfert) }}</p>
            <img
              alt=""
              class="fleche-fold"
              :src="statutFold(indexDate)"
            />
          </div>
          <div v-if="estFolde[indexDate]">
            <div class="details-transfert-container">
              <div v-for="(transfert, indexT) of transferts" :key="transfert.id_transfert">
                <ComposantDetailsTransfert
                  class="composant-details-transfert"
                  :transfert="transfert"
                  @supprimer-transfert="supprimerTransfert(indexDate, indexT)"
                  @update-liste="updateListe"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="transfert-container">
        Aucun transfert
      </div>
    <div class="separateur1"></div>
    <div 
      class="frame-ajouter btn btn-primary" 
      @click="ajouterTransfert"
      v-if='user.adminAccessGranted'
    >
      <p class="bouton-ajouter-transfert">
        Ajouter Transfert
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "ComposantListeTransfert",
  components: { ComposantDetailsTransfert: () => import('./composantDetailsTransfert') },
  data: ()=>{
    return { 
      listeTransfert: [],
      estFolde: []
    }
  },
  props: {
    dateTransfert: {
      type: String,
      default: "Transfert du 27/01/2021"
    }
  },
  computed: {
    ...mapGetters(["dateFrancaise"]),
    ...mapState(["user"])

  },
  methods:{
    ...mapMutations(['definirContenuModale', 'definirVisibiliteModale']),
    ...mapActions(["phpPost", "modaleOuiNon"]),
    supprimerTransfert(indexDate, indexT){
      this.modaleOuiNon({
        message: "Êtes-vous sûr.e.s de vouloir supprimer ce transfert ? (Les stocks des deux lieux seront mis à jour)",
        callBackYes: ()=> {
          this.phpPost({
            phpFile: "assets/php/supprimerTransfert.php",
            sendObject: this.listeTransfert[indexDate][indexT],
            callBack: (response) => {
              if (response.reqSuccess) this.updateListe()
            }
          })
        }
      })
    },
    changeFold(indexDate) {
      this.estFolde.splice(indexDate, 1, !this.estFolde[indexDate])
    },
    statutFold(indexDate){
      return this.estFolde[indexDate]
      ? "assets/icones/unfoldedArrow.png"
      : "assets/icones/foldedArrow.png"
    },
    listeNumerosTransfert(indexT){
      return "test"
    },
    ajouterTransfert() {
      this.definirContenuModale({
        componentName: 'ComposantAjoutTransfert',
        allowQuitOnClick: false
      })
      this.definirVisibiliteModale(true)
    },
    updateListe() {
      this.phpPost({
        phpFile: "assets/php/listeTransfert.php",
        disableThumbnail: true,
        callBack: (response) => { 
          if (response.reqSuccess) {
            this.listeTransfert = response.reqList
            if (this.estFolde.length == 0) this.estFolde = Array(this.listeTransfert.length).fill(false)
          }
        }
      })
    }
  },
  mounted() {
    this.estFolde == []
    this.updateListe()
  }

};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-liste-transfert {
  min-width: 800px;
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 28px;
  padding: 19.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 23px;
  @include typo-large;
}
.separateur1 {
  width: 80%;
  height: 1px;
  background-color: $silver;
  margin-bottom: 23px;
}

.transfert-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  // margin: 0 auto;
  margin-bottom: 10px;
}
.date-transfert-container {
  display: flex;
  justify-content: center;
  // align-items: center;
  // align-self: stretch;
}
.date-transfert {
  color: $couleur-texte-fonce;
  margin-right: 10px;
  @include typo-medium;
}
.fleche-fold {
  width: 23px;
  height: 24px;
}

.details-transfert-container{
  margin-top: 10px;
  border: 1px dashed black;
  border-radius: 10px;
}

.composant-details-transfert {
  margin-left : 23px;
  // align-self: stretch;
}
.frame-ajouter {
  @include btnHover($neutral-btn);
  background-color: $neutral-btn;
  border-radius: 9px;
  // padding: 12px 16px;
  // box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  align-self: stretch;
  width: 200px;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
}
.bouton-ajouter-transfert {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
</style>