
import Vue from 'vue'
export var numeroAConsulter = undefined;

window.listeNumerosEstAJour = false;
window.listeContributeursEstAJour = false;
window.listeLieuxEstAJour = false;

// Récupération de la date du jour

import $ from 'jquery'
import { masquerModale, modaleAlert, modaleAlertListe } from './modale'
export function getToday(){
    var todayD = new Date();

    var month = (todayD.getMonth()+1).toString();
    var day = (todayD.getDate()).toString();
    
    return todayD.getFullYear().toString() + '-' 
                + (month.length<2?'0'+ month : month) + '-'
                + (day.length<2?'0'+ day : day);
}


export function getHour(){
    var todayD = new Date();

    var hours = todayD.getHours().toString();
    var minutes = todayD.getMinutes().toString();
    var seconds = todayD.getSeconds().toString();

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;
    
    return hours + 'h' + minutes + 'm' + seconds +'s';
}


window.today = getToday();

export const imgVerrouillageConst = new Map([
    [true, window.imgCadenas],
    [false, window.imgCadenasOuvert]
])

export const imgStatutConst = new Map([
    [true, window.imgValide],
    [false, window.imgHorloge]
])
    
export const imgSuccessConst = new Map([
    [true, window.imgValide],
    [false, window.imgErreur]
])
    
export const creditDroitsTemplateStr = `
    <div id="creditDroitsInfos" class = "breadcrumb divTextCenter" >
        <h4>Détails droits générés</h4>
    </div>
    <div id='listeCreditsDroitsContainer'>
    </div>
    `;

export var infosNumeroTemplateStr = `
    <div class="dflex space-around">
        <div id = "modaldeInfosContainer">
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2" id="modaleInfosNumeroTitre"></div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2" id="modaleInfosNumeroDate"></div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2" id="modaleInfosNumeroStock"></div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2" id="modaleInfosNumeroVendus"></div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2" id="modaleInfosNumeroImpressions"></div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau2">Contributions :</div>
            <div class = "modaleInfosNumeroLigne modaleInfosNumeroNiveau3" id="modaleInfosNumeroContributions"></div>
        </div>
        <div id="modaleInfosNumeroCouvertureContainer"><img id = "modaleInfosNumeroCouverture" src="" alt="Couverture manquante"></div>
    </div>`;

export function isJSON(data, callBackIfYes, callBackIfNo){
    if (callBackIfYes == undefined) callBackIfYes = ()=>{};
    if (callBackIfNo == undefined) callBackIfNo = ()=>{};
    try {
        var dataJSON = JSON.parse(data);
        callBackIfYes(dataJSON);
        return true;
    } catch {
        callBackIfNo(data)
        return false;
    }
}

export function postAndJSON(url, data, callBack) {
    if (callBack == undefined) callBack = ()=>{};
    modaleAlert('Veuillez patienter...', true);
    $.post(
        url,
        data,
        (returnData) => {
            masquerModale();
            isJSON(returnData, 
                (JSONData)=>{
                    modaleAlertListe(JSONData);
                },
                ()=>{
                    modaleAlert(returnData);
                }
            )
            if (callBack != undefined) callBack(returnData);
        }
    )

}

// Fonction qui vérifie que l'objet n'a pas d'element vides parmis les clés testées
// Renvoie true si toutes les clés sont remplies, false si au moins une clé est vide
export function verifierClesVideObjet(obj, tableauCles) {
    if (tableauCles === undefined) tableauCles = Object.keys(obj);
    for (const [key, value] of Object.entries(obj)) {
        if (tableauCles.indexOf(key)!=-1 && (value === '' || value === undefined || value === null)) return false;
    }
    return true;
}

// Fonction qui vérifie qu'une date est bien au format jj/dd/aaaa ou aaaa-mm-jj
export function testDate(dateStr) {
    return dateStr.split('-').reverse().join('/').match(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/) != null;
}

export function dateFrancaise(date) {
    return date.split('-').reverse().join('/');
}

export function formatDateNomDuMois(dateStr) {
    const listeMois = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ];

    var errMessage = 'format de date incorrect';
    if (dateStr != undefined){
        var splitDate = undefined;
        if (dateStr.indexOf('/')!=-1){
            splitDate = dateStr.split('/');
            splitDate.reverse();
            if (splitDate.length == 3) splitDate.pop();
            splitDate.reverse();
            }
        else if (dateStr.indexOf('-')!=-1) {
            splitDate = dateStr.split('-');
            if (splitDate.length == 3) splitDate.pop();
            splitDate.reverse();
        } else return errMessage;
        
        
        var month = parseInt(splitDate[0]);
        var year = parseInt(splitDate[1]);

        if (!(month>=1 && month<=12)) return errMessage;

        splitDate[0] = listeMois[parseInt(splitDate[0])-1];
        return splitDate.join(' ');
    }     else return errMessage
}

