<template>
  <!-- Liste demandes de versement -->
  <div v-if="pageAAfficher == VISUALISATION_LISTE" class="composant-liste-demandes-versement">
    <p class="titre">Liste des demandes versement</p>
    <div class="separateur1"></div>
    <div class="frame-titres-liste-versements">
      <p class="label label-date">Date</p>
      <p class="label label-montant">Montant</p>
      <p v-if="user.adminAccessGranted && !screenIsPhone" class="label label-numero-facture">
        N° facture
      </p>
      <p class="label label-facture" v-if="!screenIsPhone">Pdf</p>
      <p class="label label-statut">Payé</p>
      <div class="frame-remplissage"></div>
    </div>
    <div class="separateur1"></div>
    <div v-if="listeDemandeVersement.length != 0">
      <ComposantDemandeVersement
        class="composant-demande-versement"
        v-for="demandeVersement of listeDemandeVersement"
        :key="demandeVersement.id_demande_versement"
        :demandeVersement="demandeVersement"
        @update-list="updateList"
      />
      <!-- @declaration-versement="choixDateVersement"
        @annulation-versement="annulerVersement" -->
    </div>
    <div v-else class="label aucune-demande">Aucune demande</div>
    <div class="separateur1"></div>
  </div>

  <!-- Saisie date déclaration versement -->
  <div v-else class="composant-liste-demandes-versement">
    <div class="titre">Declarer un versement</div>
    <div class="separateur1"></div>
    <div>
      <div class="frame-saisie-date-versement">
        <div class="label">Date de versement :</div>
        <input class="label" label="Date de versement" type="date" v-model="dateVersement" />
      </div>
      <div class="frame-boutons">
        <div class="frame-ajouter btn btn-primary" @click="declarerVersement(demandeVersementTemp)">
          Continuer
        </div>
        <div class="frame-annuler btn btn-danger" @click="pageAAfficher = VISUALISATION_LISTE">
          Annuler
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const VISUALISATION_LISTE = "VISUALISATION_LISTE";
const SAISIE_DATE_VERSEMENT = "SAISIE_DATE_VERSEMENT";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "ComposantListeDemandesVersement",
  props: {
    idContributeur: { default: "-" },
    idCompteDroits: { default: undefined },
  },
  data: () => {
    return {
      VISUALISATION_LISTE: VISUALISATION_LISTE,
      SAISIE_DATE_VERSEMENT: SAISIE_DATE_VERSEMENT,
      listeDemandeVersement: [],
      pageAAfficher: VISUALISATION_LISTE,
      demandeVersementTemp: undefined,
      dateVersement: "",
    };
  },
  computed: {
    ...mapGetters(["getToday"]),
    ...mapState(["user", "screenIsPhone"]),
  },
  methods: {
    ...mapActions(["phpPost", "modaleOuiNon"]),
    ...mapMutations(["masquerModale"]),
    updateList() {
      this.phpPost({
        phpFile: "assets/php/listeDemandeVersement.php",
        sendObject: { idContributeur: this.idContributeur },
        disableThumbnail: true,
        callBack: (response) => {
          this.listeDemandeVersement = [];
          this.$nextTick(() => {
            if (response.reqSuccess) {
              this.listeDemandeVersement = response.reqList;
              for (let i in this.listeDemandeVersement)
                this.listeDemandeVersement[i].idCompteDroits = this.idCompteDroits;
            }
          });
        },
      });
    },
    // declarerVersement(demandeVersement) {
    //   this.modaleOuiNon({
    //     message:
    //       "Valider cette demande ajoutera un versement du montant indiqué au contributeur et ses droits seront mis à jour.<br>Êtes vous sûr.e de vouloir continuer ?",
    //     captionYes: "Valider",
    //     captionNo: "Annuler",
    //     // keepOpen: true,
    //     callBackYes: () => {
    //       this.phpPost({
    //         phpFile: "assets/php/ajouterReglementDemandeVersement.php",
    //         sendObject: {
    //           dateVersement: this.dateVersement,
    //           idCompteDroits: demandeVersement.idCompteDroits,
    //           ...demandeVersement,
    //         },
    //         dispatchAtTheEnd: "miseAJourContributeurs",
    //         callBack: (response) => {
    //           demandeVersement.versementRegle = response.reqSuccess;
    //           this.pageAAfficher = VISUALISATION_LISTE;
    //           this.updateList();
    //         },
    //       });
    //     },
    //   });
    // },
    // annulerVersement(demandeVersement) {
    //   this.modaleOuiNon({
    //     message:
    //       "Vous êtes sur le point de supprimer le versement associé à cette demande. Cette action mettra les droits d'auteur à jour dans la fiche auteur<br>Êtes-vous sûr.e de vouloir continuer ?",
    //     captionYes: "Continuer",
    //     captionNo: "Annuler",
    //     // keepOpen: true,
    //     callBackYes: () => {
    //       this.phpPost({
    //         phpFile: "assets/php/supprimerReglementDemandeVersement.php",
    //         sendObject: {
    //           id_demande_versement: demandeVersement.id_demande_versement,
    //           id_compte_droits: demandeVersement.idCompteDroits,
    //         },
    //         dispatchAtTheEnd: "miseAJourContributeurs",
    //         callBack: (response) => {
    //           demandeVersement.versementRegle = response.statutReglement;
    //           this.updateList();
    //         },
    //       });
    //     },
    //     // callBackNo: () =>{
    //     //   this.pageAAfficher = VISUALISATION_LISTE
    //     // }
    //   });
    // },
    choixDateVersement(demandeVersement) {
      this.pageAAfficher = SAISIE_DATE_VERSEMENT;
      this.demandeVersementTemp = demandeVersement;
    },
  },
  created: function() {
    if (this.idContributeur == "-") this.listeDemandeVersement = [];
    else this.updateList();
    this.dateVersement = this.getToday;
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
@import "src/assets/css/listeVersements.scss";

.composant-liste-demandes-versement {
  // background-color: $fond-page;
  border-radius: 28px;
  padding: 19.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.frame-titres-liste-versements {
  margin-bottom: 10px;
  // padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label {
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-medium;
}

.label-date {
  width: $largeur-date;
}
.label-montant {
  width: $largeur-montant;
}
.label-numero-facture {
  width: $largeur-numero-facture;
}

.label-facture {
  width: $largeur-icone-facture;
}
.label-statut {
  width: $largeur-statut-paiement;
}

.frame-remplissage {
  width: $largeur-icone-supprimer;
}

.separateur1 {
  height: 1px;
  background-color: $silver;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.composant-demande-versement {
  margin-bottom: 10px;
  align-self: stretch;
}

.frame-saisie-date-versement {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  & div {
    margin-right: 10px;
  }
}

.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-large;
  margin-bottom: 20px;
}
.aucune-demande {
  @include typo-large;
  margin-top: 20px;
  margin-bottom: 30px;
}

.frame-boutons {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 37px;
}
.frame-ajouter {
  // background-color: $neutral-btn;
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  align-self: stretch;
}
.bouton-ajouter-button {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.frame-annuler {
  // background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  align-self: stretch;
}
</style>
