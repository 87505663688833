<template>
  <!-- :style="'left:-190px;'" -->
  <div class="frame-menu" :class="computedClass" :style="menuStyle">
    <!-- v-if="!this.screenIsPhone || this.menuVisible" -->
    <div class="menu-navigation">
      <div class="onglets">
        <div
          :class="menuItemClass('/listenumeros')"
          class="menu-item element-clickable"
          title="Liste des numéros"
          @click="selectItem('/listenumeros')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-liste-numeros.png" />
          </div>
          <p class="label-menu-item">Liste des numéros</p>
        </div>
        <div
          :class="menuItemClass('/edition/consulter-1')"
          class="menu-item element-clickable"
          title="Fiches numéro"
          @click="selectItem('/edition/consulter-1')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-fiches-numero.png" />
          </div>
          <p class="label-menu-item">Fiches numéro</p>
        </div>
        <div
          :class="menuItemClass('/ficheauteur--')"
          class="menu-item element-clickable"
          title="Fiches auteur"
          @click="selectItem('/ficheauteur--')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-fiches-auteur.png" />
          </div>
          <p class="label-menu-item">Fiches auteur</p>
        </div>
        <div
          class="menu-item element-clickable"
          :class="menuItemClass('/edition/ajout')"
          title="Ajouter numéro"
          v-if="user.adminAccessGranted && !screenIsPhone"
          @click="selectItem('/edition/ajout')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-ajouter-numero.png" />
          </div>
          <p class="label-menu-item">Ajouter numéro</p>
        </div>
        <div
          class="menu-item element-clickable"
          :class="menuItemClass('/ajoutcomptage--')"
          title="Ajouter comptage"
          v-if="user.adminAccessGranted && !screenIsPhone"
          @click="selectItem('/ajoutcomptage--')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-ajouter-comptage.png" />
          </div>
          <p class="label-menu-item">Ajouter comptage</p>
        </div>
        <div
          class="menu-item element-clickable"
          :class="menuItemClass('/modifcomptage--/-')"
          title="Modifier comptage"
          v-if="user.adminAccessGranted && !screenIsPhone"
          @click="selectItem('/modifcomptage--/-')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-modifier-comptage.png" />
          </div>
          <p class="label-menu-item">Modifier comptage</p>
        </div>
        <div
          :class="menuItemClass('/sessioncomptage')"
          class="menu-item element-clickable"
          title="Inventaire"
          @click="selectItem('/sessioncomptage')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-inventaire.png" />
          </div>
          <p class="label-menu-item">Inventaire</p>
        </div>
        <div
          :class="menuItemClass('/gestionLieux')"
          class="menu-item element-clickable"
          title="Gestion des lieux"
          @click="selectItem('/gestionLieux')"
          v-if="!screenIsPhone"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-gestion-lieux.png" />
          </div>
          <p class="label-menu-item">Gestion des lieux</p>
        </div>
        <!-- <div
          :class="menuItemClass('/festival')"
          class="menu-item element-clickable"
          title="Gestion des festivals"
          @click="selectItem('/festival')"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-festival.png" />
          </div>
          <p class="label-menu-item">Gestion des festivals</p>
        </div> -->
        <div
          :class="menuItemClass('/statistiques')"
          class="menu-item element-clickable"
          title="Statistiques"
          @click="selectItem('/statistiques')"
          v-if="!screenIsPhone"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-statistiques.png" />
          </div>
          <p class="label-menu-item">Statistiques</p>
        </div>
        <div
          :class="menuItemClass('/espacepersonnel')"
          class="menu-item element-clickable"
          title="Espace personnel"
          @click="selectItem('/espacepersonnel')"
          v-if="!screenIsPhone"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-espace-perso.png" />
          </div>
          <p class="label-menu-item">Espace personnel</p>
        </div>
        <div
          :class="menuItemClass('/admin')"
          class="menu-item element-clickable"
          title="Administration"
          @click="selectItem('/admin')"
          v-if="user.adminAccessGranted && !screenIsPhone"
        >
          <div class="frame-icone-menu-menu-item">
            <img alt="" src="assets/icones/icone-menu-administration.png" />
          </div>
          <p class="label-menu-item">Administration</p>
        </div>
        <div
          v-if="!screenIsPhone"
          class="menu-item element-clickable"
          title=""
          @click="toggleMenu"
        >
          <div class="frame-icone-menu-menu-item">
            <img
              v-if="!screenIsPhone"
              alt=""
              class="img-toggle-menu element-clickable"
              :src="
                menuVisible
                  ? 'assets/icones/icone-fermer-menu.png'
                  : 'assets/icones/icone-ouvrir-menu.png'
              "
              title="Fermer menu"
            />
          </div>
          <p class="label-menu-item">Fermer menu</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MenuNavigation",
  props: {
    menuVisible: { default: false },
    toggleMenu: {
      type: Function,
      default: () => {
        console.error(
          "Composant menuNavigation: fonction de toggle menu manquante"
        );
      },
    },
  },
  data: () => {
    return {
      currentMenuItem: "/listenumeros",
    };
  },
  computed: {
    ...mapState(["user", "screenIsPhone"]),
    afficherMenu() {
      return !this.screenIsPhone || this.menuVisible;
    },
    comportementMenu() {
      return this.menuVisible || this.screenIsPhone ? "fixe" : "mouvant";
    },
    computedClass() {
      if (this.screenIsPhone) {
        return this.menuVisible ? "displayed" : "hidden-mobile";
      } else {
        return this.menuVisible ? "displayed" : "hidden";
      }
    },
    menuStyle() {
      let posStyle = "";
      let leftPos;
      // if (this.screenIsPhone) {
      //   leftPos = this.menuVisible ? "0" : "-200%";
      // } else {
      //   leftPos = this.menuVisible ? "0" : "-190px";
      // }
      // posStyle = "left: " + leftPos + ";";

      let padding = this.screenIsPhone ? "" : "padding-left: 50px;";
      let paddingStyle = this.menuVisible ? padding : "";

      return posStyle + paddingStyle;
    },
  },
  methods: {
    normalizePath(path) {
      return path.split("-")[0];
    },
    selectItem(menuItem) {
      this.currentMenuItem = this.normalizePath(menuItem);
      this.$emit("click", menuItem);
    },
    inverserFixationMenu() {
      this.menuVisible = !this.menuVisible;
    },
    menuItemClass(menuItem) {
      if (this.normalizePath(menuItem) == this.currentMenuItem)
        return "itemSelected";
      else return "";
    },
  },
  mounted() {
    this.currentMenuItem = this.normalizePath(this.$route.path);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.fixe {
  position: fixed;
  z-index: 30;
}

.displayed {
  display: block;
  left: 0;
}

.hidden {
  left: -190px;
}

.hidden-mobile {
  display: none;
  // left: -200%;
}

.itemSelected {
  background-color: rgba(0, 0, 0, 0.2);
}

.frame-menu {
  position: fixed;
  // transition: left 0.3s;
  transition-property: all;
  transition-duration: 300ms;
  z-index: 1;
  @media (max-width: $mobile-break-point) {
    left: 0;

    // right: 0;
  }
  // left: -190px;
  // &:hover {
  //   padding-left: 50px;
  //   margin-left: 0px;
  //   left: 0px;
  // }
}

.menu-navigation {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 21px;
  padding: 9px 8px 8px 9px;
  box-shadow: 0 4px 26px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: start;
  max-height: 80vh;
  overflow: auto;
  direction: rtl;
  @media (max-width: $mobile-break-point) {
    align-items: flex-start;
    direction: ltr;
    position: fixed;
    top: $ruban-height;
    width: 100vw;
    // left: 0;
    bottom: -30px;
    max-height: 100vh;
    height: 100vh;
    padding-top: 50px;

    // right: 0;
  }
}
.onglets {
  display: flex;
  // position: absolute;
  // top: 0;
  flex-direction: column;
  justify-content: start;
  @media (max-width: $mobile-break-point) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  // // justify-content: flex-start;
  align-items: flex-start;

  max-height: inherit;
}
.menu-item {
  margin-bottom: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 8%;
  @media (max-width: $mobile-break-point) {
    margin-bottom: 20px;
    height: 35px;
    width: 50%;
  }
}
.list-outline {
  margin-right: 5px;
  padding: 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
}
.label-menu-item {
  width: 182px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.file-text-outline {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.file-add-outline {
  margin-right: 5px;
  padding: 1px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
}
.folder-add-outline {
  margin-right: 5px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
}
.frame-icone-menu-menu-item {
  margin-right: 5px;
  padding: 1px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  & img {
    width: 34px;
  }
}
.push-pin {
  border: 1px solid black;
}

.icone-ouvrir-menu {
  display: flex;
  justify-content: end;
}

.img-toggle-menu {
  width: 34px;
}
.icones-affichage-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 100%;
}
</style>
