<template>
  <div>
    <div id="ajoutNumeroCorpsDePage" class="corpsDePage">
      <div v-if="user.adminAccessGranted && !screenIsPhone">
        <section v-if="unlocked" id="detailsNumero" class="breadcrumb">
          <h4>Details Numéro</h4>
          <div class="detailsNumero">
            <div id="infosTexte">
              <div class="ligneFormulaire">
                <div class="labelFormulaire">Titre de l'exemplaire</div>
                <input
                  type="text"
                  name="titre_numeroAjout"
                  id="titre_numeroAjout"
                  v-model="exemplaireAEditer.titre_numero"
                />
              </div>
              <div class="ligneFormulaire">
                <div class="labelFormulaire">Exemplaire N°</div>
                <input
                  type="texte"
                  name="numero_numeroAjout"
                  id="numero_numeroAjout"
                  v-model="exemplaireAEditer.numero_numero"
                  maxlength="4"
                />
              </div>
              <div class="ligneFormulaire">
                <div class="labelFormulaire">Date de parution</div>
                <input
                  type="text"
                  name="dateParutionAjout"
                  id="dateParutionAjout"
                  v-model="exemplaireAEditer.date_parution_numero"
                />
                <div>(mm/aaaa ou aaaa-mm)</div>
              </div>
              <div class="ligneFormulaire">
                <div class="labelFormulaire">Couverture</div>
                <input type="hidden" name="MAX_FILE_SIZE" value="10" />
                <input
                  ref="fichierCouverture"
                  type="file"
                  name="couvertureExemplaireAjout"
                  id="couvertureExemplaireAjout"
                  v-on:change="changementImgCouv()"
                />
              </div>
              <div v-if="type_edition != 'ajout'">
                <div class="ligneFormulaire">
                  <div class="labelFormulaire">Nombre d'impressions</div>
                  <div>{{ exemplaireAEditer.nombre_impressions_numero }}</div>
                  <div
                    v-if="type_edition != 'ajout'"
                    class="frame-liste-impressions element-clickable"
                    @click="afficherListeImpressions"
                  >
                    (
                    <img
                      class="icone-liste-impressions"
                      src="assets/icones/icone-menu-liste-numeros.png"
                      alt="Liste impressions"
                    />
                    Liste des impressions)
                  </div>
                </div>
                <div>
                  <ComposantCheckbox
                    class="ligneFormulaire"
                    label="Ce numéro est un pack thématique (coffret ou autre)"
                    v-model="exemplaireAEditer.estUnPack"
                  />
                  <div v-if="exemplaireAEditer.estUnPack">
                    <select
                      class="ligneFormulaire option-ajout-numero liste-choix-numero-pack"
                      name="numeroAAjouterAuPack"
                      v-model="idNumeroAAjouterAuPack"
                      @change="ajouterNumeroAuPack"
                    >
                      <option value="0">Choisir numéro à ajouter</option>
                      <option
                        v-for="numero of listeNumeros"
                        :key="numero.id_numero"
                        :value="numero.id_numero"
                        >{{
                          numero.numero_numero + " " + numero.titre_numero
                        }}</option
                      >
                    </select>
                    <div
                      v-for="(numero,
                      index) of exemplaireAEditer.liste_numeros_pack"
                      :key="index"
                    >
                      <div class="liste-numero-pack-container">
                        <div
                          class="retirer-numero-pack"
                          @click="retirerDuPack(index)"
                        >
                          ❌
                        </div>
                        <div class="numero-a-ajouter-au-pack">
                          {{ numero.numero_numero + " " + numero.titre_numero }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <!-- Options d'ajout - première impression et déclaration de pack -->
                <ComposantCheckbox
                  class="ligneFormulaire"
                  label="Declarer la première impression"
                  v-model="exemplaireAEditer.declarerPremiereImpression"
                />
                <div v-if="exemplaireAEditer.declarerPremiereImpression">
                  <div class="ligneFormulaire option-ajout-numero">
                    <div class="labelFormulaire">Quantité</div>
                    <input
                      type="text"
                      name="dateParutionAjout"
                      class="inputSaisiePremiereImpression"
                      v-model="exemplaireAEditer.quantitePremiereImpression"
                    />
                  </div>
                  <div class="ligneFormulaire option-ajout-numero">
                    <div class="labelFormulaire">Coût</div>
                    <input
                      type="text"
                      name="dateParutionAjout"
                      class="inputSaisiePremiereImpression"
                      v-model="exemplaireAEditer.coutPremiereImpression"
                    />
                    <div>€</div>
                  </div>
                </div>
                <ComposantCheckbox
                  class="ligneFormulaire"
                  label="Ce numéro est un pack thématique (coffret ou autre)"
                  v-model="exemplaireAEditer.estUnPack"
                />
                <div v-if="exemplaireAEditer.estUnPack">
                  <div class="dflex">
                    <select
                      class="ligneFormulaire option-ajout-numero liste-choix-numero-pack"
                      name="numeroAAjouterAuPack"
                      v-model="idNumeroAAjouterAuPack"
                      @change="ajouterNumeroAuPack"
                    >
                      <option value="0">Choisir numéro à ajouter</option>
                      <option
                        v-for="numero of listeNumeros"
                        :key="numero.id_numero"
                        :value="numero.id_numero"
                        >{{
                          numero.numero_numero + " " + numero.titre_numero
                        }}</option
                      >
                    </select>
                    <!-- <div class="btn btn-small btn-primary" @click="ajouterNumeroAuPack">Ajouter au pack</div> -->
                  </div>
                  <div
                    v-for="(numero,
                    index) of exemplaireAEditer.liste_numeros_pack"
                    :key="index"
                  >
                    <div class="liste-numero-pack-container">
                      <div
                        class="retirer-numero-pack"
                        @click="retirerDuPack(index)"
                      >
                        ❌
                      </div>
                      <div class="numero-a-ajouter-au-pack">
                        {{ numero.numero_numero + " " + numero.titre_numero }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="imgCouvDivModif">
              <img
                class="imgCouvertureModif"
                :src="exemplaireAEditer.couverture_numero"
                alt="couverture"
              />
            </div>
          </div>
        </section>
        <section v-if="unlocked" id="detailsContributions" class="breadcrumb">
          <!--******************************** Section contributions *****************************-->
          <h4>Contributions</h4>
          <div id="listeContributionsContainer">
            <div
              v-if="exemplaireAEditer.liste_contributions.length == 0"
              class="ligneFormulaire"
              id="listeContributions"
            >
              Aucune contribution pour ce numéro
            </div>
            <div v-else>
              <div
                v-for="(contribution,
                i) in exemplaireAEditer.liste_contributions"
                :key="contribution.id_contribution"
                class="listeContributions"
              >
                <AffichageContribution
                  :locked="false"
                  v-model="exemplaireAEditer.liste_contributions[i]"
                  :numero_contribution="i"
                  v-on:supprimer-contribution="effacerContribution"
                />
                <!-- <AffichageContribution :identified = 'true' :contribution="contribution" :numero_contribution="i" v-on:supprimer-contribution = "effacerContribution" v-on:mise-a-jour-contribution = "miseAJourContribution"></AffichageContribution> -->
              </div>
              <div class="consignes">
                (Clic pour modifier - ❌ pour supprimer)
              </div>
            </div>
          </div>
          <div id="ajoutContribution">
            <!--****************************** Ajout Contribution ***********************************-->
            <h5>Ajouter Contribution</h5>
            <div id="ajoutContributeur" class="sousSection">
              <h6>Contributeur</h6>
              <div id="optionsAjoutContributeur">
                <!-- ********************* Contributeur existant ************************* -->
                <div>
                  <input
                    v-model="modeAjoutContributeur"
                    type="radio"
                    name="modeAjoutContributeur"
                    id="ajoutContributeurExistant"
                    value="existant"
                    checked
                    v-on:change="visibiliteOptionNouveauContributeur = false"
                  />
                  <label for="ajoutContributeurExistant"
                    >Contributeur existant</label
                  >
                  <select
                    id="listeContributeurs"
                    name="listeContributeurs"
                    v-model="idContributeurSelectionne"
                  >
                    <option value="-">-</option>
                    <option
                      v-for="contributeur in listeContributeurs"
                      :key="contributeur.id_contributeur"
                      :value="contributeur.id_contributeur"
                      >{{ contributeur.nom_contributeur }}
                      {{ contributeur.prenom_contributeur }}</option
                    >
                  </select>
                </div>
                <!-- ********************* Nouveau contributeur ************************* -->
                <div>
                  <input
                    v-model="modeAjoutContributeur"
                    type="radio"
                    name="modeAjoutContributeur"
                    id="ajoutNouveauContributeur"
                    value="nouveau"
                    v-on:change="visibiliteOptionNouveauContributeur = true"
                  />
                  <label for="ajoutNouveauContributeur"
                    >Nouveau Contributeur</label
                  >
                  <div
                    id="detailsNouveauContributeur"
                    v-if="visibiliteOptionNouveauContributeur"
                  >
                    <div class="nom-prenom">
                      <div>
                        <div class="ligneFormulaire">
                          <div class="labelFormulaire">Nom*</div>
                          <input
                            @keyup="checkNomPrenom"
                            @blur="checkNomPrenom"
                            v-model="contribution.nom_contributeur"
                            type="text"
                            name="nomContributeur"
                            id="nomContributeur"
                          />
                        </div>
                        <div class="ligneFormulaire">
                          <div class="labelFormulaire">Prénom*</div>
                          <input
                            @keyup="checkNomPrenom"
                            @blur="checkNomPrenom"
                            v-model="contribution.prenom_contributeur"
                            type="text"
                            name="prenomContributeur"
                            id="prenomContributeur"
                          />
                        </div>
                      </div>
                      <div
                        class="infos-dispo-nom infobulle"
                        :bulle-content="messageDisponibiliteNomPrenom"
                      >
                        {{ disponibiliteNomPrenom }}
                      </div>
                    </div>

                    <!-- ********************* Nouveau contributeur - options de création de compte auteur *********************-->

                    <div class="frame-checkbox-creation-compte">
                      <input
                        id="creation-compte-auteur"
                        type="checkbox"
                        v-model="creationDeCompteAuteur"
                      />
                      &nbsp;<label for="creation-compte-auteur"
                        >Créer un compte auteur - Nécessite l'adresse mail du
                        contributeur
                        <span
                          class="infobulle"
                          bulle-content="Lors de la création, un mot de passe aléatoire sera créé et envoyé au contributeur. "
                          >(?)</span
                        ></label
                      >
                    </div>
                    <div v-if="creationDeCompteAuteur">
                      <div class="ligneFormulaire">
                        <div class="labelFormulaire">Identifiant*</div>
                        <input
                          @keyup="checkId"
                          @blur="checkId"
                          v-model="contribution.identifiant"
                          class="identifiant-contributeur"
                          placeholder="identifiant"
                          autocomplete="off"
                        />
                        <p
                          class="validation infobulle"
                          bulle-content="Disponibilité de l'identifiant"
                        >
                          {{ disponibiliteId }}
                        </p>
                        <p
                          class="auto-fill-id infobulle"
                          bulle-content="Remplir avec le nom"
                          @click="remplirIdAvecNom"
                        >
                          ✏️
                        </p>
                      </div>
                      <div class="ligneFormulaire">
                        <div class="labelFormulaire">Mail*</div>
                        <input
                          v-model="contribution.email_contributeur"
                          type="text"
                          name="mailContributeur"
                          id="mailContributeur"
                        />
                        <p
                          class="validation infobulle"
                          :bulle-content="
                            emailValidation
                              ? 'E-mail correct'
                              : 'E-mail incorrect'
                          "
                        >
                          {{ emailValidationIcon }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ************************ Nature contribution ************************* -->
            <div id="detailsAjoutContribution" class="sousSection">
              <h6>Contribution</h6>
              <div id="typeDeContributionContainer">
                <div class="type-contribution-checkbox">
                  <div>
                    <label for="typeContribution" class="labelFormulaire"
                      >Type Contribution :
                    </label>
                  </div>
                  <div>
                    <label class="form-check-label" for="dessinCheckBox">
                      <input
                        v-model="dessinCheckBox"
                        type="checkbox"
                        class="form-check-input"
                        id="dessinCheckBox"
                      />
                      Dessin
                    </label>
                  </div>
                  <div>
                    <label class="form-check-label" for="scenarioCheckBox">
                      <input
                        v-model="scenarioCheckBox"
                        type="checkbox"
                        class="form-check-input"
                        id="scenarioCheckBox"
                      />
                      Scenario
                    </label>
                  </div>
                  <div class="dflex">
                    <label class="form-check-label" for="autreCheckBox">
                      <input
                        v-model="autreCheckBox"
                        type="checkbox"
                        class="form-check-input"
                        id="autreCheckBox"
                      />
                      Autre :
                    </label>
                    <input
                      type="text"
                      v-model="typeContributionAutre"
                      class="input-autre-contribution"
                    />
                  </div>
                </div>

                <div>
                  <label class="labelFormulaire">Mode de calcul droits :</label>
                  <input
                    v-on:change="symboleMontantDroits = '%'"
                    type="radio"
                    name="modeDroitsContribution"
                    id="modeDroisContributionPourcentage"
                    value="pourcentage"
                    v-model="contribution.mode_contribution"
                  />
                  <label
                    class="form-check-label form-type-contribution-label"
                    for="modeDroisContributionPourcentage"
                    >Pourcentage</label
                  >
                  <input
                    v-on:change="symboleMontantDroits = '€'"
                    type="radio"
                    name="modeDroitsContribution"
                    id="modeDroisContributionFixe"
                    value="fixe"
                    v-model="contribution.mode_contribution"
                  />
                  <label
                    class="form-check-label form-type-contribution-label"
                    for="modeDroisContributionFixe"
                    >Fixe</label
                  >
                </div>

                <div class="ligneFormulaire">
                  <div class="labelFormulaire">Partage droits</div>
                  <input
                    type="text"
                    name="partageDroitsContribution"
                    id="partageDroitsContribution"
                    v-model="contribution.pourcentage_contribution"
                  />
                  <span>&nbsp;{{ symboleMontantDroits }}</span>
                </div>
              </div>
            </div>
            <div class="divTextCenter">
              <button
                id="boutonModifierContribution"
                class="btn-primary btn-fiche-numero"
                v-on:click="ajouterContribution()"
              >
                Ajouter la contribution
              </button>
            </div>
          </div>
        </section>
      </div>

      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->
      <!------------------------------------------- Section information / résumé / selection de numéro -->
      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------------------------->

      <section class="breadcrumb frame-section-resume">
        <div id="titreFicheNumero">
          <h4>{{ titrePage() }}</h4>
          <select
            v-if="!unlocked"
            type="select"
            id="selectionNumeroAConsulter"
            v-on:change="afficherExemplaire"
            v-model="idExemplaireSelectionne"
          >
            <option
              v-for="numero of listeNumeros"
              :key="numero.id_numero"
              :value="numero.id_numero"
              >{{ numero.numero_numero }} - {{ numero.titre_numero }}</option
            >
          </select>
        </div>

        <div class="details">
          <h5>Infos générales</h5>
          <div class="detailsNumero">
            <div class="infosTexte" id="recapAjoutNumero">
              <div class="ligneFormulaire ligneRecapModif">
                Exemplaire N°{{ exemplaireAEditer.numero_numero }}
              </div>
              <div class="ligneFormulaire ligneRecapModif">
                Titre : {{ exemplaireAEditer.titre_numero }}
              </div>
              <div class="ligneFormulaire ligneRecapModif">
                Mois de parution :
                {{ formatDate(exemplaireAEditer.date_parution_numero) }}
              </div>
              <div class="ligneFormulaire ligneRecapModif">
                Impressions :
                {{ exemplaireAEditer.nombre_impressions_numero }}
                <div
                  v-if="type_edition != 'ajout' && !unlocked"
                  class="frame-liste-impressions element-clickable"
                  @click="afficherListeImpressions"
                >
                  <div class="btn btn-primary btn-small">
                    Voir la liste
                  </div>
                </div>
              </div>
              <div
                v-if="exemplaireAEditer.estUnPack"
                class="ligneFormulaire ligneRecapModif"
              >
                Contenu du pack :
                <div
                  class="frame-liste-impressions element-clickable"
                  @click="afficherContenuPack"
                >
                  Voir la liste
                </div>
              </div>
              <div
                v-if="type_edition != 'ajout'"
                class="ligneFormulaire ligneRecapModif"
              >
                Nombre d'exemplaires vendus :
                {{ exemplaireAEditer.nombre_ventes_numero }}
              </div>
              <div
                v-if="type_edition != 'ajout'"
                class="ligneFormulaire ligneRecapModif"
              >
                Nombre d'exemplaires en stock :
                {{ nombreExemplaireDernierComptage() }}
              </div>
              <div class="details-derniers-stockages-container">
                <div
                  class="details-derniers-stockages"
                  v-for="stockage of exemplaireAEditer.liste_derniers_stockages"
                  :key="stockage.id_stockage"
                >
                  - {{ stockage.nom_lieu }} : {{ formatQuantiteStockage(stockage) }}
                </div>
              </div>
            </div>
            <div id="imgCouvDivModif">
              <img
                class="imgCouvertureModif"
                :src="exemplaireAEditer.couverture_numero"
                alt="couverture"
              />
            </div>
          </div>

          <div
            v-if="
              type_edition != 'ajout' &&
                user.adminAccessGranted &&
                !screenIsPhone
            "
            id="cadenasContainer"
            class="dflex cursorPointer"
            @click="inverseVerouillagePage"
          >
            <img
              id="cadenasImg"
              :src="imgCadenasEditionNumero"
              title="Cliquer pour changer le verouillage"
              alt="locked"
            />
            <div
              v-if="unlocked == true"
              class="infoVerouillage"
              title="Cliquer pour changer le verouillage - Attention, tous les changements seront perdus !"
            >
              Fiche numéro déverouillée
            </div>
            <div
              v-else
              class="infoVerouillage"
              title="Cliquer pour changer le verouillage"
            >
              Fiche numéro vérouillée
            </div>
          </div>
        </div>

        <div class="details">
          <h5>Contributions</h5>
          <div id="listeContributionsContainer">
            <div
              v-if="exemplaireAEditer.liste_contributions.length == 0"
              class="ligneFormulaire"
              id="listeContributions"
            >
              Aucune contribution n'a été ajoutée
            </div>
            <div
              v-else
              v-for="(contribution, i) of exemplaireAEditer.liste_contributions"
              :key="contribution.id_contribution"
              class="listeContributions"
            >
              <AffichageContribution
                :contribution="contribution"
                :numero_contribution="i"
                v-on:supprimer-contribution="effacerContribution"
              />
            </div>
          </div>
        </div>

        <div
          v-if="unlocked"
          v-on:click="enregistrerNumero()"
          class="btn-primary btn-fiche-numero"
        >
          {{
            type_edition == "ajout"
              ? "Ajouter le numéro"
              : "Appliquer les modifications"
          }}
        </div>
        <div
          v-if="unlocked && type_edition == 'consulter'"
          v-on:click="inverseVerouillagePage()"
          class="btn-danger btn-fiche-numero"
        >
          Annuler
        </div>
      </section>
    </div>
    <Keypress
      key-event="keyup"
      :key-code="27"
      @success="fermerModeModification"
    />
  </div>
</template>

<script>
import { formatDateNomDuMois } from "./../js/fonctionsGlobales";

import AffichageContribution from "./composantContributionTDD";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

const ACTIONS_TO_MAP = [
  "miseAJourNumeros",
  "initDetectionModification",
  "phpPost",
  "modaleAlert",
  "modaleOuiNon",
];

const MUTATIONS_TO_MAP = [
  "marquerModificationPresente",
  "resetModificationPresente",
  "resetListeNumerosPrete",
  "definirContenuModale",
  "afficherModale",
  "displayMessage",
];

const STATES_TO_MAP = [
  "numeroVide",
  "listeNumeros",
  "listeContributeurs",
  "modificationPresente",
  "listeNumerosPrete",
  "imgVerrouillageConst",
  "messageAlerteModification",
  "user",
  "screenIsPhone",
];

const GETTERS_TO_MAP = ["trouveNumeroParId", "trouveContributeurParId"];

export default {
  name: "fiche-numero",
  components: {
    AffichageContribution,
    Keypress: () => import("vue-keypress"),
  },
  data: function() {
    return {
      idNumeroAAjouterAuPack: 0,
      dateRegEx: /^((?:19|20)\d{2})-(0?\d|1[012])-(0[1-9]|[12]\d|3[01])$|^(0?\d|1[012])\/((?:19|20)\d{2})$|^((?:19|20)\d{2})-(0?\d|1[012])$/,
      visibiliteOptionNouveauContributeur: false,
      symboleMontantDroits: "%",
      type_edition: "",
      unlocked: false,
      errorsFound: false,
      rapportErreurs: [],
      couverture_numero: "",
      idExemplaire: "",
      titre_numeroAjout: "",
      numero_numeroAjout: "",
      dateParutionAjout: "",
      dessinCheckBox: false,
      scenarioCheckBox: false,
      autreCheckBox: false,
      typeContributionAutre: "",
      idContributeurSelectionne: "-",
      idExemplaireSelectionne: undefined,
      exemplaireAvantModif: undefined,
      exemplaireAEditer: undefined,

      modeAjoutContributeur: "existant",
      contribution: {
        id_contributeur: undefined,
        nom_contributeur: "",
        prenom_contributeur: "",
        date_naissance_contributeur: "",
        tel_contributeur: "",
        email_contributeur: "",
        type_contribution: "",
        mode_contribution: "pourcentage",
        pourcentage_contribution: "",
        siret_contributeur: "",
        identifiant: "",
      },
      disponibiliteId: "❌",
      disponibiliteNomPrenom: "❌",
      creationDeCompteAuteur: false,
      messageDisponibiliteNomPrenom:
        "Nom ou Prénom invalide(s) : seules les lettres (avec ou sans accent) et le trait d'union (touche 6) sont autorisés",
    };
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    afficherListeImpressions() {
      this.definirContenuModale({
        componentName: "ComposantListeImpressions",
        allowQuitOnClick: true,
        props: {
          numero: this.exemplaireAEditer,
        },
      });
      this.afficherModale();
    },
    retirerDuPack(index) {
      this.exemplaireAEditer.liste_numeros_pack.splice(index, 1);
      this.$forceUpdate();
    },
    ajouterNumeroAuPack() {
      if (this.exemplaireAEditer.liste_numeros_pack == undefined)
        this.exemplaireAEditer.liste_numeros_pack = [];
      if (
        this.idNumeroAAjouterAuPack == 0 ||
        this.idNumeroAAjouterAuPack == undefined
      )
        return;
      if (
        this.exemplaireAEditer.liste_numeros_pack.find((numero) => {
          return numero.id_numero == this.idNumeroAAjouterAuPack;
        }) != undefined
      ) {
        return this.displayMessage({
          texte: "Numero déjà ajouté au pack",
          type: "error",
        });
      }
      var numeroAAjouter = this.trouveNumeroParId(this.idNumeroAAjouterAuPack);
      if (numeroAAjouter.estUnPack)
        return this.displayMessage({
          texte:
            "Impossible d'ajouter un pack à un autre pack (Le numéro choisi est déjà un pack)",
          type: "error",
        });
      this.exemplaireAEditer.liste_numeros_pack.push({
        id_numero: this.idNumeroAAjouterAuPack,
        numero_numero: numeroAAjouter.numero_numero,
        titre_numero: numeroAAjouter.titre_numero,
      });
    },
    afficherContenuPack() {
      this.definirContenuModale({
        componentName: "ComposantListeContenuPack",
        allowQuitOnClick: true,
        props: {
          listeNumeros: this.exemplaireAEditer.liste_numeros_pack,
          titrePack:
            this.exemplaireAEditer.numero_numero +
            " " +
            this.exemplaireAEditer.titre_numero,
        },
      });
      this.afficherModale();
    },
    nombreExemplaireDernierComptage() {
      if (
        this.exemplaireAEditer.liste_comptages != undefined &&
        this.exemplaireAEditer.liste_comptages.length != 0 &&
        !this.exemplaireAEditer.liste_comptages[0].aucunComptage
      ) {
        let dateDernierComptage = this.formatDate(
          this.exemplaireAEditer.liste_comptages[
            this.exemplaireAEditer.liste_comptages.length - 1
          ].date_comptage
        );
        return this.exemplaireAEditer.liste_comptages[
          this.exemplaireAEditer.liste_comptages.length - 1
        ].quantite_comptage;
        //  +
        // " (dernier comptage : " +
        // dateDernierComptage
      } else return "aucun comptage n'a été enregistré pour ce numéro";
    },
    formatQuantiteStockage(stockage){
      if (this.exemplaireAEditer.estUnPack) {
        return `${stockage.quantite_vide_stockage} vides, ${stockage.quantite_stockage} pleins`
      }
    },
    inversionVerrouillage() {
      window.scrollTo(0, 0);
      this.unlocked = !this.unlocked;
      this.resetModificationPresente();
      if (!this.unlocked) {
        if (this.type_edition == "consulter") {
          this.exemplaireAEditer = JSON.parse(
            JSON.stringify(
              this.trouveNumeroParId(this.exemplaireAEditer.id_numero)
            )
          );
        } else
          this.exemplaireAEditer = JSON.parse(JSON.stringify(this.numeroVide));
      } else {
        this.$nextTick(() => {
          this.idNumeroAAjouterAuPack = 0;
          this.initDetectionModification();
          var tmp = this.exemplaireAEditer.date_parution_numero.split("-");
          if (tmp.length > 2) tmp.pop();
          this.exemplaireAEditer.date_parution_numero = tmp.join("-");
        });
      }
      this.definirLaSelection();
    },
    inverseVerouillagePage() {
      if (!this.user.adminAccessGranted) return;
      if (!this.modificationPresente) {
        this.inversionVerrouillage();
      } else {
        this.modaleOuiNon({
          message: this.messageAlerteModification,
          callBackYes: () => this.inversionVerrouillage(),
        });
      }
    },
    fermerModeModification() {
      if (this.unlocked && this.type_edition != "ajout")
        this.inverseVerouillagePage();
    },
    titrePage() {
      if (this.type_edition == "ajout") return "Ajouter numéro - récapitulatif";
      if (this.type_edition == "consulter")
        if (this.unlocked) return "Valider modifications numéro";
        else return "Fiche numéro";
    },
    formatDate(dateAFormater) {
      if (dateAFormater != undefined) {
        if (!dateAFormater.match(this.dateRegEx))
          return "Format date incorrect";
        return formatDateNomDuMois(dateAFormater);
      }
      return undefined;
    },
    changementImgCouv() {
      var viderInputFile = () => {
        // permet de vider le champ file
        this.$refs.fichierCouverture.type = "text";
        this.$refs.fichierCouverture.type = "file";
      };
      var fichierImageCouverture = this.$refs.fichierCouverture.files[0];
      this.rapportErreurs = [];
      var typesFichierUploadCouverture = ["JPEG", "PNG"];
      if (fichierImageCouverture.size >= 1000000) {
        this.modaleAlert("fichier trop volumineux - taille maximale : 1Mo");
        viderInputFile();
        return false;
      } else if (
        fichierImageCouverture.type == "" ||
        typesFichierUploadCouverture.indexOf(
          fichierImageCouverture.type.split("/")[1].toUpperCase()
        ) == -1
      ) {
        this.modaleAlert(
          "Type de fichier non pris en charge - types reconnus : jpeg, png"
        );
        viderInputFile();
        return false;
      }

      var formData = new FormData();
      formData.append("file", fichierImageCouverture);
      formData.append("idExemplaire", this.exemplaireAEditer.id_numero);

      this.phpPost({
        phpFile: "./assets/php/uploadCouvertureTmp.php",
        postType: "ajax",
        sendObject: formData,
        callBack: (response) => {
          if (response.reqSuccess)
            this.exemplaireAEditer.couverture_numero = response.imgURL;
        },
      });
    },
    effacerContribution(numero) {
      var contributeurStr =
        this.exemplaireAEditer.liste_contributions[numero].prenom_contributeur +
        " " +
        this.exemplaireAEditer.liste_contributions[numero].nom_contributeur;
      this.modaleOuiNon({
        message:
          "Etes-vous sûr de vouloir supprimer la contribution de  " +
          contributeurStr +
          " ?",
        callBackYes: () => {
          this.exemplaireAEditer.liste_contributions = this.exemplaireAEditer.liste_contributions.filter(
            function(e, i) {
              return i != numero;
            }
          );
          this.marquerModificationPresente();
        },
      });
    },
    ajouterContribution() {
      if (
        !(this.dessinCheckBox || this.scenarioCheckBox || this.autreCheckBox)
      ) {
        this.modaleAlert("Un type de contribution doit être choisi");
        return;
      }

      if (this.modeAjoutContributeur == "nouveau") {
        this.checkNomPrenom();
        this.checkId();
        if (!this.nomPrenomValidation)
          return this.modaleAlert(
            "Impossible d'ajouter la contribution :<br>Les nom et prénom sont vides ou existent déjà"
          );
        if (!this.idValidation)
          return this.modaleAlert(
            "Impossible d'ajouter la contribution :<br>L'identifiant existe déjà ou n'est pas conforme - utiliser uniquement des chiffres, des lettres (majuscules et minuscules) et le trait d'union '-' (touche 6 du clavier)"
          );
        if (!this.emailValidation && this.creationDeCompteAuteur)
          return this.modaleAlert(
            "Impossible d'ajouter la contribution :<br>L'adresse mail est vide ou invalide"
          );
        this.contribution.id_contributeur = "nouveau";
      } else if (this.modeAjoutContributeur == "existant") {
        if (this.idContributeurSelectionne == "-") {
          return this.modaleAlert("Un contributeur doit être choisi");
        }

        var contributeur = this.trouveContributeurParId(
          this.idContributeurSelectionne
        );
        this.contribution.id_contributeur = contributeur.id_contributeur;
        this.contribution.nom_contributeur = contributeur.nom_contributeur;
        this.contribution.prenom_contributeur =
          contributeur.prenom_contributeur;
      }

      this.contribution.type_contribution = this.typeContributionMisEnForme;
      if (this.contribution.pourcentage_contribution == "")
        this.contribution.droitsContributionMisEnForme = "non précisés";
      if (!this.creationDeCompteAuteur) this.contribution.identifiant = "";
      this.exemplaireAEditer.liste_contributions.push(
        Object.assign({}, this.contribution)
      );

      this.marquerModificationPresente();
      this.contribution.nom_contributeur = "";
      this.contribution.prenom_contributeur = "";
      this.contribution.identifiant = "";
      this.contribution.email_contributeur = "";
      this.checkId();
      this.checkNomPrenom();
    },
    enregistrerNumero() {
      this.rapportErreurs = [];
      this.errorsFound = false;

      var fichierPHP =
        this.type_edition == "ajout"
          ? "./assets/php/ajouterNumero.php"
          : "./assets/php/modifierNumero.php";

      if (this.exemplaireAEditer.titre_numero == "")
        return this.modaleAlert("Le champ titre doit être rempli");
      if (this.exemplaireAEditer.numero_numero == "")
        return this.modaleAlert("Le champ numéro d'exemplaire est vide");
      if (this.exemplaireAEditer.date_parution_numero == "")
        return this.modaleAlert("Le champ date de parution doit être rempli");

      if (this.exemplaireAEditer.declarerPremiereImpression) {
        if (
          this.exemplaireAEditer.coutPremiereImpression === "" ||
          this.exemplaireAEditer.coutPremiereImpression === undefined
        )
          return this.modaleAlert(
            'Le champ "coût première impression" doit être rempli'
          );
        if (
          this.exemplaireAEditer.quantitePremiereImpression === "" ||
          this.exemplaireAEditer.quantitePremiereImpression === undefined
        )
          return this.modaleAlert(
            'Le champ "quantite première impression" doit être rempli'
          );
      }

      if (!this.exemplaireAEditer.date_parution_numero.match(this.dateRegEx))
        return this.modaleAlert(
          "Format de date incorrect - choisir un format de type mm/aaaa ou aaaa-mm"
        );

      // this.resetListeNumerosPrete();
      this.exemplaireAEditer.date_parution_numero = this.exemplaireAEditer.date_parution_numero
        .split("/")
        .reverse()
        .join("-");

      this.phpPost({
        phpFile: fichierPHP,
        sendObject: this.exemplaireAEditer,
        dispatchAtTheEnd: ["miseAJourNumeros", "miseAJourContributeurs"],
        dispatchOnlyIfSuccess: true,
        callBack: (data) => {
          if (data.reqSuccess) {
            this.resetModificationPresente();
            this.inverseVerouillagePage();
            if (this.type_edition == "ajout")
              this.$router.push("/edition/consulter-" + data.id_numero);
          }
        },
      });
    },
    definirExemplaireAConsulter() {
      this.exemplaireAEditer = this.trouveNumeroParId(
        this.idExemplaireSelectionne
      );
      if (this.exemplaireAEditer != undefined) {
        this.resetModificationPresente();
      }
    },
    definirURL() {
      this.$router.push("/edition/consulter-" + this.idExemplaireSelectionne);
    },
    afficherExemplaire() {
      this.definirExemplaireAConsulter();
      this.definirURL();
    },
    definirLaSelection() {
      var parametresURL = this.$route.params.type_edition.split("-");
      if (parametresURL[1] == "") {
        this.exemplaireAEditer = this.listeNumeros[0];
      }
      this.idExemplaireSelectionne = this.exemplaireAEditer.id_numero;
    },
    remplirIdAvecNom() {
      this.contribution.identifiant = this.contribution.nom_contributeur
        .toUpperCase()
        .noAccent();
      this.checkId();
    },
    checkId() {
      this.contribution.identifiant = this.contribution.identifiant
        .toUpperCase()
        .noAccent();
      if (!this.idValidation) return (this.disponibiliteId = "❌");
      this.disponibiliteId = "🕒";
      this.contribution.identifiant = this.contribution.identifiant.trim();
      if (!this.idValidation) {
        this.disponibiliteId = "❌";
      } else {
        this.phpPost({
          phpFile: "assets/php/verifierIdentifiant.php",
          sendObject: { nouvelIdentifiant: this.contribution.identifiant },
          disableThumbnailForAll: true,
          callBack: (response) => {
            if (response.idDisponible) this.disponibiliteId = "✔️";
            else this.disponibiliteId = "❌";
          },
        });
      }
    },
    checkNomPrenom() {
      //   this.contribution.nom_contributeur = this.contribution.nom_contributeur.toUpperCase().noAccent();

      if (!this.nomPrenomValidation) {
        this.messageDisponibiliteNomPrenom =
          "Nom ou Prénom invalide(s) : seules les lettres (avec ou sans accent) et le trait d'union (touche 6) sont autorisés";
        return (this.disponibiliteNomPrenom = "❌");
      }
      this.disponibiliteNomPrenom = "🕒";
      this.contribution.identifiant = this.contribution.identifiant.trim();

      this.disponibiliteNomPrenom = "❌";
      this.phpPost({
        phpFile: "assets/php/verifierNomPrenom.php",
        sendObject: {
          nom: this.contribution.nom_contributeur,
          prenom: this.contribution.prenom_contributeur,
        },
        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.nomPrenomDisponible) {
            this.messageDisponibiliteNomPrenom = "Nom et prénom validés";
            this.disponibiliteNomPrenom = "✔️";
          } else {
            this.messageDisponibiliteNomPrenom =
              "un contributeur avec les mêmes nom et prénoms existe déjà";
            this.disponibiliteNomPrenom = "❌";
          }
        },
      });
    },
    initPage() {
      this.rapportErreurs = [];
      var parametresURL = this.$route.params.type_edition.split("-");
      this.type_edition = parametresURL[0];
      window.scrollTo(0, 0);
      if (
        this.type_edition == "consulter" ||
        this.user.adminAccessGranted !== true
      ) {
        if (this.listeNumerosPrete) {
          if (isNaN(parametresURL[1])) parametresURL[1] = 1;
          this.exemplaireAEditer = this.trouveNumeroParId(parametresURL[1]);
          if (this.exemplaireAEditer == false) {
            // id numero inconnu
            this.modaleAlert({
              message: "Impossible de charger le numéro !",
              callBack: () => {
                this.$router.push("/edition/consulter-1");
              },
            });
          }
          this.resetModificationPresente();
          this.definirLaSelection();
        }
      } else {
        this.exemplaireAEditer = JSON.parse(JSON.stringify(this.numeroVide));
        this.unlocked = true;
        this.exemplaireAEditer.declarerPremiereImpression = false;
        this.exemplaireAEditer.coutPremiereImpression = 0;
        this.exemplaireAEditer.quantitePremiereImpression = 0;
      }
      this.$nextTick(() => {
        this.initDetectionModification();
      });
      // this.unlocked = true;
    },
  },
  computed: {
    emailValidation() {
      return this.contribution.email_contributeur.match(
        /^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i
      );
    },
    emailValidationIcon() {
      return this.emailValidation ? "✔️" : "❌";
    },
    idValidation() {
      return (
        !this.creationDeCompteAuteur ||
        (this.contribution.identifiant.match(/\w{3,}/) &&
          !this.contribution.identifiant.match(/^$| +/) &&
          this.contribution.identifiant.match(/^[A-Za-z0-9\-]+$/))
      );
    },
    nomPrenomValidation() {
      return (
        !this.contribution.nom_contributeur.match(/^$/) &&
        this.contribution.nom_contributeur.match(/^[A-Za-z\-éèçüöïäëà ]+$/) &&
        !this.contribution.prenom_contributeur.match(/^$/) &&
        this.contribution.prenom_contributeur.match(/^[A-Za-z\-éèçüöïäëà ]+$/)
      );
    },
    typeContributionMisEnForme() {
      return (
        (this.dessinCheckBox ? "Dessin" : "") +
        (this.scenarioCheckBox ? " Scénario" : "") +
        (this.autreCheckBox ? " " + this.typeContributionAutre : "")
      ).trim();
    },
    imgCadenasEditionNumero() {
      return this.imgVerrouillageConst.get(!this.unlocked);
    },
    listeContributions() {
      return this.exemplaireAEditer == undefined
        ? []
        : this.exemplaireAEditer.liste_contributions;
    },
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
  },
  watch: {
    $route(to, from) {
      this.unlocked = false;
      this.initPage();
    },
    listeNumeros() {
      this.initPage();
    },
  },
  beforeMount: function() {
    this.exemplaireAEditer = JSON.parse(JSON.stringify(this.numeroVide));
  },
  mounted: function() {
    this.unlocked = false;
    this.initPage();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
h5 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 30px;
}
.ligneFormulaire {
  @include typo-medium;
  height: 35px;
  &.option-ajout-numero {
    margin-left: 40px;
    &.liste-choix-numero-pack {
      margin-bottom: 0;
      margin-right: 10px;
      @include typo-small-medium;
    }
  }
  @media (max-width: $mobile-break-point) {
    margin-bottom: 0;
  }
}
.liste-numero-pack-container {
  @extend .bshadow;
  display: flex;
  padding: 5px;
  // background-color: $fond-page;
  margin-left: 40px;
  border-radius: 10px;
  // box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
}
.numero-a-ajouter-au-pack {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.retirer-numero-pack {
  @extend .element-clickable;
  width: 30px;
  text-align: center;
}

.labelFormulaire {
}

#titre_numeroAjout {
  width: 300px;
}

#titreFicheNumero {
  display: flex;
  justify-content: space-between;
  width: 600px;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}

#numero_numeroAjout {
  width: 50px;
}

#dateParutionAjout {
  width: 80px;
  margin-right: 5px;
}

.inputSaisiePremiereImpression {
  width: 100px;
}

.icone-ajout-impression {
  margin-right: 5px;
  position: relative;
  height: 100%;
}

.detailsNumero {
  display: flex;
  justify-content: space-between;
  @media (max-width: $mobile-break-point) {
    flex-direction: column;
  }
  // padding-right: 50px;
}

.details-derniers-stockages-container {
  @include typo_medium;
  margin-left: 30px;
}

.details-derniers-stockages {
  margin-bottom: 5px;
}

.imgCouvertureModif {
  width: 230px;
  // margin-left: 60px;
}

#optionsAjoutContributeur {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#listeContributeurs {
  margin-left: 10px;
}

.consignes {
  color: $couleur-texte-fonce;
  @include typo_small;
  margin-top: 20px;
  text-align: center;
}

.nom-prenom {
  display: flex;
}

.infos-dispo-nom {
  border-left: 1px solid #000;
  height: 70px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

#detailsNouveauContributeur {
  margin-left: 30px;
}

.frame-checkbox-creation-compte {
  margin-bottom: 10px;
}

.centered-button {
  margin: 20px auto 0 auto;
  width: 250px;
}

#listeContributionsContainer {
  margin: 20px 0;
}

.listeContributions {
  display: flex;
  margin: 0 20px;
}

.itemListCol {
  border: 1px solid #000;
  text-align: center;
  background-color: #fff;
}

.nonEditable {
  background-color: #eeeeee;
}

.itemListCol1 {
  width: 20px;
  background-color: #ff6b6b;
}

.itemListCol2 {
  width: 200px;
}

.itemListCol3 {
  width: 200px;
}

.itemListCol4 {
  width: 100px;
}

.itemListCol5 {
  width: 100px;
}

.form-check-label {
  width: 90px;
  margin-left: 20px;
}

.form-type-contribution-label {
  margin-left: 5px;
  margin-right: 20px;
}

.type-contribution-checkbox {
  display: flex;
  align-items: center;
}

.input-autre-contribution {
  width: 150px;
}

.label-contribution-pourcentage {
  margin-right: 20px;
}

.ligneRecapModif {
  //   font-size: 1.2em;
  //   margin-top: 7px;
}

.frame-liste-impressions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.icone-liste-impressions {
  height: 30px;
  // margin-left: 10px;
  margin-right: 10px;
}

#cadenasImg {
  height: 40px;
  margin-top: 5px;
}

.infoVerouillage {
  text-align: center;
}

#cadenasContainer {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 5px;
}
#cadenasContainer:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.validation {
  margin-left: 10px;
  &:hover {
    cursor: help;
  }
}

.auto-fill-id {
  font-size: 0.9em;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}

input {
  &[type="checkbox"] {
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
  &[type="radio"] {
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
}

.btn-primary {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
}
.btn-danger {
  background-color: $danger-btn;
  @include btnHover($danger-btn);
}

.btn-fiche-numero {
  @include typo-medium;
  @extend .btn;
  @extend .centered-button;
  text-align: center;
  color: white;
  padding: 9px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);

  // border: 3px solid black;
  // max-width: none;
  // width: 500px;
}

.frame-section-resume {
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.corpsDePage {
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
section {
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}

.infosTexte {
  @media (max-width: $mobile-break-point) {
    order: 2;
    margin-top: 30px;
  }
}

#imgCouvDivModif {
  @media (max-width: $mobile-break-point) {
    width: 100%;
    text-align: center;
  }
}

#selectionNumeroAConsulter {
  @media (max-width: $mobile-break-point) {
    width: 50%;
  }
}
</style>
