<template>
  <div class="composant-ajout-impression">
    <p class="titre">Ajouter une impression</p>
    <div class="separateur1"></div>
    <div class="frame-ligne-modale">
      <p class="label">Numéro concerné*</p>
    <select name="liste-numeros" id="liste-numeros" v-model='impression.id_numero'>
      <option value="-">-</option>
      <option v-for="numero of listeNumeros" :key='numero.id_numero' :value="numero.id_numero">{{ numero.numero_numero + ' - ' + numero.titre_numero }}</option>
    </select>
      <!-- 
      <div>{{ nomNumero }}</div> -->
    </div>
    <div class="frame-ligne-modale">
      <p class="label">Date d’impression*</p>
      <input type="date" v-model="impression.date_impression" class="numero-imprime" placeholder="Date" />
    </div>
    <div class="frame-ligne-modale">
      <p class="label">Nombre d’exemplaires imprimés*</p>
      <input 
        v-model="impression.quantite_impression"
        class="numero-imprime"
        placeholder="Quantite"
      />
    </div>
    <div class="frame-ligne-modale">
      <p class="label">Cout de l’impression*</p>
      <input 
        v-model="impression.cout_impression"
        class="numero-imprime"
        placeholder="Montant"
      />
    </div>
    <div class="frame-boutons">
      <div class="frame-ajouter">
        <p class="bouton-ajouter-button" @click="ajouterImpression">Ajouter</p>
      </div>
      <div class="frame-annuler" @click='afficherListeImpressions'>
        <p class="bouton-ajouter-button">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
    name: "ComposantAjoutImpression",
    props: {
        idNumero: { default: undefined },
    },
    data: ()=>{
        return {
            impression: {
                id_numero: '-',
                date_impression: '0000-00-00',
                quantite_impression: 0,
                cout_impression: 0
            }
        }
    },
    methods: {
        ...mapActions(['phpPost', 'modaleAlert']),
        ...mapMutations(['masquerModale', 'definirContenuModale']),
        afficherListeImpressions() {
          this.definirContenuModale({
            componentName: "ComposantListeImpressions",
            allowQuitOnClick: true,
            props: {
                numero: this.trouveNumeroParId(this.impression.id_numero)
            }
          })
        },
        ajouterImpression() {
          
          if (this.impression.id_numero == '-') return this.modaleAlert("Aucun numéro n'a été choisi")
          if (this.impression.date_impression == '0000-00-00') return this.modaleAlert("Aucune date n'a été choisie")
          if (this.impression.quantite_impression == 0) return this.modaleAlert("La quantité ne peut pas être nulle")
          // if (this.impression.cout_impression == 0) return this.modaleAlert("Le coût ne peut pas être nul")

          this.phpPost({
            phpFile: 'assets/php/ajouterImpression.php',
            sendObject: this.impression,
            displayLoader: "Ajout d'impression en cours...",
            dispatchAtTheEnd: ['miseAJourNumeros', 'miseAJourContributeurs'],
            callBack: (response) => {
              if (response.reqSuccess) this.afficherListeImpressions()
            }
          })
        }
    },
    computed: {
        ...mapState(['numeroVide', 'listeNumeros']),
        ...mapGetters(['trouveNumeroParId']),
        nomNumero() {
            if (this.numero == undefined) return '-'
            return this.numero.numero_numero + ' - ' + this.numero.titre_numero
        }
    },
    mounted: function() {
        if (this.idNumero!=undefined) this.impression.id_numero = this.idNumero
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-ajout-impression {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-ligne-modale {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label {
  width: 340px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}
.numero-imprime {
  width: 170px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.cout-impression {
  width: 75px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
  width: 244px;
}
.frame-ajouter {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.bouton-ajouter-button {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
.frame-annuler {
  background-color: $danger-btn;
  @include btnHover($danger-btn);

  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>