<template>
  <div class="composant-infos-decla-contributeur">
    <div class="main-frame">
      <p class="nom-complet-contributeur">
        {{ nomCompletContributeur }}
      </p>
      <div class="separateur"></div>
      <div class="frame-infos">
        <div class="frame-infos-generales">
          <div class="frame-ligne-details">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(datePremierNumero)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/968326c6-c0bd-4c2f-b708-9fec71185f0f.png"
                @click="copier(datePremierNumero)"
              /> -->
            </div>
            <p class="label-ligne-details">
              Première publication RDL
            </p>
            <p class="date-premier-numero">
              {{ datePremierNumero }}
            </p>
          </div>
          <div class="frame-ligne-details">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(numeroSecu)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/20c49ea7-f70e-41bb-b32a-ca241d05c455.png"
                @click="copier(numeroSecu)"
              /> -->
            </div>
            <p class="label-ligne-details">
              Numéro Sécurité sociale
            </p>
            <p class="date-premier-numero">
              {{ numeroSecu }}
            </p>
          </div>
          <div class="frame-ligne-details">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(mailContributeur)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/b95a191d-23ca-4b6a-994b-27fb80aa5439.png"
                @click="copier(mailContributeur)"
              /> -->
            </div>
            <p class="label-ligne-details">Mail</p>
            <p class="date-premier-numero">
              {{ mailContributeur }}
            </p>
          </div>
          <div class="frame-ligne-details">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(telContributeur)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/5484f5f9-c8cc-48e8-92d1-dd4e38d4f1f0.png"
                @click="copier(telContributeur)"
              /> -->
            </div>
            <p class="label-ligne-details">Téléphone</p>
            <p class="date-premier-numero">
              {{ telContributeur }}
            </p>
          </div>
          <div class="frame-ligne-details-two">
            <p class="label-ligne-details-two">
              Liste des activités :
            </p>
            <div class="frame-liste-activites">
              <p class="date-premier-numero" v-html="detailActivite"></p>
            </div>
          </div>
        </div>
        <div class="separateur-two"></div>
        <div class="frame-adresse">
          <div class="frame-ligne-details-three">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(libelleAdresse)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/87d9b724-6a6c-4b1c-9bf6-7ca23a766b1c.png"
                @click="copier(libelleAdresse)"
              /> -->
            </div>
            <p class="label-ligne-details">Adresse</p>
            <p class="date-premier-numero">
              {{ libelleAdresse }}
            </p>
          </div>
          <div class="frame-ligne-details-three">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(infosComplementaires)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/06bb8ff8-ccc0-480a-9a58-912513742fb0.png"
                @click="copier(infosComplementairesAdresse)"
              /> -->
            </div>
            <p class="label-ligne-details">
              Infos complémentaires
            </p>
            <p class="date-premier-numero">
              {{ infosComplementairesAdresse }}
            </p>
          </div>
          <div class="frame-ligne-details-three">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(codePostal)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/c4025839-ef71-4056-8ccc-eb48aa086b3c.png"
                @click="copier(codePostal)"
              /> -->
            </div>
            <p class="label-ligne-details">Code postal</p>
            <p class="date-premier-numero">
              {{ codePostal }}
            </p>
          </div>
          <div class="frame-ligne-details-three">
            <div class="frame-icone">
              <div class="element-clickable bouton-copier" @click="copier(ville)">Copier</div>
              <!-- <img
                class='element-clickable'
                alt=""
                src="https://static.overlay-tech.com/assets/7205e5a8-0c29-4ee6-8fb6-0e0a6c6b54a6.png"
                @click="copier(ville)"
              /> -->
            </div>
            <p class="label-ligne-details">Ville</p>
            <p class="date-premier-numero">{{ ville }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "ComposantInfosDeclaContributeur",
  data:() => {
    return {
      visibiliteVignette: false
    }
  },
  props: {
    infos: undefined
  },
  computed: {
    ...mapGetters(['dateFrancaise']),
    nomCompletContributeur(){
      return 'Infos déclaratives - ' + this.infos.prenom_contributeur +  ' ' + this.infos.nom_contributeur
    },
    datePremierNumero() {
      if (this.infos==undefined) return 'Manquant'
      else return this.infos.date_premiere_publication == '' ? 'Manquant' : this.dateFrancaise(this.infos.date_premiere_publication)
    },
    numeroSecu() {
      if (this.infos==undefined) return 'Manquant'
      else return this.infos.numero_secu_contributeur == '' ? 'Manquant' : this.infos.numero_secu_contributeur
    },
    mailContributeur() {
      if (this.infos==undefined) return 'Manquant'
      else return this.infos.email_contributeur == '' ? 'Manquant' : this.infos.email_contributeur
    },
    telContributeur() {
      if (this.infos==undefined) return 'Manquant'
      else return this.infos.tel_contributeur == '' ? 'Manquant' : this.infos.tel_contributeur
    },
    detailActivite() {
      if (this.infos==undefined) return 'Manquant'
      else if (this.infos.liste_type_contribution == undefined) return ''
      else return this.infos.liste_type_contribution.reduce((a, v) => { return a + '&nbsp; - ' + v + '<br>'}, '')
    },
    libelleAdresse() {
      return this.infos.adresse_contributeur.libelle_adresse
    },
    codePostal() {
      return this.infos.adresse_contributeur.code_postal_adresse
    },
    ville() {
      return this.infos.adresse_contributeur.ville_adresse
    },
    infosComplementairesAdresse() {
      return this.infos.adresse_contributeur.infos_complementaires_adresse
    },
  },
  methods: {
    ...mapMutations(['displayMessage']),
    copier(ref) {
      let tmpElement = document.createElement('input');
      tmpElement.visible=false
      document.body.appendChild(tmpElement)
      tmpElement.value = ref
      tmpElement.focus()
      tmpElement.select()
      document.execCommand('copy')
      document.body.removeChild(tmpElement);

      this.displayMessage({
        texte: 'Texte copié !',
        type: 'success',
        duree: 1500
      })
    }
  }
};
</script>


<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-infos-decla-contributeur {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-frame {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nom-complet-contributeur {
  color: $couleur-texte-fonce;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  @include typo-titre;
}
.separateur {
  height: 1px;
  background-color: $silver;
  margin-bottom: 19px;
  align-self: stretch;
}
.frame-infos {
  display: flex;
  align-items: flex-start;
}
.frame-infos-generales {
  margin-right: 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-ligne-details {
  margin-bottom: 19px;
  border-radius: 9px;
  padding: 5px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.frame-icone {
  padding: 0 10px 0 0;
  display: flex;
  align-items: flex-start;
}
.label-ligne-details {
  width: 250px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.date-premier-numero {
  color: $couleur-texte-fonce;
  @include typo-medium;
  white-space: nowrap;
}
.frame-ligne-details-two {
  border-radius: 9px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.label-ligne-details-two {
  @extend .label-ligne-details
  // width: 280px;
  // color: $couleur-texte-fonce;
  // margin-bottom: 9.5px;
  // @include typo-medium;
}
.frame-liste-activites {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.separateur-two {
  width: 1px;
  background-color: $silver;
  margin-right: 19px;
  align-self: stretch;
}
.frame-adresse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frame-ligne-details-three {
  border-radius: 9px;
  padding: 5px;
  display: flex;
  align-items: center;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 19px;
  }
}
.bouton-copier{
  border-radius: 15px;
  border: 1px solid black;  
  padding-top: 0px;
  padding-bottom: 0px;
}

</style>