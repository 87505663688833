<template>
  <div class="page-festival">
    <div class="corps-de-page">
      <div class="titre">Festivals</div>
      <div class="liste-festival">
        <div
          v-for="festival of listeFestivals"
          :key="festival.id_festival"
          class="carte-container"
        >
          <ComposantCarteFestival
            class="carte-festival"
            :festival="festival"
            @supprimer-festival="() => supprimerFestival(festival.id_festival)"
            @afficher-details-festival="
              () => $router.push('/festival/festival-' + festival.id_festival)
            "
          />
        </div>
        <div class="carte-container">
          <ComposantCarteFestival
            class="carte-festival"
            @ajouter-festival="ajouterFestival"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { consoleMessage } from "../js/consoleMessage";
export default {
  name: "pageGestionFestival",
  components: {
    ComposantCarteFestival: () => import("./composantCarteFestival"),
  },
  data: () => {
    return {
      listeFestivals: [],
    };
  },
  methods: {
    ...mapActions(["phpPost", "modaleComposant", "modaleOuiNon"]),
    ajouterFestival() {
      this.modaleComposant({
        componentName: "ComposantAjoutFestival",
        allowQuitOnClick: true,
        props: {
          updateListeFestival: this.updateListeFestival,
        },
      });
    },
    supprimerFestival(id) {
      this.modaleOuiNon({
        message: "Êtes-vous sûr.e de vouloir supprimer ce festival ?",
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/supprimerFestival.php",
            sendObject: { id },
            callBack: (response) => {
              if (response.reqSuccess) this.updateListeFestival();
            },
          });
        },
      });
    },
    updateListeFestival() {
      console.group("Groupe 1");
      consoleMessage("this.listeFestivals", this.listeFestivals);
      console.groupEnd();
      this.phpPost({
        phpFile: "assets/php/listeFestivals.php",
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess)
            this.listeFestivals = response.listeFestivals;
        },
      });
    },
  },
  mounted: function() {
    this.updateListeFestival();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.page-festival {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  // padding: 0 50px;
  width: 100%;
}

.corps-de-page {
  width: 70%;
  // height: 80vh;
  // justify-content: space-around;
  padding: 50px 20px;
  @include typo-medium;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}

.titre {
  @include typo-gros-titre;
  margin-bottom: 50px;
}

.liste-festival {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // align-items: start;
}

.carte-container {
  margin: 15px 10px;
}

.carte-festival {
}

.zone-selection {
  @include typo-medium;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  display: flex;
  flex-direction: column;
}
</style>
