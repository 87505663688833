<template>
  <div class="zone-selection">
    <div class="partie-haute">
      <div
        class="btn btn-primary btn-fiche-festival"
        @click="afficherFicheFestival"
      >
        Retour à la fiche festival
      </div>
      <div class="nomLieu">
        {{ detailsLieuCourant }}
      </div>
      <!-- <select
        class="select-lieu"
        name="lieu"
        id="lieu"
        :value="idLieu"
        @change="(e) => changerLieu(e.target.value)"
      >
        <option :value="INFOS_GENERALES">Choisir un lieu</option>
        <option
          v-for="lieu in listeLieux"
          :key="lieu.id_lieu"
          :value="lieu.id_lieu"
          >{{ lieu.nom_lieu }}</option
        >
      </select> -->


      <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
        <div
          class="btn btn-primary btn-fiche-festival"
          style="width: 49%; height: 50px;"
          v-for="lieu in listeLieux"
          :key="lieu.id_lieu"
          @click="() => definirLieu(lieu)"
          >{{ lieu.nom_lieu }}
        </div>
      </div>
      
      <div class="boutons-type-saisie-frame">
        <div
          class="btn btn-primary btn-type-saisie"
          @click="() => definirTypeSaisie(UNITE_FESTIVAL_DEPART)"
        >
          Saisie au départ
        </div>
        
        <div
          class="btn btn-primary btn-type-saisie"
          @click="() => definirTypeSaisie(UNITE_FESTIVAL_RETOUR)"
        >
          Saisie au retour
        </div>
      </div>

      <div
        v-if="idLieu != INFOS_GENERALES && !festivalCloture"
        class="btn btn-primary btn-fiche-festival"
        @click="showNumeroList"
      >
        Choisir numéros
      </div>
      
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
        <div
          v-if="idLieu != INFOS_GENERALES && !festivalCloture"
          class="btn btn-primary btn-fiche-festival"
            style="width: 49%;"
          @click="selectionnerTousLesNumeros"
        >
          Tous
        </div>
        
        <div
          v-if="idLieu != INFOS_GENERALES && !festivalCloture"
          class="btn btn-primary btn-fiche-festival"
            style="width: 49%;"
          @click="retirerTousLesNumeros"
        >
          Aucun
        </div>
      </div>

      
      <!-- <div v-if="idLieu != INFOS_GENERALES" style="display: none;">
        <div class="label">
          Type
        </div>
        <select
          class="select-lieu"
          name="moment"
          id="moment"
          :value="typeUniteAAfficher"
          @change="(e) => resetListeEmmenes(e.target.value)"
        >
          <option :value="UNITE_FESTIVAL_DEPART">Départ</option>
          <option :value="UNITE_FESTIVAL_RETOUR">Retour</option>
        </select>
      </div> -->
      <div
        v-if="idLieu != INFOS_GENERALES && !festivalCloture"
        class="frame-liste-numeros"
      >
          <multiselect
            :style="listeNumerosStyle"
            ref="selectNumeroList"
            id="liste-numeros"
            name="liste-numeros"
            class="liste-numeros"
            track-by="id_numero"
            @input="ajouterNumero"
            @remove="retirerNumero"
            :options="listeNumeros"
            :value="listeNumerosEmmenes"
            :customLabel="
              (numero) => `${numero.numero_numero} - ${numero.titre_numero}`
            "
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="true"
            @open="() => openEvents()"
            @close="() => closeEvents()"
          />
        </div>
        <!-- <select
          id="liste-numeros"
          name="liste-numeros"
          class="liste-numeros"
          v-model="numeroAAjouter"
          @change="() => ajouterNumero(numeroAAjouter)"
          multiple
        >
          <option
            v-for="numero of listeNumerosFiltree"
            :key="numero.id_numero"
            :value="numero"
            >{{ numero.numero_numero }} {{ numero.titre_numero }}</option
          >
        </select> -->
        <!-- <div class="notice-mobile info-festival-cloture">
          <span class="icone-info-mobile">i</span>
          Si vous êtes actuellement sur mobile et que vous avez déjà ajouté des
          numéros, ignorez le message '0 Element(s) sélectionné(s)'.
        </div> -->
      <!-- </div> -->
      <div
        v-else-if="idLieu != INFOS_GENERALES && festivalCloture"
        class="info-festival-cloture"
      >
        <div class="zone-titre-infos-festival">
          <span class="icone-info-mobile">i</span>
          Festival Clôturé
        </div>
        Il n'est désormais plus possible d'ajouter des numéros
      </div>
    </div>
    <div class="validation-lieu-numeros" @click="cacherChoixNumerosMobileTMP">
      <div class="btn btn-primary">Commencer la saisie</div>
    </div>
    <div
      v-if="boutonFermerVisible"
      class="close-select-cross"
      @click="fermerChoixNumeros"
    >
      <div class="btn btn-primary">Retour</div>
    </div>
    <div v-if="afficherMasqueGris" class="masque-gris"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UNITE_FESTIVAL_DEPART, UNITE_FESTIVAL_RETOUR } from "../js/storeConst";
const INFOS_GENERALES = "Aucun lieu choisi";

export default {
  name: "ComposanSelectionNumerosFestival",
  props: {
    festivalCloture: { default: false },
    versionMobile: { default: false },
    typeUniteAAfficher: { default: UNITE_FESTIVAL_DEPART },
    idLieu: { default: INFOS_GENERALES },
    listeNumerosFiltree: { default: [] },
    listeNumeros: { default: () => [] },
    listeNumerosEmmenes: { default: () => [] },
    changerLieu: {
      type: Function,
      default: function() {
        console.error("Fonction de changement de lieu manquante");
      },
    },
    ajouterNumero: {
      type: Function,
      default: function() {
        console.error("Fonction d'ajout de numéro manquante");
      },
    },
    resetListeEmmenes: {
      type: Function,
      default: function() {
        console.error("Fonction de reset de liste de numéros emmenés");
      },
    },
    cacherChoixNumerosMobile: {
      type: Function,
      default: function() {
        console.error(
          "Fonction de masquage du choix de numéros sur mobile non transmise"
        );
      },
    },
    retirerNumero: {
      type: Function,
      default: function() {
        console.error(
          "Fonction de suppression de numéros de la liste non transmise"
        );
      },
    },
  },
  data: () => {
    return {
      INFOS_GENERALES,
      numeroAAjouter: [],
      // screenIsPhone: false,
      boutonFermerVisible: false,
      afficherMasqueGris: false,
      nomLieuCourant: "Cliquez pour choisir un lieu",
      typeSaisie: UNITE_FESTIVAL_DEPART,
      listeNumerosStyle: "display: block;"
    };
  },
  computed: {
    ...mapState([
      "listeLieux",
      "UNITE_FESTIVAL_DEPART",
      "UNITE_FESTIVAL_RETOUR",
      "screenIsPhone",
    ]),
    detailsLieuCourant(){
      if (this.idLieu == INFOS_GENERALES) return "Choisir un lieu"
      return this.nomLieuCourant + " / " + ((this.typeSaisie & UNITE_FESTIVAL_DEPART == 1) ? "Départ" : "Retour")
    }
  },
  methods: {
    ...mapActions([
      "modaleAlert",
      "modaleOuiNon",
    ]),
    definirTypeSaisie(typeUnite){
      this.resetListeEmmenes(typeUnite)
      this.typeSaisie = typeUnite
    },
    definirLieu(lieu){
      this.nomLieuCourant = lieu.nom_lieu
      this.changerLieu(lieu.id_lieu)
    },
    showNumeroList(){
      this.listeNumerosStyle = "display: block;"
      this.$nextTick(()=>{
        this.$refs.selectNumeroList.activate()
      })
    },
    selectionnerTousLesNumeros(){
      this.modaleOuiNon({
        message: "Ajouter tous les numéros à " + this.detailsLieuCourant + " ?",
        callBackYes: () => {
          this.modaleAlert({
            message: "Ajout de tous les numéros à " + this.detailsLieuCourant,
            displayLoader: true,
          });
          this.ajouterNumero(this.listeNumeros)
        }
      })
    },
    retirerTousLesNumeros(){
      this.modaleOuiNon({
        message: "<h5>Etes vous sûr de vouloir retirer tous les numéros de la liste pour " + this.detailsLieuCourant + " ? <br>⚠️ Attention, toutes les saisies seront perdues !</h5>",
        captionYes: "Retirer les numéros",
        captionNo: "Annuler",
        callBackYes: () => {
          for (let numero of this.listeNumeros) {
            this.retirerNumero(numero)
          }
        }
      })
          },
    cacherChoixNumerosMobileTMP() {
      this.cacherChoixNumerosMobile();
    },
    afficherFicheFestival() {
      this.changerLieu(INFOS_GENERALES);
    },
    openEvents() {
      if (this.screenIsPhone) {
        this.boutonFermerVisible = true;
      } else {
        this.afficherMasqueGris = true;
      }
    },
    closeEvents() {
      if (this.screenIsPhone) {
        this.boutonFermerVisible = false;
      } else {
        this.afficherMasqueGris = false;
      }
      // this.listeNumerosStyle = "display: none;"
    },
    fermerChoixNumeros() {
      this.boutonFermerVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";

$marge-titre: 20px;
$title-font-size: 25px;

.zone-selection-width {
  width: 340px;
}

.zone-selection {
  text-align: center;
  @include typo-festival;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  width: 300px;
  height: calc(90vh - 60px);
  @media (max-width: $mobile-break-point) {
    z-index: 40;
    display: block;
    position: fixed;
    top: $ruban-height;
    bottom: $footer-height;
    left: 0;
    margin: 0;
    max-width: none;
    min-width: none;
    width: 100%;
    padding-bottom: 10px;
    // height: 90vh;
  }
  // border: 3px solid black;
  // height: 80vh;
}

.partie-haute {
  display: flex;
  // border: 1px solid black;
  height: 100%;
  flex-direction: column;
}

.titre-zone-selection {
  margin-bottom: $marge-titre;
  font-size: $title-font-size;
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
}

.btn-fiche-festival {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  @include typo-festival;
  margin-bottom: 10px;
}

.validation-lieu-numeros {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: $footer-height;
  background: $fond-page;
  // font-size: 18px;
  z-index: 200;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.4);
}

.close-select-cross {
  @include closeCross(210);
  & .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 50%;
    @include typo-large;
  }
}
select {
  @media (max-width: $mobile-break-point) {
    height: 30px;
  }
}
#lieu {
  @media (max-width: $mobile-break-point) {
    height: 30px;
  }
}
.select-lieu {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.frame-liste-numeros {
  & .multiselect__content-wrapper,
  & .multiselect__content {
    // $largeur-liste-choix: 400px;
    border: 1px solid $couleur-texte-fonce;
    // border-radius: 15px;
    position: fixed;
    left: calc(50% - 200px);
    //right: 200px !important;
    width: 400px;
    top: $ruban-height + 50px;
    bottom: 50px;
    overflow: auto;
    background: #fff;
    min-width: 0 !important;
    // z-index: 70 !important;

    @media (max-width: $mobile-break-point) {
      background: #fff;
      position: fixed;
      top: $ruban-height;
      bottom: $footer-height;
      left: 0px;
      overflow: auto;
      // z-index: 70;
      width: 100%;
    }
  }
}
.liste-numeros {
  @include typo-festival;
  width: 100%;
  height: 90%;
  @media (max-width: $mobile-break-point) {
    height: 50px;
  }
  // z-index: 115;
}

.label {
  text-align: left;
  @include typo-festival;
  margin-left: 5px;
  margin-top: 5px;
}

.info-festival-cloture {
  margin: 10px;
  background-color: $fond-page;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;
}

.zone-titre-infos-festival {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.notice-mobile {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
}
.icone-info-mobile {
  @include typo-festival;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid $couleur-texte-fonce;
}
.masque-gris {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.nomLieu{
  text-align: center;
  font-size: 18px;
  margin: 15px 0;
}

.boutons-type-saisie-frame{
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  .btn-type-saisie{
    width: 100px;
  }
}
// display: none;
// // border: 1px solid #000;
// @media (max-width: $mobile-break-point) {
//   display: flex;
// }
// justify-content: center;
// font-size: 2.5em;
// width: 50px;
// position: fixed;
// bottom: 10px;
// background: rgba(122, 122, 122, 0.1);
// left: calc(50% - 25px);
// border-radius: 50%;

.multiselect__option--highlight{
  background-color: rgb(108, 211, 114)!important;
}


.multiselect__option--selected:before {
  content: "✔️";
  margin-right: 5px;
  // position: absolute;
  // right: 15px;
  // color: black;
  // width: 10px;
  // height: 10px;
  // z-index: 100;
}
</style>
