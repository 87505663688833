<template>
  <div class="composant-modale-oui-non">
    <p class="message" v-html="params.message"></p>
    <div class="separateur1"></div>
    <div class="frame-boutons">
      <button class="bouton-oui" @click="clickSurOui">
        <p class="texte-bouton">{{ texteOui }}</p></button
      ><button class="bouton-non" @click="clickSurNon">
        <p class="texte-bouton">{{ texteNon }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "ComposantModaleOuiNon",
  props: {
    params: {
      default: {},
    },
  },
  computed: {
    texteOui() {
      return this.params.captionYes == undefined
        ? "Oui"
        : this.params.captionYes;
    },
    texteNon() {
      return this.params.captionNo == undefined ? "Non" : this.params.captionNo;
    },
  },
  methods: {
    ...mapMutations(["definirVisibiliteModale", "definirContenuModale"]),
    callBackYes() {
      if (this.params.callBackYes != undefined) this.params.callBackYes();
    },
    callBackNo() {
      if (this.params.callBackNo != undefined) this.params.callBackNo();
    },
    quitterModale() {
      if (this.params.waitingContent != undefined)
        this.definirContenuModale(this.params.waitingContent);
      else this.definirVisibiliteModale(false);
    },
    clickSurOui() {
      this.quitterModale();
      this.callBackYes();
    },
    clickSurNon() {
      this.quitterModale();
      this.callBackNo();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-modale-oui-non {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    max-width: none;
    padding: 10px 10px;
  }
}
.message {
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 359px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
  @media (max-width: $mobile-break-point) {
    width: calc(100% - 50px);
  }
}
.frame-boutons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.bouton-oui {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.texte-bouton {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.bouton-non {
  background-color: $danger-btn;
  @include btnHover($danger-btn);
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
