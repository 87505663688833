<template>
  <div>
    <div v-if="user.adminAccessGranted" class="corpsDePage">
      <section class="breadcrumb">
        <h4>Ajouter un comptage</h4>
        <div class="sousSection">
          <h5 class="titreSousSection">Numero à compter</h5>
          <div id="ajoutComptageSelectionNumeroContainer">
            <div id="#ajoutComptageSelectionNumeroPartieGauche">
              <div class="ligneFormulaire">
                <span class="labelFormulaire">Exemplaire concerné</span>
                <select
                  type="select"
                  id="selectionNumeroACompter"
                  v-on:change="definirNumeroACompter()"
                  v-model="numeroSelectionne"
                >
                  <option value="noNum"> -- </option>
                  <option
                    v-for="numero of listeNumeros"
                    :key="numero.id_numero"
                    :value="numero.id_numero"
                    >{{
                      numero.numero_numero + " - " + numero.titre_numero
                    }}</option
                  >
                </select>
              </div>
              <div class="ligneFormulaire">
                <span class="labelFormulaire">Date comptage : </span>
                <input
                  type="date"
                  v-model="dateComptage"
                  @change="chercherDernierComptageAvantDate"
                />
                <span
                  class="warning-comptage-existant infobulle"
                  v-if="dernierComptageAvantDate == -1"
                  :bulle-content="
                    'Comptage déjà existant dans le même mois\nUtilisez la fonction Modifier comptage dans le menu latéral'
                  "
                >
                  ⚠️
                </span>
              </div>
              <div
                class="ligneFormulaire"
                v-for="(lieu, i) of listeComptages"
                :key="lieu.id_lieu"
              >
                <div class="labelFormulaire">{{ lieu.nomLieuStockage }}</div>
                <input
                  type="text"
                  v-model="listeComptages[i].quantiteStockage"
                />
              </div>
            </div>
            <div id="ajoutComptageImgCouvertureContainer">
              <img
                :src="couvertureNumeroACompter"
                alt="couverture"
                id="ajoutComptageImgCouverture"
              />
            </div>
          </div>
        </div>
        <div class="sousSection">
          <h5>Recapitulatif</h5>
          <div class="details">
            <div
              class="ligneFormulaire"
              v-for="(lieu, i) of listeComptages"
              :key="lieu.id_lieu"
            >
              <div class="labelFormulaire">{{ lieu.nomLieuStockage }}</div>
              <span>{{ listeComptages[i].quantiteStockage }}</span>
            </div>
          </div>
          <div class="details totalComptage">
            <div class="ligneFormulaire">
              <div class="labelFormulaire">Dernier Comptage :</div>
              <div>{{ dernierComptageStr }}</div>
            </div>
            <div class="ligneFormulaire">
              <div class="labelFormulaire">Comptage du jour :</div>
              <div v-html="totalComptage()"></div>
            </div>
          </div>
          <div class="divTextCenter" id="frameBoutonValideComptage">
            <div
              id="boutonValiderComptage"
              class="btn btn-primary"
              @click="validerComptage"
            >
              Valider Comptage
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

const ACTIONS_TO_MAP = [
  "initDetectionModification",
  "miseAJourNumeros",
  "phpPost",
  "modaleAlert",
  "modaleOuiNon",
];

const STATES_TO_MAP = [
  "numeroVide",
  "listeNumeros",
  "listeLieux",
  "modificationPresente",
  "listeNumerosPrete",
  "listeLieuxPrete",
  "messageAlerteModification",
  "imgSelectionnerNumero",
  "messageAlerteModification",
  "user",
];

const GETTERS_TO_MAP = ["trouveNumeroParId"];

const MUTATIONS_TO_MAP = ["resetModificationPresente"];

export default {
  name: "pageAjoutComptage",
  template: "#pageAjoutComptageTemplate",
  data: () => {
    return {
      rapportErreurs: [],
      numeroACompter: undefined,
      dateComptage: "",
      listeComptages: [],
      lieuComptage: undefined,
      totalComptageVar: 0,
      numeroSelectionne: "",
      dernierComptageAvantDate: undefined,
    };
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    chercherDernierComptageAvantDate() {
      let moisComptageEnCours = this.dateComptage.split("-")[1];
      let anneeComptageEnCours = this.dateComptage.split("-")[0];
      let comptageExistantMoisChoisi = this.numeroACompter.liste_comptages.filter(
        (comptage) => {
          let moisDuComptage = comptage.date_comptage.split("-")[1];
          let anneeDuComptage = comptage.date_comptage.split("-")[0];
          return (
            moisDuComptage == moisComptageEnCours &&
            anneeDuComptage == anneeComptageEnCours
          );
        }
      );

      if (comptageExistantMoisChoisi.length > 0) {
        this.dernierComptageAvantDate = -1;
        return;
      }

      let dernierComptageAvantDate = this.numeroACompter.liste_comptages.filter(
        (comptage, index) => {
          if (index == this.numeroACompter.liste_comptages.length - 1) {
            return comptage.date_comptage < this.dateComptage;
          } else {
            return (
              this.numeroACompter.liste_comptages[index].date_comptage <
                this.dateComptage &&
              this.numeroACompter.liste_comptages[index + 1].date_comptage >
                this.dateComptage
            );
          }
        }
      );
      if (dernierComptageAvantDate.length > 0) {
        this.dernierComptageAvantDate = dernierComptageAvantDate[0];
      } else this.dernierComptageAvantDate = undefined;

      // if (this.dernierComptageAvantDate != undefined) {
      //     let moisComptageEnCours = this.dateComptage.split('-')[1]
      //     let moisDernierComptageAvantDate = this.dernierComptageAvantDate.date_comptage.split('-')[1]
      //     if (moisComptageEnCours == moisDernierComptageAvantDate) this.dernierComptageAvantDate = -1
      // }
    },
    enregistrerComptage() {
      var comptageDuJour = {
        id_numero: this.numeroACompter.id_numero,
        date_comptage: this.dateComptage,
        quantite_comptage: this.totalComptageVar,
        listeComptages: this.listeComptages,
      };

      this.phpPost({
        phpFile: "assets/php/ajouterComptage.php",
        sendObject: comptageDuJour,
        dispatchAtTheEnd: ["initialisation"],
        callBack: (data) => {
          if (data.reqSuccess) {
            this.resetModificationPresente();
          }
        },
      });
    },
    validerComptage() {
      if (this.numeroSelectionne == "noNum") {
        this.modaleAlert(
          "Aucun numéro sélectionné - veuillez choisir un numéro dans liste."
        );
        return;
      }
      if (this.dateComptage == undefined) {
        this.modaleAlert("Date non reconnue !");
        return;
      }
      if (this.dernierComptageAvantDate == -1) {
        this.modaleAlert(
          'Il existe déjà un comptage pour le mois de la date choisie. Pour le modifier, utilisez l\'onglet "Modifier comptage" dans le menu latéral.'
        );
        return;
      }

      this.modaleOuiNon({
        message: `Etes vous sûr de vouloir ajouter ce comptage ?`,
        callBackYes: (() => {
          if (!isNaN(this.totalComptageVar)) {
            this.chercherDernierComptageAvantDate();
            let validerEnregistrement = false;
            if (this.dernierComptageAvantDate != undefined) {
              if (
                this.totalComptageVar >
                this.dernierComptageAvantDate.quantite_comptage
              ) {
                this.modaleOuiNon({
                  message:
                    "<h4>Attention !</h4><br>Le stock déclaré est supérieur au stock précédent - êtes-vous sûr de vouloir ajouter le comptage ? (Si oui, pensez à vérifier les valeurs et les impressions une fois le comptage ajouté)",
                  callBackYes: () => {
                    this.enregistrerComptage();
                  },
                });
              } else validerEnregistrement = true;
            } else validerEnregistrement = true;

            if (validerEnregistrement) this.enregistrerComptage();
          } else
            this.modaleAlert(
              "Certains champs sont mal remplis - veillez à ne mettre que des nombres"
            );
        }).bind(this),
      });
    },
    definirNumeroACompter() {
      if (this.modificationPresente)
        if (!confirm(this.messageAlerteModification)) {
          this.numeroSelectionne = this.numeroACompter.id_numero;
          return;
        }

      this.resetModificationPresente();
      this.numeroACompter = this.trouveNumeroParId(this.numeroSelectionne);
      if (this.numeroSelectionne != "noNum")
        this.$router.push("/ajoutcomptage-" + this.numeroSelectionne);
      else this.$router.push("/ajoutcomptage--");
    },
    messageClassMethod(success) {
      if (success) return "alert alert-success";
      else return "alert alert-danger";
    },
    initPage() {
      window.scrollTo(0, 0);
      this.dateComptage = this.today;
      this.listeComptages = [];
      for (var lieu of this.listeLieux) {
        this.listeComptages.push({
          idLieuStockage: lieu.id_lieu,
          nomLieuStockage: lieu.nom_lieu,
          quantiteStockage: 0,
        });
      }
      if (this.$route.params.id != "-" && this.$route.params.id != "noNum") {
        this.numeroACompter = this.trouveNumeroParId(this.$route.params.id);
        this.numeroSelectionne = this.$route.params.id;
      } else {
        this.numeroACompter = JSON.parse(JSON.stringify(this.numeroVide));
        this.numeroACompter.couverture_numero = this.imgSelectionnerNumero;
        this.numeroSelectionne = "noNum";
      }
      this.$nextTick(() => {
        this.initDetectionModification();
      });
    },
    totalComptage() {
      this.totalComptageVar = this.listeComptages.reduce(
        (sousTotal, comptage) => {
          return (
            sousTotal +
            parseInt(
              comptage.quantiteStockage == "" ? "0" : comptage.quantiteStockage
            )
          );
        },
        0
      );
      // var precedentComptage = 2940;
      if (this.dernierComptageAvantDate == undefined)
        return this.totalComptageVar;
      if (this.dernierComptageAvantDate == -1)
        return "Comptage déjà existant pour le mois choisi<br>Utilisez l'onglet 'Modifier comptage' pour le modifier";
      // if (this.numeroACompter != undefined && this.numeroACompter.liste_comptages!= undefined && this.numeroACompter.liste_comptages.length != 0)
      if (
        this.numeroACompter == undefined ||
        this.numeroACompter.liste_comptages == undefined
      )
        return;
      let precedentComptage = parseInt(
        this.dernierComptageAvantDate.quantite_comptage
      );
      return isNaN(this.totalComptageVar)
        ? "Certains champs ne sont pas remplis avec des nombres"
        : this.totalComptageVar +
            " (différence avec le précédent comptage : " +
            (precedentComptage - this.totalComptageVar) +
            ")";
    },
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
    couvertureNumeroACompter() {
      if (this.numeroACompter == undefined) return this.imgSelectionnerNumero;
      return this.numeroACompter.couverture_numero;
    },
    dernierComptageStr() {
      if (this.numeroACompter == undefined) return "Aucun numero sélectionné";
      if (
        this.numeroACompter.liste_comptages == undefined ||
        this.numeroACompter.liste_comptages.length == 0
      )
        return "Aucun comptage pour ce numéro";
      if (this.dernierComptageAvantDate == undefined)
        return "Aucun comptage trouvé avant la date choisie";
      if (this.dernierComptageAvantDate == -1) return "--";
      var cptage = this.dernierComptageAvantDate;
      // var cptage = this.numeroACompter.liste_comptages[this.numeroACompter.liste_comptages.length - 1];
      return (
        cptage.quantite_comptage +
        " exemplaires - " +
        cptage.date_comptage
          .split("-")
          .reverse()
          .join("/")
      );
    },
  },
  watch: {
    $route(from, to) {
      this.initPage();
    },
    listeLieuxPrete() {
      if (this.listeNumerosPrete) this.initPage();
    },
    listeNumerosPrete() {
      if (this.listeLieuxPrete) this.initPage();
    },
  },
  mounted: function() {
    this.rapportErreurs = [];
    if (this.listeNumerosPrete && this.listeLieuxPrete) this.initPage();
  },
};
</script>

<style>
#ajoutComptageSelectionNumeroContainer {
  display: flex;
  justify-content: space-around;
}

#selectionNumeroACompter {
  max-width: 250px;
}

#ajoutComptageImgCouverture {
  width: 150px;
}

.warning-comptage-existant {
  margin-bottom: 5px;
  margin-left: 5px;
}
.ajoutComptageDetailsElement {
  margin-right: 20px;
}

#boutonAjouterComptage {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.itemComptage {
  margin-left: 50px;
}

#frameBoutonValideComptage {
  margin-top: 40px;
}
</style>
