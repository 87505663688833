<template>
  <div class="composant-lieu">
    <div class="infos-lieu">
      <LabelEditable class="nom-lieu" v-model = "nomLieu" :key="'nomLieu-' + key" />
      <LabelEditable class="adresse-lieu" v-model = "adresseLieu" :key="'adresseLieu-' + key"/>
    </div>
    <div class="separateur"></div>
    <BarreProgression :pourcentage="pourcentage" labelPos="right" width='150px' class='barreProgression' />
    <div class="separateur"></div>
    <p class="quantite-lieu">{{ quantiteLieu }}</p>
    <div class="separateur"></div>
    <div class="bouton-fiche-lieu element-clickable">
      <img
        alt="fiche lieu"
        src="assets/icones/icone_fiche_lieu.png"
        @click="$emit('click', {type: 'fiche', indexLieu: index})"
      />
    </div>
    <div class="separateur"></div>
    <div class="bouton-fiche-lieu element-clickable bouton-supprimer-lieu">
      <img 
        src="assets/icones/iconeSupprimer.png" 
        alt="supprimer"
        title="Supprimer lieu"
        @click="supprimerLieu"
      >
    </div>
  </div>
</template>

<script>
import BarreProgression from './barreProgression'
import { mapActions, mapMutations, mapState } from 'vuex'

const ACTIONS_TO_MAP = [
  'phpPost',
  'modaleAlert',
  'modaleOuiNon'
]

const MUTATIONS_TO_MAP = [
  'supprLieu'
]

const STATES_TO_MAP = [
  'userConnected'
]

export default {
  name: "ComposantLieu",
  components: { BarreProgression },
  data: () => {
    return {
      myBar: undefined,
      lieuAvantModifications: undefined,
      key: 0
    }
  },
  props: {
    lieu: undefined,
    index: { type : Number, default: -1 }
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    quantiteLieu() { 
      return this.lieu.quantite_lieu
    },
    nomLieu: {
      get: function () {
        return this.lieu.nom_lieu
      },
      set: function (nouveauNom) {
        this.phpPost({
          sendObject: this.lieu,
          modifications: { nom_lieu: nouveauNom },
          phpFile: 'assets/php/modifierLieu.php',
          dispatchAtTheEnd: 'miseAJourLieux',
          callBack: ()=>{
            this.key += 1
          }
        })
      }
    },
    adresseLieu: {
      get: function () {
        return this.lieu.adresse_lieu
      },
      set: function (nouvelleAdresse) {
        this.phpPost({
          sendObject: this.lieu,
          modifications: { adresse_lieu: nouvelleAdresse },
          phpFile: 'assets/php/modifierLieu.php',
          dispatchAtTheEnd: 'miseAJourLieux',
          callBack: ()=>{
            this.key += 1
          }
        })
      },
    },
    pourcentage() {
      return Math.round(this.lieu.quantite_lieu / this.lieu.capacite_lieu * 100) <= 0 ? 0 : Math.round(this.lieu.quantite_lieu / this.lieu.capacite_lieu * 100);
    }
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    supprimerLieu(){
      if (parseInt(this.lieu.quantite_lieu) != 0) {
        return this.modaleAlert('Impossible de supprimer un lieu qui n\'est pas vide ! Vider le lieu en déclarant un transfert avant de le supprimer')
      }
      
      this.modaleOuiNon({
        message: 'Êtes-vous sûr de vouloir supprimer le lieu ' + this.lieu.nom_lieu + ' ?',
        callBackYes: ()=>{ // si oui
          this.phpPost({
            phpFile: 'assets/php/supprimerLieu.php',
            sendObject: { idLieu: this.lieu.id_lieu },
            dispatchAtTheEnd: 'miseAJourLieux'
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-lieu {
  margin: 3px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 40px;
  // padding: 7px 30px;
  box-shadow: 0 4px 4px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $couleur-texte-fonce;
  // width: auto;
}

  .infos-lieu {
  border-radius: 34px;
  padding: 10px 10px 10px 25px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  width: 395px;
}
  .nom-lieu {
    width: 288px;
    @include typo-medium;
    color: $couleur-texte-fonce;
    margin-bottom: 4px;
  }

  .adresse-lieu {
    width: 259px;
    @include typo-small;
    color: $couleur-texte-fonce;
  }
  .separateur {
    width: 1px;
    height: 48px;
    background-color: $couleur-texte-clair;
  }
  .quantite-lieu {
    width: 72px;
    @include typo-medium;
    color: $couleur-texte-fonce;
    text-align: center;
  }
  .bouton-fiche-lieu {
    border-radius: 15px;
    padding: 3px;
    display: flex;
    justify-content: center;
  }
  .bouton-supprimer-lieu{
    margin-left: 10px;
  }
</style>
