<template>
  <!-- Composant saisi dans Coda -->
  <div
    class="composant-ajout-versement"
    @keypress.enter="ajouterVersement()"
    @keyup.escape="definirVisibiliteModale(false)"
  >
    <p class="titre">Ajouter un versement</p>
    <div class="separateur1"></div>
    <div class="frame-titres-liste-versements">
      <p class="montant-versement">Numero facture*</p>
      <input
        class="montant"
        placeholder="Numero"
        v-model="detailsVersement.numeroFacture"
      />
    </div>
    <div class="frame-titres-liste-versements">
      <p class="montant-versement">Montant du versement*</p>
      <input
        ref="focusElement"
        class="montant"
        placeholder="Montant"
        v-model="detailsVersement.montantVersement"
      />
    </div>
    <div class="frame-titres-liste-versements">
      <p class="montant-versement">Montant du précompte</p>
      <input
        class="montant"
        placeholder="Montant"
        v-model="detailsVersement.precompteVersement"
      />
    </div>
    <div class="frame-titres-liste-versements">
      <p class="montant-versement">Date du versement*</p>
      <input
        type="date"
        class="montant"
        placeholder="Date"
        v-model="detailsVersement.dateVersement"
      />
    </div>
    <div class="frame-fichier-facture">
      <p class="label-fichier-facture">
        Choisissez un fichier pour la facture:
      </p>
      <input
        class="input-file"
        ref="inputFile"
        type="file"
        @change="uploadCheck"
      />
    </div>
    <div class="frame-boutons">
      <div class="frame-ajouter" @click="ajouterVersement()">
        <p class="bouton-ajouter-button">Ajouter</p>
      </div>
      <div class="frame-annuler" @click="definirVisibiliteModale(false)">
        <p class="bouton-ajouter-button">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { verifierClesVideObjet, testDate } from "../js/fonctionsGlobales";

const MUTATIONS_TO_MAP = ["definirVisibiliteModale", "definirContenuModale"];

const ACTIONS_TO_MAP = ["phpPost", "modaleAlert"];

export default {
  name: "ComposantAjoutVersement",
  props: {
    idCompteDroits: { default: undefined },
  },
  data: function() {
    return {
      detailsVersement: {
        idCompteDroits: this.idCompteDroits,
        dateVersement: "",
        montantVersement: 0,
        precompteVersement: 0,
        numeroFacture: "",
      },
    };
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    uploadCheck() {
      var facture = this.$refs.inputFile.files[0];
      if (facture == undefined) return;
      var typesFichierUploadFacture = ["PDF"];
      if (facture.size > 1000000) {
        this.modaleAlert("Fichier trop volumineux, maximum : 1Mo");
        this.$refs.inputFile.type = "text";
        this.$refs.inputFile.type = "file";
      } else if (
        facture.type == "" ||
        typesFichierUploadFacture.indexOf(
          facture.type.split("/")[1].toUpperCase()
        ) == -1
      ) {
        this.modaleAlert(
          "Format de fichier non pris en charge - fichier accepté : pdf"
        );
        this.$refs.inputFile.type = "text";
        this.$refs.inputFile.type = "file";
      }
    },
    ajouterVersement() {
      if (this.idCompteDroits == undefined) {
        this.modaleAlert("Une erreur est survenue (idCompteDroits undefined)");
      }

      if (
        this.detailsVersement.dateVersement == "" ||
        this.detailsVersement.montantVersement == "" ||
        this.detailsVersement.numeroFacture == ""
      ) {
        this.modaleAlert("Les champs marqués d'une * sont obligatoires");
        return;
      }

      this.detailsVersement.montantVersement = parseFloat(
        this.detailsVersement.montantVersement
      );
      this.detailsVersement.precompteVersement = parseFloat(
        this.detailsVersement.precompteVersement
      );

      if (isNaN(this.detailsVersement.precompteVersement)) {
        this.modaleAlert("Le précompte entré n'est pas un nombre");
        this.detailsVersement.precompteVersement = 0;
      }
      if (isNaN(this.detailsVersement.montantVersement)) {
        this.modaleAlert("Le montant de versement entré n'est pas un nombre");
        this.detailsVersement.montantVersement = 0;
        return;
      }
      if (!testDate(this.detailsVersement.dateVersement)) {
        this.modaleAlert({
          message: "format de date incorrect",
        });
        return;
      }

      var facture = this.$refs.inputFile.files[0];
      // if (facture == undefined) return this.modaleAlert('Aucun fichier choisi')

      var formData = new FormData();
      formData.append("file", facture);
      // formData.append("nom", 'Matthieu');
      formData.append(
        "detailsVersement",
        JSON.stringify(this.detailsVersement)
      );

      this.phpPost({
        phpFile: "./assets/php/ajouterVersement.php",
        sendObject: formData,
        postType: "ajax",
        dispatchAtTheEnd: "miseAJourContributeurs",
        callBack: (response) => {
          if (response.reqSuccess) this.definirVisibiliteModale(false);
        },
      });
    },
  },
  mounted: function() {
    this.$refs.focusElement.focus();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-ajout-versement {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 24px 23px 23px 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-titres-liste-versements {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.montant-versement {
  width: 250px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-right: 26px;
}
.montant {
  width: 150px;
  @include typo-medium;
  color: $couleur-texte-fonce;
}
.frame-fichier-facture {
  text-align: left;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 30px;
  & .label-fichier-facture {
    @include typo-medium;
    color: $couleur-texte-fonce;
    margin-bottom: 20px;
  }
  & input {
    width: 100%;
    @include typo-medium;
    color: $couleur-texte-fonce;
    margin: 0;
    margin-left: 30px;
  }
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
  width: 244px;
}

.frame-ajouter {
  @include btnHover($neutral-btn);
  background-color: $neutral-btn;

  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.bouton-ajouter-button {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}

.frame-annuler {
  background-color: $danger-btn;
  @include btnHover($danger-btn);

  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>
