<template>
  <div>
    <div v-if="user.adminAccessGranted" class="corpsDePage">
      <section class="breadcrumb">
        <h4>Modifier un comptage</h4>
        <div class="sousSection">
          <h5 class="titreSousSection">Numero à compter</h5>
          <div
            class="message-cloture-festival"
            v-if="
              comptageAModifier != undefined &&
                comptageAModifier.id_festival != undefined
            "
          >
            <span class="icone-info">🛈</span> Ce comptage a été ajouté
            automatiquement suite à la clôture du festival "{{
              infosFestival.nom_festival
            }}". Il est déconseillé de le modifier / supprimer manuellement
          </div>
          <div id="ajoutComptageSelectionNumeroContainer">
            <div id="ajoutComptageSelectionNumeroPartieGauche">
              <div class="ligneFormulaire">
                <span class="labelFormulaire">Exemplaire concerné</span>
                <select
                  type="select"
                  id="selectionNumeroACompter"
                  v-on:change="definirNumeroACompter()"
                  v-model="idNumeroSelectionne"
                >
                  <option value="-"> -- </option>
                  <option
                    v-for="numero of listeNumeros"
                    :key="numero.id_numero"
                    :value="numero.id_numero"
                    >{{
                      numero.numero_numero + " - " + numero.titre_numero
                    }}</option
                  >
                </select>
              </div>
              <div v-if="numeroACompter != undefined" class="ligneFormulaire">
                <span class="labelFormulaire">Date comptage : </span>
                <select
                  type="select"
                  id="selectionComptageAModifier"
                  v-on:change="
                    () => {
                      this.$router.push(
                        '/modifcomptage-' +
                          this.idNumeroSelectionne +
                          '/' +
                          this.idComptageSelectionne
                      );
                    }
                  "
                  v-model="idComptageSelectionne"
                >
                  <option v-if="listeComptages == undefined" value="noCpt"
                    >Aucun Comptage</option
                  >
                  <option
                    v-for="comptage of listeComptages"
                    :key="comptage.id_comptage"
                    :value="comptage.id_comptage"
                    >{{ dateFrancaise(comptage.date_comptage) }}</option
                  >
                </select>
              </div>
              <div
                v-if="
                  numeroACompter != undefined &&
                    !numeroACompter.liste_comptages[0].aucunComptage
                "
              >
                <div>
                  <div v-if="numeroACompter.estUnPack" class="ligneFormulaire">
                    <div class="labelFormulaire"></div>
                    <div class="type-coffrets">Coffrets pleins</div>
                    <div class="type-coffrets">Coffrets vides</div>
                  </div>
                  <div
                    v-for="(lieu, i) of listeStockages"
                    :key="i"
                    class="ligneFormulaire"
                  >
                    <div class="labelFormulaire">{{ lieu.nom_lieu }}</div>
                    <input
                      class="inputComptage"
                      :class="numeroACompter.estUnPack ? 'small-input' : ''"
                      type="text"
                      v-model="listeStockages[i].quantite_stockage"
                      @input="calculTotalComptage"
                    />
                    <div
                      v-if="listeStockagesValeursOriginales != undefined"
                      class="valeurs-avant-modif"
                    >
                      (Avant modif :
                      {{
                        listeStockagesValeursOriginales[i].quantite_stockage
                      }})
                    </div>

                    <input
                      v-if="numeroACompter.estUnPack"
                      class="inputComptage"
                      :class="numeroACompter.estUnPack ? 'small-input' : ''"
                      type="text"
                      v-model="listeStockages[i].quantite_vide_stockage"
                      @input="calculTotalComptage"
                    />
                    <div
                      v-if="listeStockagesValeursOriginales != undefined && numeroACompter.estUnPack"
                      class="valeurs-avant-modif"
                    >
                      (Avant modif :
                      {{
                        listeStockagesValeursOriginales[i].quantite_vide_stockage
                      }})
                    </div>
                  </div>
                </div>
                <div
                  class="ligneFormulaire totalComptage"
                  v-if="
                    comptageAModifier != undefined &&
                      numeroACompter != undefined
                  "
                >
                  <div class="labelFormulaire">Total</div>
                  <input
                    disabled
                    type="text"
                    v-model="comptageAModifier.quantite_comptage"
                    class="inputComptage"
                    :class="numeroACompter.estUnPack ? 'small-input' : ''"
                  />
                  <!-- <div class="labelFormulaire">{{ comptageAModifier.quantite_comptage }}</div> -->
                  <div>
                    (Avant modif : {{ quantiteComptageValeurOriginale }})
                  </div>
                </div>
              </div>
            </div>
            <!-- <div id='ajoutComptageImgCouvertureContainer'>
                            <img :src="couvertureNumeroACompter()"  alt="couverture" id="ajoutComptageImgCouverture">
                        </div> -->
          </div>
          <div
            class="dflex centered space-around"
            style="width: 400px; margin-top: 20px"
          >
            <div
              class="btn btn-primary"
              id="btnValiderModifComptage"
              @click="validerModificationsComptage"
            >
              Valider Modifications
            </div>
            <div
              class="btn btn-danger"
              id="btnSupprimerComptage"
              @click="supprimerComptage"
            >
              Supprimer Comptage
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { dateFrancaise } from "../js/fonctionsGlobales";

const ACTIONS_TO_MAP = [
  "initDetectionModification",
  "miseAJourNumeros",
  "phpPost",
  "modaleOuiNon",
  "modaleAlert",
];

const MUTATIONS_TO_MAP = [
  "resetModificationPresente",
  "resetListeNumerosPrete",
];

const STATES_TO_MAP = [
  "listeNumeros",
  "listeLieux",
  "imgSelectionnerNumero",
  "listeNumerosPrete",
  "listeLieuxPrete",
  "modificationPresente",
  "messageAlerteModification",
  "user",
];

const GETTERS_TO_MAP = ["trouveNumeroParId"];

export default {
  name: "pageModifComptage",
  template: "#pageModifComptageTemplate",
  data: () => {
    return {
      infosFestival: undefined,
      rapportErreurs: [],
      detailsComptage: undefined,
      comptageAModifier: undefined,
      listeComptages: [],
      listeStockages: [],
      lieuComptage: undefined,
      idNumeroSelectionne: "-",
      idComptageSelectionne: undefined,
      numeroACompter: undefined,
      totalComptageVar: 0,
      quantiteComptageValeurOriginale: undefined,
      listeStockagesValeursOriginales: undefined,
    };
  },
  methods: {
    dateFrancaise(date) {
      return dateFrancaise(date);
    },
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    messageClassMethod(success) {
      if (success) return "alert alert-success";
      else return "alert alert-danger";
    },
    calculTotalComptage() {
      this.comptageAModifier.quantite_comptage = 0;
      for (var s of this.listeStockages)
        this.comptageAModifier.quantite_comptage +=
          s.quantite_stockage == null || s.quantite_stockage == ""
            ? 0
            : parseInt(s.quantite_stockage);
      if (isNaN(this.comptageAModifier.quantite_comptage))
        this.comptageAModifier.quantite_comptage = 0;
    },
    supprimerComptage() {
      if (this.idNumeroSelectionne == "-")
        return this.modaleAlert("Aucun numéro sélectionné");
      if (this.idComptageSelectionne == "noCpt")
        return this.modaleAlert("Aucun comptage sélectionné");
      this.modaleOuiNon({
        message:
          "Êtes-vous sûr de vouloir supprimer ce comptage ?<br>Note : s'il s'agit du comptage le plus récent, les droits des contributeurs seront recalculés",
        callBackYes: () => {
          //si Oui
          this.phpPost({
            phpFile: "./assets/php/supprimerComptage.php",
            dispatchAtTheEnd: "initialisation",
            sendObject: {
              idComptage: this.comptageAModifier.id_comptage,
              idNumero: this.numeroACompter.id_numero,
            },
            callBack: (data) => {
              if (data.reqSuccess) this.idComptageSelectionne = "noCpt";
            },
          });
        },
      });
    },
    validerModificationsComptage() {
      if (this.idNumeroSelectionne == "-")
        return this.modaleAlert("Aucun numéro sélectionné");
      if (this.idComptageSelectionne == "noCpt")
        return this.modaleAlert("Aucun comptage sélectionné");
      for (var stockage of this.listeStockages) {
        if (isNaN(stockage.quantite_stockage)) {
          return this.modaleAlert(
            "La quantité entrée pour le stockage " +
              stockage.nom_lieu +
              " n'est pas un nombre"
          );
        }
      }
      var comptageEtStockages = {
        comptage: {
          id_comptage: this.comptageAModifier.id_comptage,
          id_numero: this.numeroACompter.id_numero,
          quantite_comptage: this.comptageAModifier.quantite_comptage,
        },
        stockages: this.listeStockages,
      };
      console.log('comptageEtStockages', comptageEtStockages)
      this.phpPost({
        sendObject: comptageEtStockages,
        phpFile: "./assets/php/modifierComptage.php",
        dispatchAtTheEnd: [
          "miseAJourNumeros",
          "miseAJourContributeurs",
          "miseAJourLieux",
        ],
        callBack: () => {
          this.resetModificationPresente();
          this.initPage();
        },
      });
    },
    couvertureNumeroACompter() {
      if (this.numeroACompter == undefined) return this.imgSelectionnerNumero;
      return this.numeroACompter.couverture_numero;
    },
    definirNumeroACompter() {
      if (this.modificationPresente) {
        if (confirm(this.messageAlerteModification)) {
          this.resetModificationPresente();
          this.rapportErreurs = [];
          this.numeroACompter = this.trouveNumeroParId(
            this.idNumeroSelectionne
          );
          this.$router.push(
            "/modifcomptage-" + this.idNumeroSelectionne + "/-"
          );
          this.idComptageSelectionne = "noCpt";
        } else {
          this.idNumeroSelectionne = this.numeroACompter.id_numero;
        }
      } else {
        this.rapportErreurs = [];
        this.numeroACompter = this.trouveNumeroParId(this.idNumeroSelectionne);
        this.$router.push("/modifcomptage-" + this.idNumeroSelectionne + "/-");
        if (this.numeroACompter.liste_comptages != undefined)
          this.idComptageSelectionne = this.numeroACompter.liste_comptages[
            this.numeroACompter.liste_comptages.length - 1
          ].id_comptage;
        else this.idComptageSelectionne = "noCpt";
      }
    },
    definirComptageAModifier() {
      if (
        this.idComptageSelectionne == "noCpt" ||
        this.idComptageSelectionne == "-" ||
        this.idComptageSelectionne == undefined
      ) {
        this.comptageAModifier = this.listeComptages[0];
        if (this.comptageAModifier == undefined) return;
        this.idComptageSelectionne = this.comptageAModifier.id_comptage;
      }

      if (this.modificationPresente) {
        if (!confirm(this.messageAlerteModification)) {
          this.idComptageSelectionne = this.comptageAModifier.id_comptage;
          return;
        }
      }

      this.resetModificationPresente();
      this.comptageAModifier = this.listeComptages.filter((cpt) => {
        return cpt.id_comptage == this.idComptageSelectionne;
      })[0];
      this.phpPost({
        phpFile: "./assets/php/detailsComptages.php",
        sendObject: this.comptageAModifier,
        disableThumbnail: true,
        callBack: (data) => {
          if (data.reqSuccess) {
            if (data.hasWarning) {
              console.warn(data.reqType + " : " + data.reqMessage);
            } else {
              this.listeStockages = data.listeStockages;
              this.comptageAModifier.quantite_comptage = data.stockTotal;
              this.quantiteComptageValeurOriginale = this.comptageAModifier.quantite_comptage;
              this.listeStockagesValeursOriginales = JSON.parse(
                JSON.stringify(this.listeStockages)
              );
              this.infosFestival = data.infosFestival;
            }
          } else {
            this.listeStockages = [];
            this.listeStockagesValeursOriginales = [];
          }
          this.quantiteComptageValeurOriginale = this.comptageAModifier.quantite_comptage;
        },
      });
    },
    initPage() {
      this.rapportErreurs = [];
      window.scrollTo(0, 0);
      if (this.listeNumerosPrete && this.listeLieuxPrete) {
        this.listeStockages = [];
        this.idNumeroSelectionne = this.$route.params.id;
        if (this.$route.params.cpt)
          this.idComptageSelectionne = this.$route.params.cpt;
        else this.idComptageSelectionne = "noCpt";

        if (this.idNumeroSelectionne != "-") {
          this.numeroACompter = this.trouveNumeroParId(this.$route.params.id);
        } else {
          this.numeroACompter = undefined;
        }

        if (this.numeroACompter != undefined) {
          this.listeComptages = this.numeroACompter.liste_comptages;
          if (this.listeComptages == undefined) this.liste_comptage = [];
          // this.listeComptages = JSON.parse(JSON.stringify(this.numeroACompter.liste_comptages));
          this.listeComptages.reverse();
        }

        for (var lieu of this.listeLieux) {
          this.listeStockages.push({
            idLieuStockage: lieu.id_lieu,
            nomLieuStockage: lieu.nom_lieu,
            quantiteStockage: 0,
          });
        }
        this.definirComptageAModifier();
        this.$nextTick(() => {
          this.initDetectionModification();
        });
      }
    },
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
  },
  watch: {
    $route(from, to) {
      this.initPage();
    },
    listeNumerosPrete() {
      this.initPage();
    },
    listeLieuxPrete() {
      this.initPage();
    },
  },
  mounted: function() {
    this.initPage();
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";

.valeurs-avant-modif {
  font-size: 0.8em;
  width: 110px;
}
.message-cloture-festival {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  @include typo-medium;
  background-color: $couleur-fond-menu-navigation;
  padding: 10px 10px;
  //   width: 700px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}
.icone-info {
  font-size: 1.8em;
  position: relative;
  bottom: 2px;
  margin-right: 10px;
}

.small-input {
  width: 100px!important;
}

.type-coffrets {
  width: 200px;
  // text-align: center;
}
</style>
