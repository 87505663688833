<template>
<!-- Composant saisi dans Coda -->
  <div class="composant-ajout-mission">
    <p class="titre">Ajouter une mission</p>
    <div class="separateur1"></div>
    <div class="frame-intitule-mission">
      <p class="label-intitule-mission">
        Intitulé de mission*
      </p>
      <input
        class="frame-intitule-mission-input"
        placeholder="Intitulé"
        v-model="nouvelleMission.type_mission"
      />
    </div>
    <div class="frame-intitule-mission">
      <p class="label-intitule-mission">Description</p>
      <textarea
        class="description-mission"
        placeholder="Description"
        v-model="nouvelleMission.description_mission"
      ></textarea>
    </div>
    <div class="frame-intitule-mission">
      <p class="label-intitule-mission">
        Montant de la mission*
      </p>
      <input
        class="frame-intitule-mission-input"
        placeholder="Montant"
        v-model="nouvelleMission.montant_mission"
      />
    </div>

    <div class="frame-intitule-mission">
      <p class="label-intitule-mission">
        Date de la mission*
      </p>
      <input 
        class="date-mission" 
        type='date'
        v-model="nouvelleMission.date_mission"
      />
    </div>
    <div class="frame-intitule-mission">
      <p class="label-intitule-mission">Date de paiement</p>
      <input 
        class="date-mission" 
        type="date"
        v-model="nouvelleMission.date_paiement_mission"
      />
    </div>
    <div class="frame-boutons">
      <div class="frame-ajouter">
        <p class="bouton-ajouter-button" @click="ajouterMission">Ajouter</p>
      </div>
      <div class="frame-annuler">
        <p class="bouton-ajouter-button" @click="definirVisibiliteModale(false)">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { verifierClesVideObjet } from '../js/fonctionsGlobales';
import { testDate } from '../js/fonctionsGlobales'

const ACTIONS_TO_MAP = [
  'phpPost',
  "modaleAlert"
]

const MUTATIONS_TO_MAP = [
  'definirVisibiliteModale'
]

export default {
  name: "ComposantAjoutMission",
  props: {
    id_contributeur: {default: undefined}
  },
  data: function () {
    return {
      nouvelleMission: {
        type_mission: "",
        description_mission: "",
        montant_mission: "",
        date_mission: "",
        date_paiement_mission: "",
        id_contributeur: this.id_contributeur,
        paiement_effectue_mission: false
      }
    }
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    ajouterMission() {
      if (!verifierClesVideObjet(this.nouvelleMission, ['type_mission', 'montant_mission','date_mission'])) 
         return this.modaleAlert("Les champs munis d'une * doivent être remplis");
      
      var montantTmp = parseFloat(this.nouvelleMission.montant_mission)
      if (isNaN(montantTmp)) return this.modaleAlert("Le montant doit être un nombre")
      else this.nouvelleMission.montant_mission = montantTmp

      if (!testDate(this.nouvelleMission.date_mission)) return this.modaleAlert('Date mission : format de date incorrect')
      if (this.nouvelleMission.date_paiement_mission!='') 
          if (!testDate(this.nouvelleMission.date_paiement_mission)) return this.modaleAlert('Date paiement : format de date incorrect')
          else this.nouvelleMission.paiement_effectue_mission = true

      this.phpPost({
        phpFile: './assets/php/ajouterMission.php', 
        sendObject: this.nouvelleMission, 
        dispatchAtTheEnd: 'miseAJourContributeurs',
        callBack: (response) => {
            if (response.reqSuccess) this.definirVisibiliteModale(false);
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-ajout-mission {
  max-height: 550px;
  border-radius: 10px;
  padding: 24px 23px 23px 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.titre {
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-intitule-mission {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-intitule-mission {
  width: 250px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-right: 26px;
}
.frame-intitule-mission-input {
  width: 250px;
  padding: 2px 2px;
  @include typo-medium;
  color: $couleur-texte-fonce;
}
.description-mission {
  width: 250px;
  @include typo-medium;
  color: $couleur-texte-fonce;
}
.date-mission {
  width: 250px;
  margin: 6px;
  @include typo-medium;
  color: $couleur-texte-fonce;
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
  width: 244px;
}
.frame-ajouter {
   cursor: pointer;
   &:hover {
      background-color: darken($neutral-btn, $darken-amount);
   }
  background-color: $neutral-btn;
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.bouton-ajouter-button {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.frame-annuler {
   cursor: pointer;
   &:hover {
      background-color: darken($danger-btn, $darken-amount);
   }
  background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>