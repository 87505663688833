<template>
  <div v-if='user.adminAccessGranted' class="page-modif-contributeur">
    <div class="frame-titre">
      <p class="label-titre">Supprimer contributeur</p>
    </div>
    <div class="frame-contributeur-a-supprimer" style="width: 100%;">
      <div class="label-contributeur-a-supprimer">Contributeur à supprimer : </div>
      <select v-model="idContributeur" @change="definirContributeurAAfficher">
        <option value="-">Choisir un contributeur</option>
        <option 
          v-for='contributeur of listeContributeurs' 
          :key="contributeur.id_contributeur" 
          :value="contributeur.id_contributeur"
        >{{  contributeur.nom_contributeur + ' ' + contributeur.prenom_contributeur }}</option>
      </select>
    </div>
    <div v-if="idContributeur!='-'" class="frame-infos">
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contributeur</p>
          <div class="frame-nom">
            <p class="label-two">Nom</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.nom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Prenom</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.prenom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Nom d'artiste</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.nom_artiste_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Date naissance</p>
            <LabelEditable locked=true format=date v-model="contributeurAAfficher.date_naissance_contributeur"
              class="nom-contributeur date-naissance"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Numero Secu</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.numero_secu_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-siret">
            <p class="label-two">Siret</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.siret_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Adresse</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Libellé</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.adresse_contributeur.libelle_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Infos comp.</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.adresse_contributeur.infos_complementaires_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Code postal</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.adresse_contributeur.code_postal_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Ville</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.adresse_contributeur.ville_adresse"
              class="nom-contributeur"
            />
          </div>
        </div>
      </div>
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contacts</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Site web</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.site_web_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Email</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.email_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Tel</p>
            <LabelEditable locked=true v-model="contributeurAAfficher.tel_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Compte auteur</p>
          <div class="frame-libelle-adresse">
            <div class="frame-label-identifiant">
              <p class="label-three">
                Identifiant connexion
              </p>
            </div>
            <div class='frame-label-editable-identifiant'>
              <LabelEditable locked=true v-model="contributeurAAfficher.identifiant" class="identifiant-contributeur" />
            </div>
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Accreditation</p>
            <LabelEditable name="accreditation-contributeur" v-model="niveauAccesContributeur" />
          </div>
        </div>
      </div>
      <div class="frame-boutons">
        <div class="frame-annuler" @click="supprimerContributeur">
          <p class="bouton-label">Supprimer</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>

</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
export default {
    name: "ComposantSupprimerContributeur",
    data:() => {
        return {
            idContributeur: "-",
            contributeurAAfficher: undefined,
            contributeurAvantModification: undefined,
            listeAcces: [],
            existenceCompteAuteur: false,
            idFree: false,
            contributeurVide: {
                nom_contributeur: '',
                prenom_contributeur: '',
                date_naissance_contributeur: '',
                email_contributeur: '',
                tel_contributeur: '',
                adresse_contributeur: {
                    ville_adresse: '',
                    code_postal_adresse: '',
                    libelle_adresse: '',
                    infos_complementaires_adresse: '',
                },
                identifiant: '',
                id_acces: "-"
            },
            nouvelIdentifiant: "",
            password:"",
            emojiDisponibiliteId: "❌",
            methodeCreationPassword: "auto",
            messageValidationPassword: "Le mot de passe doit contenir au moins une majuscule | une minuscule | un chiffre | un caractère spécial (!@#$&*)",
        }
    },
    computed: {
        ...mapState(['user', 'userConnected', 'listeContributeurs', 'modificationPresente']),
        ...mapGetters(['trouveContributeurParId']),
        niveauAccesContributeur() {
          let acces = this.listeAcces.find((acces) => { return acces.id_acces==this.contributeurAAfficher.id_acces})
          return acces.libelle_acces
        },
        nomCompletContributeur() {
          return this.contributeurAAfficher.nom_contributeur + ' ' + this.contributeurAAfficher.prenom_contributeur
        }
    },
    methods: {
        ...mapActions(['phpPost', 'modaleAlert','modaleOuiNon', 'initDetectionModification']),
        ...mapMutations(['masquerModale', 'resetModificationPresente', 'marquerModificationPresente']),
        definirContributeurAAfficher() {
          if (this.idContributeur != '-') { 
            this.contributeurAAfficher = Object.assign({}, this.trouveContributeurParId(this.idContributeur))
            this.phpPost({
              phpFile: "assets/php/obtenirIdentifiant.php",
              sendObject: { id_contributeur: this.idContributeur },
              disableThumbnail: true,
              callBack: (response) => {
                if (response.reqSuccess) this.contributeurAAfficher.identifiant = response.identifiant
                else this.contributeurAAfficher.identifiant = ""
                this.existenceCompteAuteur = this.contributeurAAfficher.identifiant != ""
                this.$forceUpdate()
              }
            })
          } else {
            this.contributeurAAfficher = this.contributeurVide
            this.nouvelIdentifiant = ""
          }
          if (this.contributeurAAfficher.adresse_contributeur == undefined) this.contributeurAAfficher.adresse_contributeur = this.contributeurVide.adresse_contributeur
        },
        supprimerContributeur() {
         this.modaleOuiNon({
           message: "Êtes-vous sûr de vouloir supprimer ce contributeur ?<br>Toutes les données seront perdues (informations, contributions, montants des droits, etc...",
           callBackYes: ()=>{
            this.phpPost({
              phpFile: "assets/php/supprimerContributeur.php",
              sendObject: { id_contributeur: this.contributeurAAfficher.id_contributeur },
              dispatchAtTheEnd: [
                'miseAJourContributeurs', 'miseAJourNumeros'
              ],
              callBack: (response) => {
                if (response.reqSuccess) this.idContributeur = '-'
              }
            })
           }
         })
        }
    },
    created: function () {
        this.contributeurAAfficher = JSON.parse(JSON.stringify(this.contributeurVide))
    },
    mounted: function () {
      this.phpPost({
        phpFile: "assets/php/listeAcces.php",
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.listeAcces = response.reqList
          }
        }
      })
    }
  };
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.page-modif-contributeur {
  width: 1100px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px 20px 0 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $contour-page;
}
.frame-titre {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-titre {
  color: $couleur-texte-fonce;
  @include typo-titre;
}
.frame-contributeur-a-supprimer {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.label-contributeur-a-supprimer {
  color: $couleur-texte-fonce;
  @include typo-large;
  margin-right: 10px;
}

.frame-infos {
  margin-bottom: 58.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.frame-partie1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  &:not(:last-of-type) {
     margin-bottom: 46px;
  }
}
.frame-denomination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 19px;
  }
}
.label {
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
  @include typo-large;
}
.frame-nom {
  background-color: $couleur-fond-composant-lieu;
  margin-bottom: 12px;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.label-two {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.date-naissance{
    width: 80px;
}

.nom-contributeur {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.frame-siret {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 12px 25px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.frame-libelle-adresse {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 12px;
}
.frame-label-identifiant {
  display: flex;
  align-items: center;
}
.frame-label-editable-identifiant{
  display: flex;
  align-items: center;
}

.label-three {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.validation{
  margin-left: 10px;
   &:hover {
    cursor: help;
  }
}

.auto-fill-id {
  font-size: 0.9em;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}

.frame-reinitialiser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;
}

.bouton-creation-compte{
  @include btnHover($neutral-btn);
  // margin-left: 7px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  background-color: $neutral-btn;
  color: $fond-page;
  border-radius: 9px;
   &.disabled {
    // pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}

.frame-methode-creation-password {
    width: 200px;
}

.frame-boutons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;
}


.identifiant-contributeur {
  width: 90px;
}


.frame-supprimer {
  @include clickable($neutral-btn);
  background-color: $neutral-btn;
  margin-right: 67.5px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  align-self: stretch;
  &.disabled {
    pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}

.bouton-label {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}

.frame-annuler {
  @include clickable($danger-btn);
  background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  align-self: stretch;
  &.disabled {
    pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}



</style>