<template>
  <div>
    <div class="corpsDePage">
      <section class="breadcrumb">
        <div id="selectionAuteur" class="dflex sousSection divTextCenter">
          <div class="labelAuteur">Fiche auteur de</div>
          <div v-if="listeContributeurs.length != 1" class="admin-connected">
            <select
              type="select"
              class="selectionContributeur"
              v-on:change="afficherContributeur"
              v-model="idContributeurSelectionne"
            >
              <option value="0">Choisissez un contributeur</option>
              <option
                v-for="contributeur in listeContributeurs"
                :key="contributeur.id_contributeur"
                :id="contributeur.id_contributeur"
                :value="contributeur.id_contributeur"
                >{{
                  contributeur.nom_contributeur +
                    " " +
                    contributeur.prenom_contributeur
                }}</option
              >
            </select>
            <div
              class="btn btn-primary"
              v-if="
                user.id_contributeur != contributeurAAfficher.id_contributeur
              "
              @click="$router.push('ficheauteur-' + user.id_contributeur)"
            >
              Acceder à ma fiche
            </div>
          </div>
          <div v-if="listeContributeurs.length == 1" class="labelAuteur">
            {{
              listeContributeurs[0].nom_contributeur +
                " " +
                listeContributeurs[0].prenom_contributeur
            }}
          </div>
        </div>

        <!------------------------------------------- Détails Contributeurs ------------------------------------------------>
        <div v-if="user.identifiant == 'PESNOT-PIN' && !screenIsPhone">
          <div class="debug-switch">
            <div>Logs viewer</div>
            <ComposantSwitch v-model="showViewer" />
          </div>
        </div>

        <!-- <div v-if=>

        </div> -->
        <LogViewer v-if="showViewer" />
        <div
          v-if="
            idContributeurSelectionne != '0' && idContributeurSelectionne != '-'
          "
        >
          <div class="sousSection">
            <div class="frame-titre">
              <div class="divTextCenter nomCompletAuteur">
                {{ contributeurAAfficher.prenom_contributeur }}
                {{ contributeurAAfficher.nom_contributeur }}
              </div>
              <img
                src="assets/icones/iconeInfo.png"
                alt="infos déclaration"
                @click="afficherInfosDeclaration"
                class="icone-info-declaration-contributeur"
              />
            </div>
            <div class="details">
              <h5 class="divTextCenter titreSousSection label-adresse">
                Contributeur
              </h5>
              <div class="ligneFormulaire padd-left contributeurNom">
                <div class="labelFormulaire">Nom</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.nom_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurPrenom">
                <div class="labelFormulaire">Prenom</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.prenom_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurPrenom">
                <div class="labelFormulaire">Nom d'artiste</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.nom_artiste_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
            </div>

            <!-------------------------------- Infos décla ------------------------------->
            <div class="details">
              <h5 class="divTextCenter titreSousSection label-adresse">
                Informations déclaratives
              </h5>
              <div class="ligneFormulaire padd-left contributeurNumero">
                <div class="labelFormulaire">Numero Secu</div>
                <LabelEditable
                  length="15"
                  class="label-editable"
                  v-model="contributeurAAfficher.numero_secu_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurSiret">
                <div class="labelFormulaire">Siret</div>
                <LabelEditable
                  class="label-editable"
                  length="14"
                  v-model="contributeurAAfficher.siret_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurSiret">
                <div class="labelFormulaire">Première publication RDL</div>
                <div class="label-editable">
                  {{
                    dateFrancaise(
                      contributeurAAfficher.date_premiere_publication
                    )
                  }}
                </div>
              </div>
            </div>

            <div class="details">
              <h5 class="divTextCenter titreSousSection label-adresse">
                Contacts
              </h5>
              <div class="ligneFormulaire padd-left contributeurEmail">
                <div class="labelFormulaire">Email</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.email_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurTel">
                <div class="labelFormulaire">Tel</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.tel_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurSite">
                <div class="labelFormulaire">Site web</div>
                <LabelEditable
                  class="label-editable"
                  v-model="contributeurAAfficher.site_web_contributeur"
                  v-on:change="modifierAuteur"
                />
              </div>
            </div>

            <!--------------------------------- Adresse ------------------------------------>

            <div
              class="details"
              v-if="contributeurAAfficher.adresse_contributeur != undefined"
            >
              <h5 class="divTextCenter titreSousSection label-adresse">
                Adresse
              </h5>

              <div class="ligneFormulaire padd-left contributeurAdresseLibelle">
                <div class="labelFormulaire">Libellé</div>
                <LabelEditable
                  class="label-editable"
                  v-model="
                    contributeurAAfficher.adresse_contributeur.libelle_adresse
                  "
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurAdresseInfos">
                <div class="labelFormulaire">Infos Complémentaires</div>
                <LabelEditable
                  class="label-editable"
                  v-model="
                    contributeurAAfficher.adresse_contributeur
                      .infos_complementaires_adresse
                  "
                  v-on:change="modifierAuteur"
                />
              </div>
              <div
                class="ligneFormulaire padd-left contributeurAdresseCodePostal"
              >
                <div class="labelFormulaire">Code postal</div>
                <LabelEditable
                  class="label-editable"
                  v-model="
                    contributeurAAfficher.adresse_contributeur
                      .code_postal_adresse
                  "
                  v-on:change="modifierAuteur"
                />
              </div>
              <div class="ligneFormulaire padd-left contributeurAdresseVille">
                <div class="labelFormulaire">Ville</div>
                <LabelEditable
                  class="label-editable"
                  v-model="
                    contributeurAAfficher.adresse_contributeur.ville_adresse
                  "
                  v-on:change="modifierAuteur"
                />
              </div>
            </div>

            <!------------------------------------------- Details des totaux ------------------------------------------------>

            <div class="details">
              <h5 class="titreSousSection divTextCenter">
                Total Droits Générés
              </h5>
              <div class="ligneFormulaireAvecBouton">
                <div class="ligneFormulaireFicheAuteurTotal centered">
                  <div class="labelFormulaireLarge">
                    Total droits + missions
                  </div>
                  <div class="squared">{{ totalDroitsPlusMissions }}</div>
                </div>
              </div>
              <div class="ligneFormulaireAvecBouton">
                <div class="ligneFormulaireFicheAuteurTotal centered">
                  <div class="labelFormulaireLarge">Payé droits + missions</div>
                  <div class="squared">{{ totalPayeDroitsPlusMissions }}</div>
                </div>
              </div>
              <div class="ligneFormulaireAvecBouton">
                <div class="ligneFormulaireFicheAuteurTotal centered">
                  <div class="labelFormulaireLarge">
                    Restant droits + missions
                  </div>
                  <div class="squared">
                    {{ totalRestantDroitsPlusMissions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!------------------------------------------- Details des droits ------------------------------------------------>
          <div class="sousSection">
            <div class="details">
              <h5 class="titreSousSection divTextCenter">Droits d'auteurs</h5>
              <div class="ligneFormulaireAvecBouton padd-left">
                <div class="ligneFormulaireFicheAuteur">
                  <div class="labelFormulaire">Total droits d'auteur</div>
                  <div class="squared">{{ totalDroitsAuteur }}</div>
                </div>
                <div
                  class="btn btn-primary btn-sm btnFicheAuteur btnDemandeVersements"
                  @click="editerFacture"
                  v-if="!screenIsPhone"
                >
                  Demander un versement
                </div>
              </div>
              <div class="ligneFormulaireAvecBouton padd-left">
                <div class="ligneFormulaireFicheAuteur">
                  <div class="labelFormulaire">Total droits versés</div>
                  <div class="squared">{{ totalDroitsVerses }}</div>
                </div>
                <div
                  class="btn btn-primary btn-sm btnFicheAuteur btnDetailsVersements"
                  @click="afficherListeVersements"
                >
                  Liste des versements
                </div>
              </div>
              <div class="ligneFormulaireAvecBouton padd-left">
                <div class="ligneFormulaireFicheAuteur">
                  <div class="labelFormulaire">Reste à verser</div>
                  <div class="squared">{{ totalResteAVerser }}</div>
                </div>
                <div
                  class="btn btn-primary btn-sm btnFicheAuteur"
                  @click="consulterDemandesVersement"
                >
                  Liste des demandes
                </div>
              </div>
              <div
                v-if="user.adminAccessGranted && !screenIsPhone"
                class="ligneFormulaireAvecBouton padd-left"
              >
                <div class="ligneFormulaireFicheAuteur">
                  <div class="labelFormulaire">Action administrateur</div>
                </div>
                <div
                  class="btn btn-primary btn-sm btnFicheAuteur btnAjouterVersement"
                  @click="ajouterVersement"
                >
                  Ajouter un versement
                </div>
              </div>
            </div>

            <div class="details">
              <h5 class="titreSousSection divTextCenter">Contributions</h5>
              <div id="tableauDroitsParNumero">
                <table>
                  <tr id="titreTableau" class="dflex centered">
                    <td class="enteteTableau tableauExemplairesDroits-Col1">
                      Exemplaires
                    </td>
                    <td class="enteteTableau tableauExemplairesDroits-Col2">
                      Droits
                    </td>
                    <td
                      class="enteteTableau tableauExemplairesDroits-Col3"
                      v-if="!screenIsPhone"
                    >
                      Type contribution
                    </td>
                    <td class="enteteTableau tableauExemplairesDroits-Col4">
                      Montant
                    </td>
                    <!-- <td class="enteteTableau tableauExemplairesDroits-Col4">
                      Restant
                    </td> -->
                  </tr>
                  <tr
                    v-for="droits of listeNumerosDroits"
                    id="lignesTableau"
                    :key="droits.id_numero"
                    class="dflex centered cursorPointer"
                  >
                    <td
                      class="ligneTableau tableauExemplairesDroits-Col1"
                      :id="'modifierNumero-' + droits.id_numero"
                      v-on:click="afficherFicheNumero(droits.id_numero)"
                    >
                      N°{{ droits.numero_numero }} - {{ droits.titre_numero }}
                    </td>
                    <td class="ligneTableau tableauExemplairesDroits-Col2">
                      {{
                        droits.pourcentage_contribution +
                          (droits.mode_contribution == "fixe" ? " €" : " %")
                      }}
                    </td>
                    <td
                      class="ligneTableau tableauExemplairesDroits-Col3"
                      v-if="!screenIsPhone"
                    >
                      {{ droits.type_contribution }}
                    </td>
                    <td class="ligneTableau tableauExemplairesDroits-Col4">
                      {{ droits.droits_numero }}
                    </td>
                    <!-- <td class="ligneTableau tableauExemplairesDroits-Col4">
                      {{ droits.droits_numero_depuis_dernier_versement }}
                    </td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="sousSection">
            <!------------------------------------------- Details des missions ------------------------------------------------>
            <div class="details" v-if="!screenIsPhone">
              <h5 class="titreSousSection divTextCenter">Détails missions</h5>
              <ComposantListeMissions :listeMissions="listeMissions" />
              <div
                v-if="user.adminAccessGranted && !screenIsPhone"
                class="dflex space-around"
              >
                <div
                  class="btn btn-primary centered"
                  style="width: 100px; margin-top: 20px"
                  id="btnAjouterMission"
                  @click="ajouterMission"
                >
                  Ajouter
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="infos-declas">
        <!-- <ComposantInfosDeclaContributeur :infos="contributeurAAfficher"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import ComposantListeMissions from "./composantListeMissions";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { consoleMessage } from "../js/consoleMessage";

const ACTIONS_TO_MAP = [
  "miseAJourContributeurs",
  "initDetectionModification",
  "phpPost",
  "modaleComposant",
  "modaleAlert",
  "consoleMessage",
];

const MUTATIONS_TO_MAP = [
  "resetModificationPresente",
  "resetListeContributeursPrete",
  "definirAffichageLogsConsole",
  "definirAffichageLogsRetour",

  /***** Modale  ******/
  "definirVisibiliteModale",
  "definirContenuModale",
  "afficherModale",
];

const STATES_TO_MAP = [
  "listeContributeurs",
  "modificationPresente",
  "listeContributeursPrete",
  "messageAlerteModification",
  "imgCadenas",
  "adresseInconnue",
  "contributeurVide",
  "user",
  "screenIsPhone",
  "afficherLogsConsole",
  "afficherLogsRetour",
];

const GETTERS_TO_MAP = [
  "trouveContributeurParId",
  "modaleData",
  "dateFrancaise",
];

export default {
  name: "pageFicheAuteur",
  template: "#pageFicheAuteurTemplate",
  components: {
    LogViewer: () => import("./_debug/logViewer"),
    ComposantListeMissions,
    ComposantSwitch: () => import("./_debug/composantSwitch"),
  },
  data: () => {
    return {
      showViewer: false,
      idContributeurSelectionne: "0",
      listeNumerosDroits: [],
      listeVersements: [],
      listeVersementsOriginale: [],
      listeCreditAuteurAAfficher: [],
      listeMissions: [],
      listeMissionsOriginale: [],
      ligneMissionEnCoursEdition: undefined,
      idContributeurAAfficher: "",
      contributeurAvantModification: {},
      contributeurAAfficher: {
        nom_contributeur: "aucun contributeur",
        prenom_contributeur: "",
      },

      afficherFenetreVersements: false,
    };
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
    totalResteAVerser() {
      var total =
        parseFloat(this.contributeurAAfficher.total_compte_droits) -
        parseFloat(this.contributeurAAfficher.deja_verse_compte_droits);
      return isNaN(total) ? 0 : Math.round(total * 100) / 100;
    },
    totalDroitsVerses() {
      var total =
        Math.round(
          parseFloat(this.contributeurAAfficher.deja_verse_compte_droits) * 100
        ) / 100;
      return isNaN(total) ? 0 : total;
    },
    totalDroitsAuteur() {
      var total = this.contributeurAAfficher.total_compte_droits;

      return isNaN(total) ? 0 : Math.round(parseFloat(total) * 100) / 100;
    },
    totalMissions() {
      var total = -1;
      if (this.listeMissions.length != 0)
        total = this.listeMissions.reduce((a, v) => {
          return (a += Math.round(parseFloat(v.montant_mission) * 100) / 100);
        }, 0);
      if (!isNaN(total) && total != -1) return Math.round(total * 100) / 100;
      else return 0;
    },
    totalMissionsPayees() {
      if (this.listeMissions.length != 0)
        return this.listeMissions.reduce((a, v) => {
          return (a +=
            v.date_paiement_mission != "0000-00-00"
              ? Math.round(parseFloat(v.montant_mission) * 100) / 100
              : 0);
        }, 0);
      else return 0;
    },
    totalDroitsPlusMissions() {
      return (
        Math.round(
          (parseFloat(this.totalMissions) +
            parseFloat(this.totalDroitsAuteur)) *
            100
        ) / 100
      );
    },
    totalPayeDroitsPlusMissions() {
      return (
        Math.round(
          (parseFloat(this.totalMissionsPayees) +
            parseFloat(this.totalDroitsVerses)) *
            100
        ) / 100
      );
    },
    totalRestantDroitsPlusMissions() {
      return (
        this.totalResteAVerser +
        this.totalMissions -
        this.totalMissionsPayees
      ).toFixed(2);
    },
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    afficherInfosDeclaration() {
      this.definirContenuModale({
        componentName: "ComposantInfosDeclaContributeur",
        allowQuitOnClick: true,
        props: {
          infos: this.contributeurAAfficher,
        },
        hiddenOverflow: true,
      });
      this.afficherModale();
    },
    editerFacture() {
      // if (
      //     this.contributeurAAfficher.adresse_contributeur.libelle_adresse == ''
      //     || this.contributeurAAfficher.adresse_contributeur.code_postal_adresse == ''
      //     || this.contributeurAAfficher.adresse_contributeur.ville_adresse == ''
      // ) return this.modaleAlert("L'adresse doit être renseignée dans la fiche auteur")
      this.modaleComposant({
        componentName: "composantEditionFacture",
        props: { contributeur: this.contributeurAAfficher },
        allowQuitOnClick: true,
      });
    },
    afficherFicheNumero(id_numero) {
      this.$router.push("/edition/consulter-" + id_numero);
    },
    ajouterMission() {
      this.definirContenuModale({
        componentName: "composantAjoutMission",
        props: {
          id_contributeur: this.contributeurAAfficher.id_contributeur,
        },
        allowQuitOnClick: true,
      });
      this.definirVisibiliteModale(true);
    },
    resetListeMissions() {
      if (this.contributeurAAfficher.id_contributeur != undefined) {
        this.phpPost({
          phpFile: "./assets/php/listeMissions.php",
          sendObject: {
            idContributeur: this.contributeurAAfficher.id_contributeur,
          },
          disableThumbnail: true,
          callBack: (data) => {
            if (data.reqSuccess) this.listeMissions = data.reqList;
            else this.listeMissions = [];
            this.listeMissionsOriginale = JSON.parse(
              JSON.stringify(this.listeMissions)
            );
          },
        });
      }
    },
    consulterDemandesVersement() {
      this.definirContenuModale({
        componentName: "composantListeDemandesVersement",
        props: {
          idContributeur: this.idContributeurSelectionne,
          idCompteDroits: this.contributeurAAfficher.id_compte_droits,
        },
        allowQuitOnClick: true,
      });
      this.afficherModale();
    },
    ajouterVersement() {
      this.definirContenuModale({
        componentName: "composantAjoutVersement",
        props: { idCompteDroits: this.contributeurAAfficher.id_compte_droits },
        allowQuitOnClick: true,
      });
      this.definirVisibiliteModale(true);
    },
    afficherListeVersements() {
      this.phpPost({
        phpFile: "./assets/php/listeVersements.php",
        sendObject: {
          id_compte_droits: this.contributeurAAfficher.id_compte_droits,
        },
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.listeVersements = response.reqList;
            this.listeVersementsOriginale = JSON.parse(
              JSON.stringify(this.listeVersements)
            );
            this.modaleComposant({
              componentName: "composantListeVersement",
              props: {
                listeVersements: this.listeVersements,
              },
              allowQuitOnClick: true,
            });
            // this.definirVisibiliteModale(true)
          } else {
            this.listeVersements = [];
            this.listeVersementsOriginale = [];
          }
        },
      });
    },
    afficherDetailsCreditsDroits() {},
    definirURL() {
      this.$router.push("/ficheauteur-" + this.idContributeurSelectionne);
    },
    afficherContributeur() {
      if (this.modificationPresente)
        if (!confirm(this.messageAlerteModification)) {
          this.idContributeurSelectionne = this.contributeurAAfficher.id_contributeur;
          return;
        }
      this.resetModificationPresente();
      this.definirURL();
    },
    definirContributeurAAfficher() {
      if (this.listeContributeurs.length == 1) {
        this.contributeurAAfficher = Object.assign(
          {},
          this.listeContributeurs[0]
        );
        this.idContributeurSelectionne = this.contributeurAAfficher.id_contributeur;
      } else {
        this.contributeurAAfficher = JSON.parse(
          JSON.stringify(
            this.trouveContributeurParId(this.idContributeurSelectionne)
          )
        );
      }
      if (this.contributeurAAfficher.adresse_contributeur == undefined)
        this.contributeurAAfficher.adresse_contributeur = this.adresseInconnue;
      this.contributeurAvantModification = JSON.parse(
        JSON.stringify(this.contributeurAAfficher)
      );
      if (this.contributeurAAfficher.id_contributeur != undefined) {
        this.phpPost({
          phpFile: "./assets/php/listeNumerosDroits.php",
          sendObject: {
            idContributeur: this.contributeurAAfficher.id_contributeur,
          },
          disableThumbnail: true,
          callBack: (data) => {
            if (data.reqSuccess) this.listeNumerosDroits = data.reqList;
            else this.listeNumerosDroits = [];
          },
        });
      }
      this.resetModificationPresente();
    },
    modifierAuteur() {
      this.phpPost({
        sendObject: this.contributeurAAfficher,
        phpFile: "./assets/php/modifierContributeur.php",
        dispatchAtTheEnd: ["miseAJourContributeurs", "miseAJourNumeros"],
        disableThumbnail: true,
        callBack: (data) => {
          if (!data.reqSuccess) {
            this.contributeurAAfficher = JSON.parse(
              JSON.stringify(this.contributeurAvantModification)
            );
          }
        },
      });
    },
    miseAJourPage() {
      if (this.listeContributeursPrete) {
        this.contributeurAAfficher = this.listeContributeurs[0];
        var idContributeur = this.$route.params.id;
        if (idContributeur == "-") {
          this.idContributeurSelectionne = 0;
        } else {
          this.idContributeurSelectionne = this.$route.params.id;
        }
        this.definirContributeurAAfficher();
        this.resetListeMissions();

        this.$nextTick().then(() => {
          this.initDetectionModification();
        });
      }
    },
    resetPage() {
      this.resetListeMissions();
      this.contributeurAAfficher = JSON.parse(
        JSON.stringify(this.contributeurVide)
      );
      this.contributeurAvantModification = JSON.parse(
        JSON.stringify(this.contributeurAAfficher)
      );
      this.listeNumerosDroits = [];
      this.listeVersements = [];
      this.listeVersementsOriginale = [];
      this.listeCreditAuteurAAfficher = [];
      this.listeMissions = [];
      this.listeMissionsOriginale = [];
    },
  },
  watch: {
    listeContributeursPrete() {
      this.miseAJourPage();
    },
    $route(from, to) {
      this.miseAJourPage();
    },
    modaleData() {
      if (this.modaleData.message == "liste versement modifiée") {
        let handler = setInterval(() => {
          if (this.contributeurAAfficher.id_contributeur != undefined) {
            this.afficherListeVersements();
            clearInterval(handler);
          }
        }, 100);
      }
    },
  },
  mounted: function() {
    this.resetPage();
    this.miseAJourPage();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.label-editable {
  @media (max-width: $mobile-break-point) {
    width: 50% !important;
  }
  // min-width: 200px;
}

.infos-declas {
  position: fixed;
  right: 0;
  //   position: fixed;
  //   transition-property: right;
  //   transition-duration: 300ms;
  //   z-index: 5;
  //   right: -320px;
  //   &:hover {
  //     padding-right: 50px;
  //     margin-right: 0px;
  //     right: 0px;
  //   }
}

.corpsDePage {
  display: flex;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}

section {
  @media (max-width: $mobile-break-point) {
    width: 100%;
    padding: 5px !important;
  }
}

.sousSection {
  width: 100%;
  @media (max-width: $mobile-break-point) {
    padding: 5px;
  }
}
.details {
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
#selectionAuteur {
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  padding-right: 70px;
  padding-left: 70px;
  @media (max-width: $mobile-break-point) {
    padding: 0 5px;
  }
}

.selectionContributeur {
  margin-right: 30px;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}

.admin-connected {
  display: flex;
}

.icone-debug-params {
  display: flex;
  align-items: center;
  // margin-left: 20px;
  // border: 1px solid #000;
  // height: 100%;
}

.debug-switch {
  display: flex;
  justify-content: space-between;
  width: 185px;
  margin: 5px auto;
  margin-top: 10px;
  & div {
    @include typo-medium;
    color: $couleur-texte-fonce;
    margin-right: 0px;
  }
}

.frame-titre {
  display: flex;
  justify-content: center;
  & .nomCompletAuteur {
    margin-right: 20px;
  }
}

.icone-info-declaration-contributeur {
  @extend .element-clickable;
  height: 35px;
  position: relative;
  top: 5px;
}

.labelAuteur {
  /* width: 250px; */
  font-size: 1.5em;
  margin-right: 10px;
  @media (max-width: $mobile-break-point) {
    display: none;
  }
}

.label-adresse {
  margin-bottom: 30px;
}

.nomCompletAuteur {
  /* font-weight: bold; */
  font-size: 1.7em;
}

.labelFormulaire {
  // border: 1px solid #000;
  @media (max-width: $mobile-break-point) {
    width: 50%;
  }
}
.labelFormulaireLarge {
  width: 200px;
  @media (max-width: $mobile-break-point) {
  }
}

.ligneFormulaire {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 3px auto;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
  // border: 1px solid black;
}

.ligneFormulaireFicheAuteur {
  // border: 5px solid #000;
  display: flex;
  width: 300px;
  padding-top: 5px;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    justify-content: center;
  }
}

.ligneFormulaireFicheAuteurTotal {
  display: flex;
  justify-content: space-between;
  width: 300px;
  padding-top: 5px;
  @media (max-width: $mobile-break-point) {
    // width: 100%;
  }
}

.ligneFormulaireAvecBouton {
  // border: 5px solid #000;
  display: flex;
  margin-bottom: 10px;
  @media (max-width: $mobile-break-point) {
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
}

.btnFicheAuteur {
  width: 180px !important;
  height: 30px;
  @media (max-width: $mobile-break-point) {
    margin-top: 10px;
    margin: 10px auto 0 auto;
  }
}

.squared {
  border: 1px solid #000;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  margin-right: 20px;
  background-color: #fff;
}

#tableauDroitsParNumero {
  // border: 5px solid #000;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
  @media (max-width: $mobile-break-point) {
    & table {
      width: 100%;
    }
    width: 100%;
  }
}

.tableauExemplairesDroits-Col1 {
  width: 300px;
  border: 1px solid #000;
  @media (max-width: $mobile-break-point) {
    width: 55%;
  }
}

.tableauExemplairesDroits-Col2 {
  width: 80px;
  border: 1px solid #000;
  text-align: center;
  @media (max-width: $mobile-break-point) {
    width: 20%;
  }
}

.tableauExemplairesDroits-Col3 {
  width: 200px;
  border: 1px solid #000;
  text-align: center;
  // @media (max-width: $mobile-break-point) {
  //   width: 30%;
  // }
}

.tableauExemplairesDroits-Col4 {
  width: 80px;
  border: 1px solid #000;
  text-align: center;
  @media (max-width: $mobile-break-point) {
    width: 25%;
  }
}

.enteteTableau {
  text-align: center;
  font-size: 1.1em;
  background-color: #fff;
}

.ligneTableau {
  background-color: #fff;
}

.ligneTableau.tableauExemplairesDroits-Col1 {
  padding-left: 10px;
}
.ligneTableau.tableauExemplairesDroits-Col1:hover {
  background-color: #addaff;
}

.routerLink div {
  text-decoration: none;
}

#listeCreditsDroitsTable td {
  border: 1px solid #000;
  padding: 5px;
  text-align: center;
}

.listeCreditscol1 {
  width: 150px;
}

.listeCreditscol3 {
  width: 400px;
}

#listeCreditsDroitsContainer {
  overflow: auto;
}

.boutonsModifierMission {
  border: none;
  height: 30px;
  padding-top: 5px;
  margin-left: 3px;
  background-color: transparent;
}

.disabledField {
  pointer-events: none;
}

#detailsVersementsContainer {
  max-height: 50vh;
  overflow: auto;
  font-size: 1.1em;
}
#detailsVersementsContainer .col0 {
  width: 40px;
}
#detailsVersementsContainer .col1 {
  width: 170px;
}
#detailsVersementsContainer .col2 {
  width: 100px;
}
#detailsVersementsContainer .col3 {
  width: 100px;
}
#detailsVersementsContainer .boutonsModifierVersement {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 8px;
  height: 25px;
  margin-top: 5px;
}
</style>
