<template>
  <div v-if='user.adminAccessGranted' class="page-admin-asso">
    <div class="frame-titre">
      <p class="label-titre">Administration</p>
    </div>
    <div class="frame-infos">
      <div class="frame-partie1">
        <div class="frame-sous-partie">
          <p class="label">Association</p>
          <div class="frame-champ">
            <p class="label-champ">Raison sociale</p>
            <LabelEditable @change="modifierAssociation" v-model="association.raisonSociale" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Siret</p>
            <LabelEditable @change="modifierAssociation" v-model="association.siret" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Site web</p>
            <LabelEditable @change="modifierAssociation" v-model="association.siteWeb" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Email</p>
            <LabelEditable @change="modifierAssociation" v-model="association.email" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Tel</p>
            <LabelEditable @change="modifierAssociation" v-model="association.tel" class="label-editable" />
          </div> 
          <div class="frame-champ">
            <p class="label-champ">Email factures auteur</p>
            <LabelEditable @change="modifierAssociation" v-model="association.emailFacture" class="label-editable" />
          </div>
        </div>
        <div class="frame-sous-partie">
          <p class="label">Adresse</p>
          <div class="frame-champ">
            <p class="label-champ">Libellé</p>
            <LabelEditable @change="modifierAssociation" v-model="association.adresse.libelle" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Infos comp.</p>
            <LabelEditable @change="modifierAssociation" v-model="association.adresse.infosAdresse" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Code postal</p>
            <LabelEditable @change="modifierAssociation" v-model="association.adresse.codePostal" class="label-editable" />
          </div>
          <div class="frame-champ">
            <p class="label-champ">Ville</p>
            <LabelEditable @change="modifierAssociation" v-model="association.adresse.ville" class="label-editable" />
          </div>
        </div>
      </div>
      <div class="frame-titre">
        <p class="label-titre">Factures automatiques</p>
      </div>
      <div class="frame-mentions-legales">
        <p class="label-mentions-legales">
          Lien vers l'outil magique
        </p>
        <LabelEditable @change="modifierAssociation" v-model="association.lienSiteWebOutilMagique" class="label-editable mentions-legales" />
        <br>
        <p class="label-mentions-legales">
          Mention légale "TVA non applicable"
        </p>
        <LabelEditable @change="modifierAssociation" v-model="association.mentionsLegalesTVANonApplicable" class="label-editable mentions-legales" />
        <br>
        <p class="label-mentions-legales">
          Mentions légales Facturation (shift + entrée pour passer à la ligne)
        </p>
        <LabelEditable @change="modifierAssociation" v-model="association.mentionsLegalesFacturation" format="textarea" class="label-editable mentions-legales" />
      </div>
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "PageAdministration",
  data:()=>{
    return { 
      associationVide: {
        raisonSociale: "",
        siret : "",
        siteWeb : "",
        email : "",
        tel : "",
        emailFacture:"",
        adresse :{
            libelle : "",
            infosAdresse : "",
            codePostal : "",
            ville : ""
        },
        mentionsLegalesFacturation : ""
      },
      association: undefined,
      associationAvantModif: undefined
    }
  },
  computed: {
      ...mapState(['user', 'userConnected'])
  },
  methods: {
    ...mapActions(['phpPost']),
    modifierAssociation() {
      this.phpPost({
        phpFile: 'assets/php/modifierAssociation.php',
        sendObject: this.association,
        callBack: (response) => {
            if (!response.reqSuccess) this.association = JSON.parse(JSON.stringify(this.associationAvantModif))
        }
      })
    },
    recupererInfosAssociation() {
      this.phpPost({
        phpFile: 'assets/php/coordonneesAssociation.php',
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) this.association = response.infosAssociation
          this.associationAvantModif = JSON.parse(JSON.stringify(this.association))
        }
      })
    }
  },
  created: function() {
      this.association = this.associationVide
  },
  mounted: function () {
      this.recupererInfosAssociation();
      
  },
  watch: {
      userConnected() {
          if (!this.userConnected) this.association = this.associationVide
          else this.recupererInfosAssociation();
      }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.page-admin-asso {
  border: $contour-page;
  padding: 63px 20px;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0 5px 20px 0 $opacity-25;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-titre {
  width: 100%;
  margin-bottom: 59px;
}
.label-titre {
  @include typo-titre;
  color: $couleur-texte-fonce;
}
.frame-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-partie1 {
  margin-bottom: 86px;
  display: flex;
  align-items: flex-start;
  
}
.frame-sous-partie {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
}
.label {
  @include typo-large;
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
}
.frame-champ {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 10px 10px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
  
}
.label-champ {
  @include typo-small-medium;
  color: $couleur-texte-fonce;
  width: 150px;
}
.label-editable {
  min-height: 30px;
  width: 350px;
  &.mentions-legales{
    width: 100%;
  }
}

.label-mentions-legales{
 @include typo-medium;
  color: $couleur-texte-fonce;
  padding: 10px 0;
}
.label-options{
 @include typo-medium;
  color: $couleur-texte-fonce;
  padding: 10px 0;
}
.frame-mentions-legales {
  width: 100%;
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  align-self: stretch;
  margin: auto;
  padding: 20px;
}
.frame-options {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 14px;
  padding: 15px;  
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .frame-champ{
      justify-content: center;
      width: 250px;
  }
}
.frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

</style>