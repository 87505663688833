<template>
  <div class="infos-lieu">
    <div class="frame-nom-adresse">
      <LabelEditable class="nom-lieu" v-model="nomLieu" />
      <LabelEditable
        class="adresse-lieu"
        v-model="adresseLieu"
        format="textarea"
        rows="3"
      />
    </div>
    <div class="ligne-vignettes">
      <div class="frame-capacite">
        <p class="label-saturation">Capacité stockage</p>
        <LabelEditable
          class="quantite-lieu-label-editable"
          v-model="capaciteLieu"
          v-on:change="resetCompteurs()"
        />
      </div>
      <div class="frame-saturation">
        <p class="label-saturation">Saturation</p>
        <BarreProgression
          class="barreProgression"
          :pourcentage="pourcentage"
          preset="circle"
          labelPos="center"
          size="100px"
          :key="rerenderKey"
          :font-size="1"
        />
      </div>
      <div class="frame-stock">
        <p class="label-saturation">Quantité stock</p>
        <p class="quantite-lieu">{{ compteurQuantiteLieu }}</p>
      </div>
      <div class="frame-capacite">
        <p class="label-saturation">Espace restant</p>
        <p class="quantite-lieu">{{ compteurRestantLieu }}</p>
      </div>
      <div class="frame-infos-complementaires">
        <p class="label-infos-complementaires">Infos Complémentaires</p>
        <LabelEditable v-model="infosComplementaires" format="textarea" />
      </div>
    </div>
  </div>
</template>

<script>
import BarreProgression from "./barreProgression";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

const STATES_TO_MAP = ["listeNumeros", "listeLieux", "lieuVide"];

const GETTERS_TO_MAP = ["listeNumerosFiltreeTriee"];

const ACTIONS_TO_MAP = ["phpPost"];

const MUTATIONS_TO_MAP = ["modifier"];

export default {
  name: "composantInfosLieu",
  components: { BarreProgression },
  data: () => {
    return {
      pourcentage: 0,
      compteurQuantiteLieu: 0,
      compteurRestantLieu: 0,
      rerenderKey: 0,
      intervalId: undefined,
    };
  },
  methods: {
    ...mapMutations(MUTATIONS_TO_MAP),
    ...mapActions(ACTIONS_TO_MAP),
    nombreMisEnForme(nombre, delimiteur) {
      nombre = "" + nombre;
      delimiteur = delimiteur || " ";
      var c = "",
        d = 0;
      while (nombre.match(/^0[0-9]/)) {
        nombre = nombre.substr(1);
      }
      for (var i = nombre.length - 1; i >= 0; i--) {
        c = d != 0 && d % 3 == 0 ? nombre[i] + delimiteur + c : nombre[i] + c;
        d++;
      }
      return c;
    },
    ficheNumero(id_numero) {
      var routeData = this.$router.resolve("/edition/consulter-" + id_numero);
      window.open(routeData.href, "_blank");
    },
    resetCompteurs() {
      this.compteurQuantiteLieu = 0;
      this.compteurRestantLieu = 0;
      var objectifQuantite = this.lieu.quantite_lieu;
      var objectifRestant = this.lieu.capacite_lieu - this.lieu.quantite_lieu;
      var incQuantite = Math.round(objectifQuantite / 100, 2);
      var incRestant = Math.round(objectifRestant / 100, 2);
      if (this.intervalId != undefined) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.compteurQuantiteLieu += incQuantite;
        this.compteurRestantLieu += incRestant;
        if (
          this.compteurQuantiteLieu >= objectifQuantite ||
          this.compteurRestantLieu >= objectifRestant
        ) {
          this.compteurQuantiteLieu = objectifQuantite;
          this.compteurRestantLieu = objectifRestant;
          clearInterval(this.intervalId);
        }
      }, 10);
      this.pourcentage =
        Math.round((this.lieu.quantite_lieu / this.lieu.capacite_lieu) * 100) <=
        0
          ? 0
          : Math.round(
              (this.lieu.quantite_lieu / this.lieu.capacite_lieu) * 100
            );
      this.rerenderKey++;
    },
  },
  computed: {
    capaciteLieu: {
      get() {
        return this.lieu.capacite_lieu;
      },
      set(nouvelleCapacite) {
        this.phpPost({
          phpFile: "assets/php/modifierLieu.php",
          sendObject: this.lieu,
          modifications: { capacite_lieu: nouvelleCapacite },
          dispatchAtTheEnd: "miseAJourLieux",
          callBack: (response) => {
            if (!response.reqSuccess) {
              this.lieu.capacite_lieu = this.oldCapacite;
            }
          },
        });
      },
    },
    adresseLieu: {
      get: function() {
        return this.lieu.adresse_lieu;
      },
      set: function(nouvelleAdresse) {
        this.phpPost({
          sendObject: this.lieu,
          modifications: { adresse_lieu: nouvelleAdresse },
          phpFile: "assets/php/modifierLieu.php",
          callBack: (response) => {
            if (!response.reqSuccess) {
              this.lieu.adresse_lieu = this.oldAdresse;
            }
          },
        });
      },
    },
    nomLieu: {
      get: function() {
        return this.lieu.nom_lieu;
      },
      set: function(nouveauNom) {
        this.phpPost({
          phpFile: "assets/php/modifierLieu.php",
          sendObject: this.lieu,
          modifications: { nom_lieu: nouveauNom },
          callBack: (response) => {
            if (!response.reqSuccess) {
              this.lieu.nom_lieu = this.oldNom;
            }
          },
        });
      },
    },
    espaceRestant() {
      return this.nombreMisEnForme(
        this.lieu.capacite_lieu - this.lieu.quantite_lieu
      );
    },
    quantiteLieu() {
      return this.nombreMisEnForme(this.lieu.quantite_lieu);
    },
    infosComplementaires: {
      get() {
        return this.lieu.infos_complementaires_lieu == ""
          ? "Aucune information"
          : this.lieu.infos_complementaires_lieu;
      },
      set(infos) {
        this.phpPost({
          phpFile: "assets/php/modifierLieu.php",
          sendObject: this.lieu,
          modifications: { infos_complementaires_lieu: infos },
          dispatchAtTheEnd: "miseAJourLieux",
          callBack: (response) => {
            this.lieu = response.newObject;
          },
        });
      },
    },
    lieu() {
      return this.listeLieux.length <= this.$route.params.refLieu // pour éviter le plantage le temps que la fonction asynchrone du store récupère la liste
        ? this.lieuVide
        : this.listeLieux[this.$route.params.refLieu];
    },
    repartitionNumeros() {
      let listeStockNumero = this.listeNumeros
        .filter(
          (numero) =>
            numero.numero_numero.indexOf("C") == -1 &&
            numero.numero_numero.indexOf("HS") == -1
        )
        .map(
          (numero) =>
            numero.liste_derniers_stockages[this.$route.params.refLieu]
              .quantite_stockage
        );
      let stockMax = Math.max(...listeStockNumero);
      let stockMin = Math.min(...listeStockNumero);
      let pourcentage = ((stockMax - stockMin) / 800) * 100;
      if (pourcentage >= 100) pourcentage = 100;
      return pourcentage;
    },
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
  },
  mounted: function() {
    this.resetCompteurs();
    this.pourcentage =
      Math.round((this.lieu.quantite_lieu / this.lieu.capacite_lieu) * 100) <= 0
        ? 0
        : Math.round((this.lieu.quantite_lieu / this.lieu.capacite_lieu) * 100);
    this.rerenderKey += 1;
    this.oldCapacite = this.lieu.capacite_lieu;
    this.oldNom = this.lieu.nom_lieu;
    this.oldInfos = this.lieu.infos_lieu;
    this.oldAdresse = this.lieu.adresse_lieu;
  },
  watch: {
    $route() {
      this.resetCompteurs();
    },
    listeLieux() {
      this.resetCompteurs();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.infos-lieu {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 21px;
  padding: 40px 60px 80px 40px;
  box-shadow: 0px 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  overflow: auto;
}
.frame-nom-adresse {
  margin-bottom: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92px;
  width: 100%;
}
.nom-lieu.content {
  @include typo-titre;
  color: rgba(44, 82, 130, 1);
  // border: 5px solid black;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.adresse-lieu {
  @include typo-large;
  color: rgba(44, 82, 130, 1);
  width: 100%;
  text-align: center;
}
.ligne-vignettes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.frame-saturation {
  background-color: $fond-page;
  margin-bottom: 25px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 79px;
  align-self: stretch;
}
.label-saturation {
  @include typo-large;
  color: rgba(44, 82, 130, 1);
  width: 100%;
}
.barreProgression {
  position: relative;
  top: -25px;
}
.frame-stock {
  background-color: $fond-page;
  margin-bottom: 24px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 71px;
  align-self: stretch;
}
.quantite-lieu {
  @include typo-large;
  color: rgba(44, 82, 130, 1);
  width: 100%;
  text-align: right;
  justify-content: flex-end;
}

.quantite-lieu-label-editable {
  &.content {
    @extend .quantite-lieu;
  }
}
.frame-capacite {
  background-color: $fond-page;
  margin-bottom: 25px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 78px;
  align-self: stretch;
}
.frame-infos-complementaires {
  background-color: $fond-page;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.label-infos-complementaires {
  @include typo-large;
  color: rgba(44, 82, 130, 1);
  width: 100%;
  margin-bottom: 30px;
}
.infos-complementaires {
  @include typo-small-medium;
  font-weight: normal;
  color: rgba(44, 82, 130, 1);
  width: 100%;
}
</style>
