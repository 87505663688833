<template>
  <div v-if="user.adminAccessGranted" class="page-administration">
    <div class="frame-titre">
      <p class="label-titre">Espace administration</p>
    </div>
    <div class="corps-de-page">
      <div class="frame-onglet-admin">
        <div class="onglets-gestion-admin">
          <div
            class="onglet"
            :class="currentSelection('association')"
            @click="definirOnglet('association')"
          >
            <p class="label-onglet">Association</p>
          </div>
          <div
            class="onglet"
            :class="currentSelection('ajout contributeur')"
            @click="definirOnglet('ajout contributeur')"
          >
            <p class="label-onglet">Ajout contributeur</p>
          </div>
          <div
            class="onglet"
            :class="currentSelection('modification contributeur')"
            @click="definirOnglet('modification contributeur')"
          >
            <p class="label-onglet">
              Modification contributeur
            </p>
          </div>
          <div
            class="onglet"
            :class="currentSelection('suppression contributeur')"
            @click="definirOnglet('suppression contributeur')"
          >
            <p class="label-onglet">
              Suppression contributeur
            </p>
          </div>
          <div
            v-if="user.adminAccessGranted"
            class="onglet"
            :class="currentSelection('demandes versement')"
            @click="definirOnglet('demandes versement')"
          >
            <p class="label-onglet">Demandes versements</p>
          </div>
          <div
            v-if="user.superAdminAccessGranted"
            class="onglet"
            :class="currentSelection('base de données')"
            @click="definirOnglet('base de données')"
          >
            <p class="label-onglet">Base de données</p>
          </div>
        </div>
      </div>
      <keep-alive>
        <div class="composant-admin" :is="pageOnglet"></div>
      </keep-alive>
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "PageAdministration",
  data: () => {
    return {
      pageOnglet: "composantAdminAsso",
      ongletChoisi: "association",
      mapOngletComposant: new Map([
        ["ajout contributeur", "composantAjoutContributeur"],
        ["modification contributeur", "composantModifContributeur"],
        ["association", "composantAdminAsso"],
        ["suppression contributeur", "ComposantSupprimerContributeur"],
        ["demandes versement", "composantAdminDemandesVersement"],
        ["base de données", "composantAdminBDD"],
      ]),
    };
  },
  methods: {
    ...mapMutations(["resetModificationPresente"]),
    ...mapActions(["modaleOuiNon"]),
    currentSelection(onglet) {
      if (onglet == this.ongletChoisi) return "onglet-choisi";
      return "";
    },
    afficherOnglet(nouvelOnglet) {
      this.ongletChoisi = nouvelOnglet;
      if (this.mapOngletComposant.has(nouvelOnglet))
        this.pageOnglet = this.mapOngletComposant.get(nouvelOnglet);
      else this.pageOnglet = "";
    },
    definirOnglet(nouvelOnglet) {
      if (this.modificationPresente)
        this.modaleOuiNon({
          message:
            "Cette page comporte des modifications non enregistrées. Êtes-vous sûr de vouloir quitter la page ?",
          callBackYes: () => {
            this.resetModificationPresente();
            this.afficherOnglet(nouvelOnglet);
          },
        });
      else this.afficherOnglet(nouvelOnglet);
    },
  },
  computed: {
    ...mapState(["modificationPresente", "user"]),
  },
  mounted: function() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.page-administration {
  width: 1200px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-titre {
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-titre {
  color: $couleur-texte-fonce;
  @include typo-titre;
}
.corps-de-page {
  width: 1100px;
  border-radius: 0 0 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frame-onglet-admin {
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.onglets-gestion-admin {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 10px 0px 0px 0px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-onglet {
  padding: 10px;
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-medium;
}
.onglet {
  background-color: $couleur-fond-menu-navigation;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  bottom: 0;
  //   transition-property: bottom, box-shadow;
  transition-duration: 200ms;
  //   z-index: 1;
}

.onglet-choisi {
  border: 1px solid #000;
  box-shadow: 0px 0 10px 0px #888;
  border-bottom: none;
  z-index: 2;
  margin: auto;
  position: relative;
  //  bottom: 2px;
}

.onglet-choisi:before,
.onglet-choisi:after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  background-color: $couleur-fond-menu-navigation;
}

.onglet-choisi:after {
  background-color: $couleur-fond-menu-navigation;

  bottom: 0;
  transform: translateY(95%); // translatex(-5%);
  height: 10px; /* 3px + 1px */
  width: 100%;
}

.onglet-accreditation {
  border-radius: 3px 10px 0px 0px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.composant-admin {
}
</style>
