
<template>
  <div v-if="listeMissions.length!=0" class="composant-liste-mission">

    <div class="frame-titres-liste-mission">
      <p class="labelMission">Mission</p>
      <p class="labelMontant">Montant</p>
      <p class="labelDate">Date</p>
      <p class="labelPaiement">Paiement</p>
      <div class="frame-remplissage"></div>
    </div>
    <div class="separateur1"></div>

    <div class="liste-versement" v-for="(versement, index) in listeMissionsPrivate" :key="versement.id_versement">
        <div class="frame-mission">
            <LabelEditable class='label-editable labelMission' :saveOnBlur="false" v-model="listeMissionsPrivate[index].type_mission" v-on:change="enregistrerModifications(index)"/>
            <LabelEditable class='label-editable labelMontant' :saveOnBlur="false" v-model="listeMissionsPrivate[index].montant_mission" v-on:change="enregistrerModifications(index)"/>
            <LabelEditable class='label-editable labelDate'    :saveOnBlur="false" v-model="listeMissionsPrivate[index].date_mission" type="date" format="date" v-on:change="enregistrerModifications(index)"/>
            <LabelEditable class='label-editable labelPaiement' :saveOnBlur="false" v-model="listeMissionsPrivate[index].date_paiement_mission" type="date" format="date" v-on:change="enregistrerModifications(index)"/>
          <img
            v-if="user.adminAccessGranted"
            alt="supprimer"
            src="assets/uploads/imgs/remove.png"
            @click="supprimerMission(index)"
          />
        </div>
        <div class="separateur1"></div>
    </div>
    <div v-if="user.adminAccessGranted" class = "consignes">(Clic pour modifier - ❌ pour supprimer)</div>
  </div>

  <div v-else class='aucune-mission'>Aucune mission</div>
</template>



<script>
import { mapActions, mapState } from 'vuex'

const ACTIONS_TO_MAP = [
    'phpPost',
    'modaleOuiNon'
]

const STATES_TO_MAP = [
    'user'
]

export default {
    name: 'composantListeVersement',
    model: {
      prop: 'listeMissions',
      event: 'change'
    },
    props: {
        listeMissions: {default: ()=> { return [] } }
    },
    data: ()=>{
        return {
            listeMissionsPrivate: []
        }
    },
    computed: {
      ...mapState(STATES_TO_MAP)
    },
    methods: {
        ...mapActions(ACTIONS_TO_MAP),
        supprimerMission(index) {
            this.modaleOuiNon({
                message: 'Êtes vous sûr de vouloir supprimer cette mission ? (Les droits du contributeur seront recalculés)',
                callBackYes: ()=> { // Si oui
                    this.phpPost({
                        phpFile: './assets/php/supprimerMission.php', 
                        dispatchAtTheEnd: 'miseAJourContributeurs',
                        sendObject: {  
                            idMission: this.listeMissions[index].id_mission,
                        },
                        callBack: (response) => {
                            if (response.reqSuccess) this.$emit('change', this.listeMissionsPrivate)
                        }
                    })
                }
            })
        },
        enregistrerModifications(index) {
          var missionModifiee = this.listeMissionsPrivate[index]
          missionModifiee.paiement_effectue_mission = 
            missionModifiee.date_paiement_mission != '' 
            && missionModifiee.date_paiement_mission != '0000-00-00' 
            ?
            1 : 0;
          // missionModifiee.paiement_effectue_mission = missionModifiee.paiement_effectue_mission == true ? 1 : 0;
          this.phpPost({
              phpFile: './assets/php/modifierMission.php',
              sendObject: missionModifiee,
              dispatchAtTheEnd: 'miseAJourContributeurs',
              callBack: (response) => {
                  if (!response.reqSuccess) this.listeMissionsPrivate = JSON.parse(JSON.stringify(this.listeMissions))
                  else this.$emit('change', this.listeMissionsPrivate)
              }
          })
        }
    },
    watch: {
        listeMissions() {
            this.listeMissionsPrivate = JSON.parse(JSON.stringify(this.listeMissions))    
        }
    },
    mounted: function  () {
        this.listeMissionsPrivate = JSON.parse(JSON.stringify(this.listeMissions))
    }
}
</script>


<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-liste-mission {
  background-color: $fond-page;
  border-radius: 28px;
  padding: 20px 19px 19px 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-titres-liste-mission {
  background-color: $fond-page;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.date {
  width: 160px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}

.montants {
  width: 80px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}

.frame-remplissage {
  width: 35px;
  height: 15px;
}
.separateur1 {
  width: 650px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 10px;
}
.frame-mission {
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition-property: background-color;
  transition-duration: 300ms;
  // &:hover {
  //   background-color: rgba(218, 219, 231, 0.4);
  // }
}
img {
    cursor: pointer;
    height: 30px;
    margin-left: 10px;
}
.aucune-mission {
    text-align: center;
    padding: 10px 40px;
    color: $couleur-texte-fonce;
    @include typo-large;
}
.consignes {
    color: $couleur-texte-fonce;
    @include typo_small;
    margin-top: 30px;
}

.label-editable{
    cursor: pointer;
}

.labelMission{
 width: 180px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}
.labelMontant{
 width: 70px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}
.labelDate{
 width: 160px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}
.labelPaiement{
 width: 160px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
}
.liste-versement{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

