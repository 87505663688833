<template>
  <div class="composant-liste-impressions">
    <p class="titre">Impressions</p>
    <p class="titre">{{ titre }}</p>
    <div class="separateur1"></div>
    <div class="frame-titres-liste-versements">
      <p class="label-date">Date</p>
      <p class="label-quantite">Quantite</p>
      <p class="label-quantite">Coût</p>
      <div class="frame-remplissage"></div>
    </div>
    <ComposantImpression
      v-for="(impression, index) in listeImpressions"
      :key="impression.id_impression"
      class="composant-impression"
      :impression="impression"
      @update="enregistrerModificationImpression(index)"
      @delete="supprimerImpression(index)"
    />
    <div class="separateur1"></div>
    <div
      class="element-clickable frame-ajout-impression"
      @click="ajouterImpression"
      v-if="user.adminAccessGranted && !screenIsPhone"
    >
      <img
        class="icone-ajout-impression"
        src="assets/icones/add.png"
        alt="ajouter"
      />
      <div>Ajouter une impression</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ComposantListeImpressions",
  components: {
    ComposantImpression: () => import("./ComposantImpression"),
  },
  props: {
    numero: { default: undefined },
    needUpdate: { default: false },
  },
  data: () => {
    return {
      listeImpressions: [],
      listeImpressionsAvantModif: [],
    };
  },
  computed: {
    ...mapState(["user", "listeNumeros", "screenIsPhone"]),
    titre() {
      return this.numero.numero_numero + " - " + this.numero.titre_numero;
    },
  },
  methods: {
    ...mapMutations([
      "afficherModale",
      "masquerModale",
      "definirContenuModale",
      "masquerModale",
    ]),
    ...mapActions(["phpPost", "modaleOuiNon", "modaleAlert"]),
    supprimerImpression(index) {
      this.modaleOuiNon({
        message:
          "Êtes vous sûr.e de vouloir supprimer cette impression ? <br>Conséquences de cette action : <br>- Les ventes seront mises à jour<br>- Les droits d'auteur seront mis à jour",
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/supprimerImpression.php",
            sendObject: this.listeImpressions[index],
            dispatchAtTheEnd: "miseAJourNumeros",
            callBack: (response) => {
              if (response.reqSuccess) {
                this.updateListeImpressions();
              }
            },
          });
        },
      });
    },
    enregistrerModificationImpression(index) {
      this.phpPost({
        phpFile: "assets/php/modifierImpression.php",
        sendObject: this.listeImpressions[index],
        dispatchAtTheEnd: "miseAJourNumeros",
        callBack: (response) => {
          if (!response.reqSuccess) {
            this.listeImpressions = JSON.parse(
              JSON.stringify(this.listeImpressionsAvantModif)
            );
          }
        },
      });
    },
    updateListeImpressions() {
      this.phpPost({
        phpFile: "assets/php/listeImpressions.php",
        sendObject: { id_numero: this.numero.id_numero },
        disableThumbnail: true,
        displayLoader: "Récupération de la liste...",
        callBack: (response) => {
          // this.masquerModale()
          if (response.reqSuccess) {
            this.listeImpressions = response.reqList;
            this.listeImpressionsAvantModif = JSON.parse(
              JSON.stringify(this.listeImpressions)
            );
          }
        },
      });
    },
    ajouterImpression() {
      this.definirContenuModale({
        componentName: "ComposantAjoutImpression",
        props: {
          idNumero: this.numero.id_numero,
        },
      });
      this.afficherModale();
    },
  },
  mounted: function() {
    this.updateListeImpressions();
  },
  watch: {
    listeNumeros() {
      this.updateListeImpressions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
@import "src/assets/css/listeImpressions.scss";

.composant-liste-impressions {
  background-color: $fond-page;
  border-radius: 28px;
  padding: 19.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    padding: 5px;
  }
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 10px;
  @include typo-large;
}
.separateur1 {
  height: 1px;
  background-color: $silver;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  @media (max-width: $mobile-break-point) {
    width: 80%;
  }
}
.frame-titres-liste-versements {
  background-color: $fond-page;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.label-date {
  width: $largeur-date;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @include typo-medium;
}
.label-quantite {
  width: $largeur-quantite;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @include typo-medium;
}

.frame-remplissage {
  width: $largeur-croix;
  height: 15px;
}
.composant-impression {
  margin-bottom: 10px;
  align-self: stretch;
}

.frame-ajout-impression {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  height: 30px;
}

.icone-ajout-impression {
  height: 100%;
}
</style>
