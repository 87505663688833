import { render, staticRenderFns } from "./pageGestionFestivals.vue?vue&type=template&id=cc8d1462&scoped=true&"
import script from "./pageGestionFestivals.vue?vue&type=script&lang=js&"
export * from "./pageGestionFestivals.vue?vue&type=script&lang=js&"
import style0 from "./pageGestionFestivals.vue?vue&type=style&index=0&id=cc8d1462&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc8d1462",
  null
  
)

export default component.exports