<template>
  <div class="composant-ajout-festival">
    <p class="titre">Ajouter un festival</p>
    <div class="separateur1"></div>
    <div class="frame-ligne-modale">
      <p class="label">Nom</p>
      <input
        class="nom-festival"
        placeholder="Nom"
        v-model="nouveauFestival.nom_festival"
      />
    </div>
    <div class="frame-ligne-modale">
      <p class="label">Lieu</p>
      <input
        class="nom-festival"
        placeholder="Lieu"
        v-model="nouveauFestival.lieu_festival"
      />
    </div>
    <div class="frame-ligne-modale">
      <p class="label">Date</p>
      <input
        type="date"
        class="nom-festival"
        placeholder="Date"
        v-model="nouveauFestival.date_festival"
      />
    </div>
    <div class="frame-boutons">
      <div class="frame-ajouter">
        <p class="bouton-ajouter" @click="ajouterFestival">Ajouter</p>
      </div>
      <div class="frame-annuler">
        <p class="bouton-ajouter" @click="masquerModale">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ComposantAjoutFestival",
  props: {
    updateListeFestival: {
      type: Function,
      default: () => {
        console.error(
          "composantAjoutFestival: Aucune fonction d'update de liste de transfert n'a été fournie"
        );
      },
    },
  },
  data: () => {
    return {
      nouveauFestival: {
        nom_festival: "",
        lieu_festival: "",
        date_festival: "------",
      },
    };
  },
  methods: {
    ...mapMutations(["masquerModale"]),
    ...mapActions(["phpPost"]),
    ajouterFestival() {
      this.phpPost({
        phpFile: "assets/php/ajouterFestival.php",
        sendObject: { nouveauFestival: this.nouveauFestival },
        callBack: (response) => {
          if (response.reqSuccess) {
            this.updateListeFestival();
            this.masquerModale();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-ajout-festival {
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.separateur1 {
  width: 300px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-ligne-modale {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label {
  width: 100px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}
.nom-festival {
  width: 150px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
  width: 244px;
}
.frame-ajouter {
  @include btnHover($neutral-btn);
  background-color: $neutral-btn;
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.bouton-ajouter {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
.frame-annuler {
  @include btnHover($danger-btn);
  // background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>
